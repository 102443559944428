import {put, takeEvery} from 'redux-saga/effects';
import {RequestBase} from '../../models/request-base';
import {Store} from '../../redux/store';
import {push} from 'connected-react-router';
import {ResType} from '../api.type';
import {ApiBase} from '../api-base';
import {CoordinatorActions} from './coordinator.action';
import {RoutingPath} from '../../routes/routing-path';
import {DialogActions} from '../../redux/dialog/dialog.action';
import {SystemActions} from '../../redux/system/system.action';
import {ApiGetAssignCoordinatorListResponse} from './api-get-coordinator-list.response';
import {AuthActions} from '../auth/auth.action';
import {ApiAssignCoordinatorResponse} from './api-assign-coordinator.response';

function* tryGetCoordinatorList(
  action: ReturnType<typeof CoordinatorActions.getCoordinatorList>
) {
  try {
    const result: ResType<ApiGetAssignCoordinatorListResponse> = yield action.payload.run();
    yield put(AuthActions.setToken(action.payload.token));
    if (ApiBase.isSuccess(result)) {
      yield put(CoordinatorActions.setCoordinatorList(result.data));
    } else if (ApiBase.isTokenError(result)) {
      yield put(SystemActions.tokenError(result.errors));
    } else {
      yield put(DialogActions.pushMessage({message: [result.errors]}));
    }
  } catch (error) {
    yield put(SystemActions.networkError());
  }
}

function* tryGetCoordinator(
  action: ReturnType<typeof CoordinatorActions.getCoordinator>
) {
  try {
    const result: ResType<ApiAssignCoordinatorResponse> = yield action.payload.run();
    yield put(AuthActions.setToken(action.payload.token));
    if (ApiBase.isSuccess(result)) {
      yield put(
        DialogActions.push({
          message: ['担当者を指定しました'],
          dialogType: 'message',
          btnProps: [
            {
              label: 'OK',
              action: () => {
                Store.dispatch(DialogActions.pop());
                Store.dispatch(push(RoutingPath.houseList));
              },
            },
          ],
        })
      );
    } else if (ApiBase.isTokenError(result)) {
      yield put(SystemActions.tokenError(result.errors));
    } else {
      yield put(DialogActions.pushMessage({message: [result.errors]}));
    }
  } catch (error) {
    yield put(SystemActions.networkError());
  }
}

export function* CoordinatorSaga() {
  yield takeEvery(CoordinatorActions.getCoordinatorList, tryGetCoordinatorList);
  yield takeEvery(CoordinatorActions.getCoordinator, tryGetCoordinator);
}
