import {takeEvery, put, all} from 'redux-saga/effects';
import {HouseActions} from '../../../api/house/house.action';
import {DialogActions} from '../../../redux/dialog/dialog.action';
import {Store} from '../../../redux/store';
import {CoordinatorActions} from '../../../api/coordinator/coordinator.action';
import {AssignCoordinatorActions} from './assign-coordinator.action';
import {ApiGetHouseDetail} from '../../../api/house/api-get-house-detail';
import {ApiGetCoordinatorList} from '../../../api/coordinator/api-get-coordinator-list';
import {ApiAssignCoordinator} from '../../../api/coordinator/api-assign-coordinator';
import { ApiCoordinatorCheck } from '../../../api/coordinator/api-coordinator-check';
import { ApiChangePasswordResponse } from '../../../api/auth/api-change-password.response';
import { ApiCoordinatorCheckResponse } from '../../../api/coordinator/api-coodinator-check.response';
import { ResType } from '../../../api/api.type';
import { ApiBase } from '../../../api/api-base';
import { SystemActions } from '../../../redux/system/system.action';
import { RoutingPath } from '../../../routes/routing-path';
import { push } from 'connected-react-router';

function* trySetData(
  action: ReturnType<typeof AssignCoordinatorActions.setData>
) {
  const {article_code, onSuccess} = action.payload;
  yield all([
    put(
      CoordinatorActions.getCoordinatorList(
        new ApiGetCoordinatorList({sort: 'office', order: 'asc'})
      )
    ),
    put(
      HouseActions.getHouseDetail({
        api: new ApiGetHouseDetail({article_code}),
        onSuccess: (data) => {
          onSuccess(data);
        },
      })
    ),
  ]);
}

function* tryAssignCoordinator(
  action: ReturnType<typeof AssignCoordinatorActions.assignCoordinator>
) {
  const {coordinator_code, article_code} = action.payload;
  if (coordinator_code === undefined || article_code === undefined) {
    yield put(
      DialogActions.push({
        message: ['担当者の指定に失敗しました'],
        dialogType: 'message',
        btnProps: [
          {label: 'OK', action: () => Store.dispatch(DialogActions.pop())},
        ],
      })
    );
  } else {
    yield put(
      CoordinatorActions.getCoordinator(
        new ApiAssignCoordinator({
          coordinator_code,
          article_code,
        })
      )
    );
  }
}

export function* tryCheckCoordinator(
  action: ReturnType<typeof AssignCoordinatorActions.checkCoordinator>
) {
  const { article_code, onSuccess, onError } = action.payload
  if (article_code === undefined) {
    yield put(
      DialogActions.push({
        message: ['物件の指定に失敗しました'],
        dialogType: 'message',
        btnProps: [
          { label: 'OK', action: () => Store.dispatch(DialogActions.pop()) },
        ],
      })
    );
  } else {
    try {
      const api = new ApiCoordinatorCheck({ article_code });
      const result: ResType<{
        status: number,
        data: ApiCoordinatorCheckResponse[];
      }> = yield api.run();

      if (ApiBase.isSuccess(result)) {
        onSuccess?.(result.data);
      } else if (ApiBase.isTokenError(result)) {
        yield put(SystemActions.tokenError(result.errors));
      } else if (result.status === 404) {
        yield put(push(RoutingPath.houseList));
        yield put(DialogActions.pushMessage({ message: ['物件情報が見つかりません。'] }));
      } else {
      yield put(DialogActions.pushMessage({ message: [result.errors] }));
      }
    } catch {
      onError?.();
      yield put(SystemActions.networkError());
    }
  }
}

export function* AssignUserSaga() {
  yield takeEvery(AssignCoordinatorActions.setData, trySetData);
  yield takeEvery(
    AssignCoordinatorActions.assignCoordinator,
    tryAssignCoordinator
    );
    yield takeEvery(AssignCoordinatorActions.checkCoordinator, tryCheckCoordinator);
}
