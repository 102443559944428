import { takeEvery, put } from 'redux-saga/effects';
import { HouseActions } from './house.action';
import { RequestBase } from '../../models/request-base';
import { Store } from '../../redux/store';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../routes/routing-path';
import { ResType } from '../api.type';
import { ApiBase } from '../api-base';
import { AuthActions } from '../auth/auth.action';
import { DialogActions } from '../../redux/dialog/dialog.action';
import { SystemActions } from '../../redux/system/system.action';
import { ApiGetHouseListResponse } from './api-get-house-list.response';
import { ApiGetHouseDetailResponse } from './api-get-house-detail.response';

function* tryGetHouseList(
  action: ReturnType<typeof HouseActions.getHouseList>
) {
  try {
    const result: ResType<ApiGetHouseListResponse> = yield action.payload.run();
    yield put(AuthActions.setToken(action.payload.token));
    if (ApiBase.isSuccess(result)) {
      yield put(HouseActions.setHouseList(result.data.article));
    } else if (ApiBase.isTokenError(result)) {
      yield put(SystemActions.tokenError(result.errors));
    } else {
      yield put(DialogActions.pushMessage({ message: [result.errors] }));
    }
  } catch (e) {
    yield put(SystemActions.networkError());
  }
}

function* tryGetHouseDetail(
  action: ReturnType<typeof HouseActions.getHouseDetail>
) {
  const { api, onSuccess } = action.payload;
  try {
    const result: ResType<ApiGetHouseDetailResponse> = yield api.run();
    yield put(AuthActions.setToken(api.token));
    if (ApiBase.isSuccess(result)) {
      onSuccess(result.data);
    } else if (ApiBase.isTokenError(result)) {
      yield put(SystemActions.tokenError(result.errors));
    } else if (result.status === 404) {
      // ---- 物件がなかった場合 ----
      yield put(push(RoutingPath.houseList));
      yield put(DialogActions.pushMessage({ message: ['物件情報が見つかりません。'] }));
      // ----------------------------
    } else {
      yield put(DialogActions.pushMessage({ message: [result.errors] }));
    }
  } catch (e) {
    yield put(SystemActions.networkError());
  }
}

function* tryPostHouseStatus(
  action: ReturnType<typeof HouseActions.postHouseStatus>
) {
  const { api, callback } = action.payload;

  yield put(DialogActions.isLoading(true));
  try {
    const result: ResType<{}> = yield api.run();
    yield put(AuthActions.setToken(api.token));
    if (ApiBase.isSuccess(result)) {
      callback();
    } else if (ApiBase.isTokenError(result)) {
      yield put(SystemActions.tokenError(result.errors));
    } else {
      yield put(DialogActions.pushMessage({ message: [result.errors] }));
    }
  } catch (e) {
    yield put(SystemActions.networkError());
  }
  yield put(DialogActions.isLoading(false));
}

export function* HouseSaga() {
  yield takeEvery(HouseActions.getHouseList, tryGetHouseList);
  yield takeEvery(HouseActions.getHouseDetail, tryGetHouseDetail);
  yield takeEvery(HouseActions.postHouseStatus, tryPostHouseStatus);
}
