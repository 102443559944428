import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {GlobalStyleComponent} from '../../../styles/component-global-style';
import {InputText} from '../../ui/input/input-text';
import {Select} from '../../ui/select/select';
import {ButtonDefault} from '../../ui/button';
import {useDispatch} from 'react-redux';
import {RoutingPath} from '../../../routes/routing-path';
import {SpecificationActions} from '../../../api/specification/specification.action';
import {useParams} from 'react-router-dom';
import {ApiSaveSpecification} from '../../../api/specification/api-save-specification';
import {ApiGetSpecificationItem} from '../../../api/specification/api-get-specification-item';
import {DialogActions} from '../../../redux/dialog/dialog.action';
import {SpecificationController} from './specification-controller';
import {ApiSetFloorTheme} from '../../../api/specification/api-set-floot-theme';
import {
  SpecificationSelecteria,
  SpecificationItem,
  SpecificationHouse,
  SpecificationRoom,
} from '../../../api/specification/specification.type';
import {ApiGetRoomCodeList} from '../../../api/specification/api-get-room-code-list';
import {SpecificationValidation} from './validation/specification-validation';
import {AssignCoordinatorActions} from '../assign-coordinator/assign-coordinator.action';
import {ApiGetFinishTableCsv} from '../../../api/specification/api-get-finish-table-csv';

type Props = {
  data: SpecificationController | null;
  note: string;
  specificationRooms: SpecificationRoom[] | null;
  specificationHouse: SpecificationHouse | null;
  specificationItem: SpecificationItem | null;
  selecteria: SpecificationSelecteria[] | null;
  btnDisabled: boolean;
  setRoomSelection: (v: 0 | 1 | 2) => void;
  setTableListData: (v: []) => void;
  roomSelection: 0 | 1 | 2;
};

const SpecificationToolSelect = (props: Props) => {
  const {id} = useParams<{id: string}>();
  const dispatch = useDispatch();
  const {
    selecteria,
    note,
    specificationHouse,
    specificationRooms,
    specificationItem,
    btnDisabled,
    setRoomSelection,
    setTableListData,
    roomSelection,
  } = props;

  // state
  const [selectRoom, setSelectRoom] = useState<string>('');
  const [selectorium, setSelectorium] = useState<string>('');
  const [selectTheme1, setSelectTheme1] = useState<string>('');
  const [selectTheme2, setSelectTheme2] = useState<string>('');
  const [selectTheme3, setSelectTheme3] = useState<string>('');
  const [outputNum, setOutputNum] = useState<number>(0);

  const isDisabled = useMemo(() => {
    return (
      !!specificationHouse?.is_csv &&
      props.data?.data?.[0]?.element_group_code !== '1900'
    );
  }, [specificationHouse, props.data]);

  // stateのセット
  useEffect(() => {
    if (!selectRoom) {
      setSelectRoom(specificationRooms ? specificationRooms[0].code : '');
      setRoomSelection(
        specificationRooms ? specificationRooms[0].room_selection : 0
      );
    }
    if (!selectTheme1) {
      setSelectTheme1(
        specificationHouse
          ? specificationHouse.floor_theme_1f
            ? specificationHouse.floor_theme_1f.selecteria_id
            : ''
          : ''
      );
    }
    if (!selectTheme2) {
      setSelectTheme2(
        specificationHouse
          ? specificationHouse.floor_theme_2f
            ? specificationHouse.floor_theme_2f.selecteria_id
            : ''
          : ''
      );
    }
    if (!selectTheme3) {
      setSelectTheme3(
        specificationHouse
          ? specificationHouse.floor_theme_2f
            ? specificationHouse.floor_theme_2f.selecteria_id
            : ''
          : ''
      );
    }
    if (!outputNum) {
      setOutputNum(specificationHouse ? specificationHouse.export_count : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    id,
    outputNum,
    selectRoom,
    selecteria,
    selectorium,
    specificationHouse,
    specificationItem,
    specificationRooms,
    setRoomSelection,
  ]);

  // セレクテリア指定
  const handleClickSelecteriaBtn = useCallback(() => {
    if (!selectRoom || !selectorium || !props.data) {
      dispatch(
        DialogActions.pushMessage({
          message: ['部屋・セレクテリアを選択してください'],
        })
      );
      return;
    }
    dispatch(
      DialogActions.push({
        title: '',
        dialogType: 'message',
        message: ['セレクテリアを設定しますか?'],
        btnProps: [
          {
            label: 'OK',
            action: () => {
              dispatch(DialogActions.pop());
              if (props.data)
                dispatch(
                  SpecificationActions.saveSpecification({
                    api: new ApiSaveSpecification({
                      article_code: id,
                      room_code: selectRoom,
                      specification: {
                        note,
                        items: props.data.getSendData(roomSelection),
                        selecteria: selectorium,
                      },
                    }),
                    onSuccess: () => {
                      dispatch(
                        SpecificationActions.getItem({
                          api: new ApiGetSpecificationItem({
                            article_code: id,
                            room_code: selectRoom,
                            query: {selecteria: selectorium},
                          }),
                          onSuccess: () => {},
                        })
                      );
                    },
                  })
                );
            },
          },
          {
            label: 'キャンセル',
            action: () => {
              dispatch(DialogActions.pop());
            },
          },
        ],
      })
    );
  }, [dispatch, id, note, props.data, selectRoom, selectorium, roomSelection]);

  // フロアテーマ一括設定
  const handleClickFloorTheme = useCallback(
    (index: number) => {
      let change_flag =
        (index === 0 && !selectTheme1) ||
        (index === 1 && !selectTheme2) ||
        (index === 2 && !selectTheme3) ||
        !selectRoom
          ? false
          : true;
      if (!change_flag || !props.data) {
        dispatch(
          DialogActions.pushMessage({
            message: ['部屋・フロアテーマを選択してください'],
          })
        );
        return;
      }
      const themes = [selectTheme1, selectTheme2, selectTheme3];
      dispatch(
        DialogActions.push({
          title: '',
          dialogType: 'message',
          message: ['一括設定しますか?'],
          btnProps: [
            {
              label: 'OK',
              action: () => {
                dispatch(
                  SpecificationActions.setFloorTheme({
                    api: new ApiSetFloorTheme({
                      article_code: id,
                      body: {
                        floor: index + 1,
                        selecteria: themes[index],
                      },
                    }),
                    onSuccess: () => {
                      dispatch(
                        SpecificationActions.getItem({
                          api: new ApiGetSpecificationItem({
                            article_code: id,
                            room_code: selectRoom,
                          }),
                        })
                      );
                    },
                  })
                );
                dispatch(DialogActions.pop());
              },
            },
            {
              label: 'キャンセル',
              action: () => {
                dispatch(DialogActions.pop());
              },
            },
          ],
        })
      );
    },
    [
      dispatch,
      id,
      props.data,
      selectRoom,
      selectTheme1,
      selectTheme2,
      selectTheme3,
    ]
  );

  const checkCoordinateDialog = useCallback(() => {
    return new Promise<void>((resolve) => {
      dispatch(DialogActions.pushMessage({
        message: ['別タブでコーディネートシートを表示します。'],
        onClick: () => resolve(),
      }))
    })
  }, []);

  // 保存
  const handlerClickSave = useCallback(
    (onSuccess?: () => void) => {
      if (!selectRoom || !props.data) {
        dispatch(
          DialogActions.pushMessage({
            message: ['部屋を選択してください'],
          })
        );
        return;
      }

      dispatch(
        SpecificationActions.saveSpecification({
          api: new ApiSaveSpecification({
            article_code: id,
            room_code: selectRoom,
            specification: {
              note,
              items: props.data.getSendData(roomSelection),
            },
          }),
          isEnd: !onSuccess,
          onSuccess,
        })
      );
    },
    [dispatch, id, note, props.data, selectRoom, roomSelection]
  );
  // PDF出力
  const handleClickExportSheetBtn = useCallback(async() => {
    if (!selectRoom || !props.data) {
      dispatch(
        DialogActions.pushMessage({
          message: ['部屋を選択してください'],
        })
      );
      return;
    }
    handlerClickSave(() => {
      const getCsv = () =>
        new Promise<void>((resolve) => {
          dispatch(
            SpecificationActions.getFinishTableCsv({
              api: new ApiGetFinishTableCsv({
                id,
              }),
              onSuccess: () => {
                resolve();
              },
              onError: () => dispatch(DialogActions.isLoading(false)),
            })
          );
        });
      dispatch(DialogActions.isLoading(true));
      dispatch(
        AssignCoordinatorActions.checkCoordinator({
          article_code: id,
          onSuccess: async (res) => {
            if (!props.data) {
              dispatch(DialogActions.isLoading(false));
              return;
            }
            if (!res.length) {
              await getCsv();
              dispatch(DialogActions.isLoading(false));
              await checkCoordinateDialog();
              window.open(
                `${window.location.origin}/#${RoutingPath.specificationPdf}/${id}`
              );
            } else {
              dispatch(DialogActions.isLoading(false));
              const bodyEle: JSX.Element[] = [<br />];
              res.forEach((v) => {
                bodyEle.push(
                  <div style={{textAlign: 'left'}}>{`◆${v.room}`}</div>
                );
                v.elements.forEach((v2) => {
                  v2.forEach((v3, i) => {
                    bodyEle.push(
                      <div style={{textAlign: 'left'}}>{`─${v3}`}</div>
                    );
                    if (v2.length === i + 1) {
                      bodyEle.push(<br />);
                    }
                  });
                });
              });
              dispatch(
                DialogActions.pushMessage({
                  message: [
                    '下記の選択必須部材が選択されていないため',
                    'コーディネートシートを出⼒できません。',
                  ],
                  bodyEle: (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flex: '1',
                        overflow: 'auto',
                      }}
                    >
                      <div style={{display: 'inline-block'}}>{bodyEle}</div>
                    </div>
                  ),
                })
              );
            }
          },
          onError: () => dispatch(DialogActions.isLoading(false)),
        })
      );
    });
  }, [
    dispatch,
    id,
    note,
    props.data,
    selectRoom,
    roomSelection,
    handlerClickSave,
  ]);

  // 部屋切り換え
  const handleChangeRoom = useCallback(
    (room: string) => {
      if (!props.data) {
        return;
      }
      // const validation = new SpecificationValidation({
      //   selectRoom,
      //   items: props.data.getSendData(roomSelection),
      // }).checkAll();
      // if (validation.length) {
      //   dispatch(
      //     DialogActions.pushMessage({
      //       message: validation,
      //     })
      //   );
      //   return;
      // }
      setSelectRoom(room);
      setRoomSelection(
        specificationRooms?.find((v) => v.code === room)?.room_selection || 0
      );
      setTableListData([]);
      dispatch(
        SpecificationActions.saveSpecification({
          api: new ApiSaveSpecification({
            article_code: id,
            room_code: selectRoom,
            specification: {
              note,
              items: props.data.getSendData(roomSelection),
            },
          }),
          isEnd: false,
          onSuccess: () => {
            dispatch(
              SpecificationActions.getRoomCodeList({
                api: new ApiGetRoomCodeList({
                  article_code: id,
                  room_code: room,
                }),
              })
            );
            dispatch(
              SpecificationActions.getItem({
                api: new ApiGetSpecificationItem({
                  article_code: id,
                  room_code: room,
                }),
                onSuccess: () => {
                  setSelectorium('');
                },
              })
            );
          },
        })
      );
    },
    [
      dispatch,
      id,
      note,
      props.data,
      selectRoom,
      setRoomSelection,
      setTableListData,
      specificationRooms,
      roomSelection,
    ]
  );

  return (
    <>
      <GlobalStyleComponent.FlexBetween id="Component_SpecificationToolSelect">
        {/* ************************************************************ */}

        <div className="specificationToolSelectInner">
          <div className="colInnerChild_wrap child_1">
            <div className="item_wrap">
              <div className="itemLabel">
                <GlobalStyleComponent.parentWrap theme={{size: '90px'}}>
                  物件名
                </GlobalStyleComponent.parentWrap>
              </div>
              <div className="itemInput labelInput">
                <GlobalStyleComponent.parentWrap theme={{size: '220px'}}>
                  <InputText
                    disabled
                    defaultValue={specificationHouse?.article_name}
                  />
                </GlobalStyleComponent.parentWrap>
              </div>
              <div className="itemBtn" />
            </div>

            <div className="item_wrap">
              <div className="itemLabel">
                <GlobalStyleComponent.parentWrap theme={{size: '90px'}}>
                  部屋切替
                </GlobalStyleComponent.parentWrap>
              </div>
              <div className="itemInput">
                <GlobalStyleComponent.parentWrap theme={{size: '160px'}}>
                  <Select
                    title={
                      specificationRooms?.find((v) => v.code === selectRoom)
                        ?.floor
                    }
                    value={selectRoom}
                    optionList={
                      specificationRooms
                        ? specificationRooms?.map((v) => ({
                            value: v.code,
                            label: v.floor,
                          }))
                        : []
                    }
                    onChange={(e) => handleChangeRoom(e)}
                    allowUnselected
                  />
                </GlobalStyleComponent.parentWrap>
              </div>
              <div className="itemBtn" />
            </div>

            <div className="item_wrap">
              <div className="itemLabel minSize">
                <GlobalStyleComponent.parentWrap theme={{size: '90px'}}>
                  セレクテリア
                </GlobalStyleComponent.parentWrap>
              </div>
              <div className="itemInput">
                <GlobalStyleComponent.parentWrap theme={{size: '160px'}}>
                  <Select
                    title={
                      selecteria?.find((v) => v.code === selectorium)?.name
                    }
                    disabled={isDisabled}
                    value={selectorium}
                    optionList={
                      selecteria
                        ? [
                            {value: '', label: '-'},
                            ...selecteria?.map((v) => ({
                              value: String(v?.code),
                              label: v.name,
                            })),
                          ]
                        : []
                    }
                    onChange={(e) => setSelectorium(e)}
                    allowUnselected
                  />
                </GlobalStyleComponent.parentWrap>
              </div>
              <div className="itemBtn">
                <ButtonDefault
                  label="指定"
                  disabled={isDisabled}
                  onClick={handleClickSelecteriaBtn}
                />
              </div>
            </div>
          </div>

          {/* ************************************************************ */}

          <div className="colInnerChild_wrap child_2">
            <GlobalStyleComponent.FlexBox>
              <div className="floorThemeLabel">
                <GlobalStyleComponent.parentWrap theme={{size: '100px'}}>
                  フロアテーマ
                </GlobalStyleComponent.parentWrap>
              </div>

              <GlobalStyleComponent.spacer theme={{size: 10}} />

              <div>
                <div
                  className={`item_wrap flex_align_center ${
                    ((specificationHouse?.floor_theme_1f !== null &&
                    !specificationHouse?.floor_theme_1f) ||
                    ( !!specificationHouse?.is_csv &&
                      props.data?.data?.[0]?.element_group_code !== '1900'
                    ))
                    ? 'disabled'
                      : ''
                  }`}
                >
                  <div className="itemInput labelInput">
                    <GlobalStyleComponent.parentWrap theme={{size: '50px'}}>
                      <InputText
                        value="1F"
                        disabled
                        option={{alignCenter: true}}
                      />
                    </GlobalStyleComponent.parentWrap>
                  </div>

                  <GlobalStyleComponent.spacer theme={{size: 10}} />

                  <div className="itemInput">
                    <GlobalStyleComponent.parentWrap theme={{size: '220px'}}>
                      <Select
                        title={
                          selecteria?.find((v) => v.code === selectTheme1)?.name
                        }
                        value={selectTheme1}
                        optionList={
                          selecteria
                            ? specificationHouse?.floor_theme_1f !== null &&
                              !specificationHouse?.floor_theme_1f
                              ? [{value: '', label: '該当なし'}]
                              : [
                                  {value: '', label: '-'},
                                  ...selecteria?.map((v) => ({
                                    value: String(v?.code),
                                    label: v.name,
                                  })),
                                ]
                            : []
                        }
                        disabled={
                          !!specificationHouse?.is_csv &&
                          props.data?.data?.[0]?.element_group_code !== '1900'
                        }
                        onChange={(e) => setSelectTheme1(e)}
                        allowUnselected
                      />
                    </GlobalStyleComponent.parentWrap>
                  </div>

                  <div className="itemBtn">
                    <ButtonDefault
                      label="一括設定"
                      onClick={() => handleClickFloorTheme(0)}
                      disabled={
                        !!specificationHouse?.is_csv &&
                        props.data?.data?.[0]?.element_group_code !== '1900'
                      }
                    />
                  </div>
                </div>

                <div
                  className={`item_wrap flex_align_center ${
                    ((specificationHouse?.floor_theme_2f !== null &&
                    !specificationHouse?.floor_theme_2f) || (
                      !!specificationHouse?.is_csv &&
                        props.data?.data?.[0]?.element_group_code !== '1900'
                    ))
                      ? 'disabled'
                      : ''
                  }`}
                >
                  <div className="itemInput labelInput">
                    <GlobalStyleComponent.parentWrap theme={{size: '50px'}}>
                      <InputText
                        value="2F"
                        disabled
                        option={{alignCenter: true}}
                      />
                    </GlobalStyleComponent.parentWrap>
                  </div>

                  <GlobalStyleComponent.spacer theme={{size: 10}} />

                  <div className="itemInput">
                    <GlobalStyleComponent.parentWrap theme={{size: '220px'}}>
                      <Select
                        title={
                          selecteria?.find((v) => v.code === selectTheme2)?.name
                        }
                        value={selectTheme2}
                        disabled={
                          !!specificationHouse?.is_csv &&
                          props.data?.data?.[0]?.element_group_code !== '1900'
                        }
                        optionList={
                          selecteria
                            ? specificationHouse?.floor_theme_2f !== null &&
                              !specificationHouse?.floor_theme_2f
                              ? [{value: '', label: '該当なし'}]
                              : [
                                  {value: '', label: '-'},
                                  ...selecteria?.map((v) => ({
                                    value: String(v?.code),
                                    label: v.name,
                                  })),
                                ]
                            : []
                        }
                        onChange={(e) => setSelectTheme2(e)}
                        allowUnselected
                      />
                    </GlobalStyleComponent.parentWrap>
                  </div>

                  <div className="itemBtn">
                    <ButtonDefault
                      label="一括設定"
                      onClick={() => handleClickFloorTheme(1)}
                      disabled={
                        !!specificationHouse?.is_csv &&
                        props.data?.data?.[0]?.element_group_code !== '1900'
                      }
                    />
                  </div>
                </div>

                <div
                  className={`item_wrap flex_align_center ${
                    ((specificationHouse?.floor_theme_3f !== null &&
                    !specificationHouse?.floor_theme_3f) || (
                      !!specificationHouse?.is_csv &&
                        props.data?.data?.[0]?.element_group_code !== '1900'
                    ))
                      ? 'disabled'
                      : ''
                  }`}
                >
                  <div className="itemInput labelInput">
                    <GlobalStyleComponent.parentWrap theme={{size: '50px'}}>
                      <InputText
                        value="3F"
                        disabled
                        option={{alignCenter: true}}
                      />
                    </GlobalStyleComponent.parentWrap>
                  </div>

                  <GlobalStyleComponent.spacer theme={{size: 10}} />

                  <div className="itemInput">
                    <GlobalStyleComponent.parentWrap theme={{size: '220px'}}>
                      <Select
                        title={
                          selecteria?.find((v) => v.code === selectTheme3)?.name
                        }
                        disabled={
                          !!specificationHouse?.is_csv &&
                          props.data?.data?.[0]?.element_group_code !== '1900'
                        }
                        value={selectTheme3}
                        optionList={
                          selecteria
                            ? specificationHouse?.floor_theme_3f !== null &&
                              !specificationHouse?.floor_theme_3f
                              ? [{value: '', label: '該当なし'}]
                              : [
                                  {value: '', label: '-'},
                                  ...selecteria?.map((v) => ({
                                    value: String(v?.code),
                                    label: v.name,
                                  })),
                                ]
                            : []
                        }
                        onChange={(e) => setSelectTheme3(e)}
                        allowUnselected
                      />
                    </GlobalStyleComponent.parentWrap>
                  </div>

                  <div className="itemBtn">
                    <ButtonDefault
                      label="一括設定"
                      onClick={() => handleClickFloorTheme(2)}
                      disabled={
                        !!specificationHouse?.is_csv &&
                        props.data?.data?.[0]?.element_group_code !== '1900'
                      }
                    />
                  </div>
                </div>
              </div>
            </GlobalStyleComponent.FlexBox>
          </div>
        </div>

        {/* ************************************************************ */}

        <div className="colInnerChild_wrap right_wrap">
          <div>
            <div className="outputLabel">
              <GlobalStyleComponent.FlexAlignEndContentCenter>
                出力回数
                <div className="innerNum">{outputNum}回</div>
              </GlobalStyleComponent.FlexAlignEndContentCenter>
            </div>

            <GlobalStyleComponent.divide theme={{size: 10}} />

            <ButtonDefault
              label="コーディネートシート出力"
              onClick={handleClickExportSheetBtn}
            />

            <GlobalStyleComponent.divide theme={{size: 5}} />

            <GlobalStyleComponent.FlexAlignCenterContentCenter>
              <ButtonDefault
                label="保存"
                onClick={() => handlerClickSave()}
                disabled={btnDisabled}
              />
            </GlobalStyleComponent.FlexAlignCenterContentCenter>
          </div>
        </div>

        {/* ************************************************************ */}
      </GlobalStyleComponent.FlexBetween>

      <GlobalStyleComponent.divide theme={{size: 10}} />
      <div className="separateEle" />
      <GlobalStyleComponent.divide theme={{size: 10}} />
    </>
  );
};

export default SpecificationToolSelect;
