import { ApiBase } from '../api-base';
import { HeadersAuthJson } from '../api-headers';
import { ApiGetAssignCoordinatorListResponse } from './api-get-coordinator-list.response';
import { SortCoordinator } from './coordinator.type';

type Param = SortCoordinator | null;

export class ApiGetCoordinatorList extends ApiBase<ApiGetAssignCoordinatorListResponse> {
  constructor(param: Param) {
    const sort: SortCoordinator = param || {
      order: 'asc'
    };
    super({
      httpMethod: 'GET',
      contents: 'JSON',
      url: '/api/coordinator',
      param: sort,
      header: HeadersAuthJson(),
      body: {},
      host: 'https://hinokiya-specification-dev.marietta.co.jp'
    });
  }
}
