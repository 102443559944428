import React, {useCallback, useEffect, useRef, useState} from 'react';
import {RoutingPath} from '../../../routes/routing-path';
import {NavLink, useParams} from 'react-router-dom';
import {PageBase} from '../../ui/page-base/page-base';
import {AssignCoordinatorStyle as S} from '../../../styles/pages/assign-coordinator/assign-coordinator.style';
import {GlobalStyleComponent} from '../../../styles/component-global-style';
import {Table} from '../../ui/table/table';
import {ButtonDefault} from '../../ui/button';
import {InputText} from '../../ui/input/input-text';
import {useDispatch, useSelector} from 'react-redux';
import {State} from '../../../redux/root.reducer';
import * as lodash from 'lodash';
import {CoordinatorActions} from '../../../api/coordinator/coordinator.action';
import {AssignCoordinatorActions} from './assign-coordinator.action';
import {HouseDetail} from '../../../api/house/house.type';
import {ApiGetCoordinatorList} from '../../../api/coordinator/api-get-coordinator-list';
import {useDidMount} from '../../../hooks/life-cycle';
import {SortCoordinator} from '../../../api/coordinator/coordinator.type';
import img_checkBoxNoCheck from '../../../assets/images/checkbox_noCheck.svg';
import img_checkBoxChecked from '../../../assets/images/checkbox_checked.svg';

const AssignCoordinatorBody = () => {
  const dispatch = useDispatch();
  const {id} = useParams<{id: string}>();
  const {coordinatorList} = useSelector(
    (state: State) => ({
      coordinatorList: state.coordinator.coordinatorList,
    }),
    lodash.isEqual
  );

  /**
   * state
   * */
  const [houseDetail, setHouseDetail] = useState<HouseDetail | null>(null);
  const [coordinatorName, setCoordinatorName] = useState<string>('');
  const [officeName, setOfficeName] = useState<string>('');
  const [sortState, setSortState] = useState<'asc' | 'desc'>('asc');
  const [coordinatorCode, setCoordinatorCode] = useState<string>('');
  const [selectCell, setSelectCell] = useState<number>();
  const [sortType, setSortType] = useState<'office' | 'name' | 'default'>(
    'default'
  );

  /**
   * 高さ算出
   * */
  const refHeight1 = useRef<any>(1);
  const refHeight2 = useRef<any>();
  const refHeight3 = useRef<any>();
  const [topHeight, setTopHeight] = useState<number>(0);
  const [bottomOtherTableHeight, setBottomOtherTableHeight] = useState<number>(
    0
  );

  /**
   * useEffect
   */
  useDidMount(() => {
    dispatch(
      AssignCoordinatorActions.setData({
        article_code: id,
        onSuccess: (data) => {
          setHouseDetail(data);
        },
      })
    );
  });
  useEffect(() => {
    setTopHeight(refHeight1.current?.getBoundingClientRect().height || 0);
    setBottomOtherTableHeight(
      refHeight2.current?.getBoundingClientRect().height +
        refHeight3.current?.getBoundingClientRect().height
    );
  }, [
    coordinatorList,
    refHeight1,
    refHeight2,
    refHeight3,
    topHeight,
    houseDetail,
  ]);

  /**
   * handler
   */
  const handleClickAssignUser = useCallback(() => {
    dispatch(
      AssignCoordinatorActions.assignCoordinator({
        coordinator_code: coordinatorCode,
        article_code: id,
      })
    );
  }, [coordinatorCode, dispatch, id]);
  const handleClickCoordinator = useCallback(() => {
    dispatch(
      CoordinatorActions.getCoordinatorList(
        new ApiGetCoordinatorList({
          name: coordinatorName || '',
          sort: 'office',
          order: sortState,
        })
      )
    );
  }, [coordinatorName, dispatch, sortState]);
  const handleClickOffice = useCallback(() => {
    dispatch(
      CoordinatorActions.getCoordinatorList(
        new ApiGetCoordinatorList({
          office: officeName,
          sort: 'office',
          order: sortState,
        })
      )
    );
  }, [dispatch, officeName, sortState]);
  const handleClickSort = useCallback(
    (order: 'asc' | 'desc') => {
      setSortState(order);
      let sort: SortCoordinator = {
        name: '',
        office: '',
        sort: 'office',
        order,
      };
      switch (sortType) {
        case 'name':
          sort.name = coordinatorName || '';
          break;
        case 'office':
          sort.office = officeName;
          break;
      }
      dispatch(
        CoordinatorActions.getCoordinatorList(new ApiGetCoordinatorList(sort))
      );
    },
    [coordinatorName, dispatch, officeName, sortType]
  );

  /**
   * option
   * */
  const submitBtnOption = {
    paddingSize: {
      paddingTop: 30,
      paddingRight: 80,
      paddingBottom: 30,
      paddingLeft: 80,
    },
    fontSize: 27,
  };
  const tableOption = {
    stringWidth: [
      {index: 0, width: 200},
      {index: 2, width: 115},
    ],
    tdAlign: [{index: 2, align: 'center'}],
  };
  const selectedTableOption = {
    stringWidth: [
      {index: 0, width: 250},
      {index: 1, width: 150},
      // { index: 2, width: 200 },
      {index: 3, width: 170},
      {index: 4, width: 120},
      {index: 5, width: 150},
      {index: 6, width: 170},
    ],
  };
  const tableSortProps = {
    index: [0],
    onClick: (order: 'asc' | 'desc') => handleClickSort(order),
  };
  return (
    <S.AssignCoordinatorBody id="Component_AssignUserBody">
      <div className="cont_top" ref={refHeight1}>
        選択中の物件
        <GlobalStyleComponent.divide theme={{size: 10}} />
        <S.SelectItemTableBody>
          <Table
            header={[
              '物件名',
              '支社',
              '展示場',
              '営業担当',
              '入力状況',
              '入力情報の完了',
              '担当者',
            ]}
            lists={[
              [
                houseDetail?.name,
                houseDetail?.office,
                houseDetail?.exhibition_hall,
                houseDetail?.sales_staff,
                <div style={{textAlign: 'center'}}>
                  {houseDetail?.input_status ? '済' : '未'}
                </div>,
                <S.InputStatusBtn>
                  <div className="img_wrap position_r">
                    {houseDetail?.status ? (
                      <>
                        <img
                          src={img_checkBoxChecked}
                          alt="チェック済"
                          className="isChecked"
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <img src={img_checkBoxNoCheck} alt="未チェック" />
                  </div>
                  <input
                    type="checkbox"
                    // onChange={() => setKeepData(!keepData)}
                    // onFocus={() => setCheckBoxFocus(true)}
                    // onBlur={() => setCheckBoxFocus(false)}
                  />
                </S.InputStatusBtn>,
                houseDetail?.coordinator_name,
              ],
            ]}
            option={selectedTableOption}
          />
        </S.SelectItemTableBody>
        <GlobalStyleComponent.divide theme={{size: 20}} />
      </div>

      <div
        className="cont_bottom"
        style={{height: 'calc(100% - ' + topHeight + 'px)'}}
      >
        <div ref={refHeight2}>
          コーディネータ選択
          <GlobalStyleComponent.divide theme={{size: 20}} />
          <div className="define_wrap">
            <div>
              <div>コーディネータ名</div>
              <GlobalStyleComponent.parentWrap theme={{size: '300px'}}>
                <InputText
                  value={coordinatorName}
                  onChange={(e) => {
                    setCoordinatorName(e.currentTarget.value);
                  }}
                />
              </GlobalStyleComponent.parentWrap>
              <div>
                <ButtonDefault
                  label="検索"
                  onClick={() => {
                    handleClickCoordinator();
                    setSortType('name');
                  }}
                />
              </div>
            </div>

            {/* <div>
              <div>支店名</div>
              <GlobalStyleComponent.parentWrap theme={{ size: '300px' }}>
                <InputText
                  value={officeName}
                  onChange={(e) => {
                    setOfficeName(e.currentTarget.value);
                  }}
                />
              </GlobalStyleComponent.parentWrap>
              <div>
                <ButtonDefault
                  label="絞り込み"
                  onClick={() => {
                    handleClickOffice();
                    setSortType('office');
                  }}
                />
              </div>
            </div> */}
          </div>
          <GlobalStyleComponent.divide theme={{size: 20}} />
        </div>

        <S.SelectItemTableBody
          style={{height: 'calc(100% - ' + bottomOtherTableHeight + 'px)'}}
        >
          <div className="tableWidthAdjust">
            <Table
              header={['支社名', 'コーディネータ名', '選択']}
              lists={coordinatorList.map((v, i) => [
                v.office,
                v.name,
                <ButtonDefault
                  label="選択"
                  onClick={() => {
                    setCoordinatorCode(v.code);
                    setSelectCell(i);
                  }}
                  selected={v.code === coordinatorCode}
                />,
              ])}
              option={tableOption}
              selectedTr={selectCell}
              sort={tableSortProps}
            />
          </div>
        </S.SelectItemTableBody>

        <div ref={refHeight3}>
          <GlobalStyleComponent.divide theme={{size: 20}} />
          <GlobalStyleComponent.FlexAlignCenterContentCenter>
            <ButtonDefault
              label="担当者指定"
              onClick={handleClickAssignUser}
              option={submitBtnOption}
            />
          </GlobalStyleComponent.FlexAlignCenterContentCenter>
          <GlobalStyleComponent.divide theme={{size: 20}} />
        </div>
      </div>
    </S.AssignCoordinatorBody>
  );
};

const AssignUser = () => {
  return (
    <PageBase title="担当者指定画面" pageId="Component_AssignUser">
      <AssignCoordinatorBody />
    </PageBase>
  );
};

export default AssignUser;
