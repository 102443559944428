/** 実際にAPIと接続するクラス */
import { ApiBase } from '../api/api-base';
import { Config } from '../config/config';
import { LogDecorator } from '../utilities/log-decorator';

class ConnectionClass {
  public async run<ReturnType>(apiBase: ApiBase): Promise<ReturnType> {
    const startTime = new Date();
    return fetch(apiBase.createUrl(), apiBase.createRequestInit())
      .then((res) => {
        return this.response(apiBase, res, startTime);
      })
      .catch((error) => {
        throw error;
      });
  }

  /** レスポンスのタイプによって処理わけ */
  public async response(api: ApiBase, response: Response, startTime: Date) {
    const defaultTime = new Date('1990/01/01');
    const nowTime = new Date();
    // FIXME : ↓ また不都合起きたら nowTime じゃなくて startTime を使う
    // const nowTime = startTime;
    // JWTトークンセット
    // NOTE : JWT トークンセット
    //  1. トークンがレスポンスにあるかチェック
    //  2. トークン取得時の時間を localstorage から取得
    //  3. トークン取得時の時間が巻き戻らないかチェック
    //  4. トークンと取得時の時間を保存
    if (response.headers.has('Authorization')) {
      api.token = response.headers.get("Authorization");
      if (api.token) {
        const tokenGetTimeStr = localStorage.getItem('tokenGetTime');
        const tokenGetTime = tokenGetTimeStr ? new Date(+tokenGetTimeStr ? +tokenGetTimeStr : tokenGetTimeStr) : new Date(defaultTime);
        if (tokenGetTime < nowTime) {
          localStorage.setItem('token', api.token);
          localStorage.setItem('tokenGetTime', `${nowTime.getTime()}`);
        }
      }
    } else {
      if (localStorage.getItem('token')) {
        api.token = localStorage.getItem('token');
        localStorage.removeItem('token');
      }
    }
    const status = response.status;
    if (status !== 200) {
      api.contents = 'JSON';
    }

    switch (api.contents) {
      case 'JSON':
        return response.json()
          .then((json) => {
            this.log(api, json, response);
            return json;
          });
      case 'BLOB':
        return response.blob()
          .then((blob) => {
            const contentType = response.headers.get('Content-Type') || '';
            const contentTypeArr = contentType.replace(/\s/g, '').split(';');
            const nameItem = contentTypeArr.find((v) => v.indexOf('name') === 0) || '';
            if (contentTypeArr.length > 1 && nameItem) {
              const name = nameItem.split('=').length > 1 ? nameItem.split('=')[1] : '';
              const file = new File([blob], name);
              this.log(api, file, response);
              return { file, status };
            }
            const parsedBlob = api.parse(blob);
            this.log(api, api.parse, response);
            return { file: parsedBlob, status };
          });
      case 'PDF':
        let filename = "";
        const disposition = response.headers.get('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        return response.blob()
          .then((blob) => {
            if (filename) {
              const file = new File([blob], filename, { type: 'application/pdf' });
              this.log(api, file, response);
              return { file, status };
            }
            const parsedBlob = api.parse(blob);
            this.log(api, api.parse, response);
            return { file: parsedBlob, status };
          });
      default:
        return response.text()
          .then(async (text) => {
            const parsedText = await api.parse(text);
            this.log(api, parsedText, response,);
            return parsedText;
          });
    }
  }

  public log(api: ApiBase, data: any, res: Response) {
    if(Config.mode ==='prod') return
    const title = `[<${res.status}>] ${api.httpMethod} ${api.url}`;
    const statusColor = res.status === 200 ? 'blue' : 'red';
    console.groupCollapsed(...LogDecorator(title, statusColor));
    console.log('Url          : ', api.createUrl());
    console.group('Request');
    console.log('Method     : ', api.httpMethod);
    console.log('Contents   : ', api.contents);
    console.log('Header     : ', api.header);
    console.log('Param      : ', api.param);
    console.log('Body       : ', api.body);
    console.groupEnd();
    console.group('Response');
    console.log('Body       : ', data);
    console.groupEnd();
    console.groupEnd();
  }
}

export const Connection = new ConnectionClass();
