import { ApiBase } from '../api-base';
import { ResFileType } from '../api.type';


export class ApiPostZip extends ApiBase<ResFileType> {
  constructor() {
    super({
      httpMethod: 'POST',
      contents: 'BLOB',
      url: '/api/file/zip',
      header: {},
      body: {},
      host: 'https://hinokiya-specification-dev.marietta.co.jp',
    });
  }
}
