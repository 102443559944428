import { all, fork } from 'redux-saga/effects';
import { AuthSaga } from '../api/auth/auth.saga';
import { CoordinatorSaga } from '../api/coordinator/coordinator.saga';
import { HouseSaga } from '../api/house/house.saga';
import { SpecificationSaga } from '../api/specification/specification.saga';
import { TestSaga } from '../api/test/test.saga';
import { UploadMasterSaga } from '../api/upload-master/upload-master.saga';
import { AssignUserSaga } from '../components/pages/assign-coordinator/assign-coordinator.saga';
import { SpecificationToolSaga } from '../components/pages/specification-tool/specification-tool.saga';
import { DialogSaga } from './dialog/dialog.saga';
import { SystemSaga } from './system/system.saga';

export const RootSaga = function* root() {
  yield all([
    fork(TestSaga),
    fork(AuthSaga),
    fork(CoordinatorSaga),
    fork(HouseSaga),
    fork(SpecificationSaga),
    fork(SpecificationToolSaga),
    fork(AssignUserSaga),
    fork(DialogSaga),
    fork(SystemSaga),
    fork(UploadMasterSaga),
  ]);
};
