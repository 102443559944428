import { ApiBase } from '../api-base';
import { HeadersDefaultJson } from '../api-headers';

type Param = {
  password: string;
};

export class ApiCsvDownloadAuth extends ApiBase {
  constructor(param: Param) {
    super({
      httpMethod: 'POST',
      header: HeadersDefaultJson(),
      contents: 'JSON',
      url: '/api/file/auth/download',
      body: param,
      host: 'https://hinokiya-specification-dev.marietta.co.jp'
    });
  }
}
