import { ApiBase } from '../api-base';
import { HeadersAuthJson } from '../api-headers';
import { ApiGetSpecificationSelecteriaResponse } from './api-get-specification-selecteria.response';

export class ApiGetSpecificationSelecteria extends ApiBase<ApiGetSpecificationSelecteriaResponse> {
  constructor() {
    super({
      httpMethod: 'GET',
      contents: 'JSON',
      url: '/api/selecteria',
      param: {},
      header: HeadersAuthJson(),
      host: 'https://hinokiya-specification-dev.marietta.co.jp'
    });
  }
}
