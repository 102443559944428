// import { Store } from '../redux/store';

import { Store } from '../redux/store';

export const HeadersDefaultJson = () => ({
  "Content-Type": "application/json",
});

export const HeadersAuthJson = () => ({
  "Content-Type": "application/json",
  Authorization: localStorage.getItem('token') || Store.getState().auth.jwtToken,
});
