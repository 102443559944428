import { ApiBase } from '../api-base';
import { HeadersAuthJson } from '../api-headers';
import { ApiSetFloorThemeResponse } from './api-set-floot-theme.response';

type Param = {
  article_code: string;
  body: {
    floor: number;
    selecteria: string;
  };
};

export class ApiSetFloorTheme extends ApiBase<ApiSetFloorThemeResponse> {
  constructor(param: Param) {
    super({
      httpMethod: 'POST',
      contents: 'JSON',
      url: `/api/article/${param.article_code}/theme`,
      header: HeadersAuthJson(),
      body: param.body,
      host: 'https://hinokiya-specification-dev.marietta.co.jp'
    });
  }
}
