import actionCreatorFactory from 'typescript-fsa';
import { ApiRequestType } from '../api.type';
import { APIExportSpecificationDummy } from './api-export-specification.dummy';
import { ApiGetSpecificationHouse } from './api-get-specification-house';
import { ApiGetSpecificationItem } from './api-get-specification-item';
import { ApiGetSpecificationRoom } from './api-get-specification-room';
import { ApiGetSpecificationSelecteria } from './api-get-specification-selecteria';
import { ApiSaveSpecification } from './api-save-specification';
import { ApiSetFloorTheme } from './api-set-floot-theme';
import * as Type from './specification.type';
import { ApiGetSpecificationPDF } from './api-get-specification-PDF';
import { ApiGetRoomCodeList } from './api-get-room-code-list';
import { ApiGetFinishTableCsv } from './api-get-finish-table-csv';

const ActionCreator = actionCreatorFactory('specification');

export const SpecificationActions = {
  exportSpecification: ActionCreator<
    ApiRequestType<APIExportSpecificationDummy>
  >('export/Specification'),
  getFinishTableCsv: ActionCreator<{ api: ApiGetFinishTableCsv, onSuccess?: () => void; onError?: () => void;}>('get/finish-table/csv'),
  getHouse: ActionCreator<ApiGetSpecificationHouse>('get/house'),
  setHouse: ActionCreator<Type.SpecificationHouse | null>('set/house'),
  getRoom: ActionCreator<ApiGetSpecificationRoom>('get/room'),
  setRoom: ActionCreator<Type.SpecificationRoom[] | null>('set/room'),
  getSelecteria: ActionCreator<ApiGetSpecificationSelecteria>('get/selecteria'),
  setSelecteria: ActionCreator<Type.SpecificationSelecteria[] | null>('set/selecteria'),
  getItem: ActionCreator<{ api: ApiGetSpecificationItem; onSuccess?: () => void; }>('get/item'),
  setItem: ActionCreator<Type.SpecificationItem | null>('set/item'),
  setFloorTheme: ActionCreator<{ api: ApiSetFloorTheme; onSuccess?: () => void; }>('set/floor/theme'),
  saveSpecification: ActionCreator<{ api: ApiSaveSpecification; isEnd?: boolean; onSuccess?: () => void; onError?: () => void; }>('save/specification'),
  getPdf: ActionCreator<{ api: ApiGetSpecificationPDF; isEnd?: boolean; onSuccess: (file: string, fileName: string) => void; }>('get/pdf'),
  getRoomCodeList: ActionCreator<{ api: ApiGetRoomCodeList; onSuccess?: () => void; }>('get/room/code/list'),
  setRoomCodeList: ActionCreator<Type.RoomCode[] | null>('set/room/code/list'),
};
