import { ApiBase } from '../api-base';
import { HeadersAuthJson } from '../api-headers';
import { ApiGetSpecificationPDFResponse } from './api-get-specification-PDF.response';

type Param = {
  article_code?: string;
};

export class ApiGetSpecificationPDF extends ApiBase<ApiGetSpecificationPDFResponse> {
  constructor(param: Param) {
    super({
      httpMethod: 'POST',
      contents: 'PDF',
      url: `/api/article/${param.article_code}/file`,
      header: HeadersAuthJson(),
      host: 'https://hinokiya-specification-dev.marietta.co.jp',
    });
  }
}
