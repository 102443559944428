import styled from 'styled-components';
import {CommonDefine} from "../../define";

export const PageBaseStyle = {
  BaseWrap: styled.div`
    height: 100%;
    
    .PageBaseInner {
      height: 100%;
      padding: 20px;
    }
  `,

  BaseHeader: styled.div`
    height: ${CommonDefine.headerHeight}px;
    padding-bottom: 19px;
    border-bottom: 1px solid ${CommonDefine.baseBgColor};
    ${CommonDefine.flex_box};
    ${CommonDefine.flex_align_center};
    
    >div {
      &.logo_wrap {
        cursor: pointer;
        
        img {
          height: calc(${CommonDefine.headerHeight}px - 40px);
          width: auto;
        }
      }
      &.title_wrap {
        margin-left: 20px;
        font-size: 26px;
        font-weight: bold;
        flex-basis: 22em;
        width: 22em;

        //font-family: "ヒラギノ丸ゴ Pro W4","ヒラギノ丸ゴ Pro","Hiragino Maru Gothic Pro","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","HG丸ｺﾞｼｯｸM-PRO","HGMaruGothicMPRO",sans-serif !important;
        
        &.isHide {
          flex: 100%;
          width: 100%;
        }
      }
      &.user_wrap {
        flex: 1;
        ${CommonDefine.flex_box};
        ${CommonDefine.flex_align_end};
        ${CommonDefine.flex_content_end};
        font-size: 26px;
        font-weight: bold;
        line-height: 1;
        
        .min_text {
          font-size: 12px;
          font-weight: normal;
          margin-bottom: 2px;
        }
      }
      &.btn_wrap {
        margin-left: 30px;
      }
    }
  `,

  BaseBody: styled.div`
    height: calc(100% - ${CommonDefine.headerHeight}px);
    padding-top: 20px;
    overflow-y: auto;
  `,
};
PageBaseStyle.BaseWrap.defaultProps = {
};