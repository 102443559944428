import { takeEvery, put } from 'redux-saga/effects';
import { Store } from '../../redux/store';
import { ResFileType, ResType } from '../api.type';
import { SpecificationActions } from './specification.action';
import { DialogActions } from '../../redux/dialog/dialog.action';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../routes/routing-path';
import { ApiGetSpecificationHouseResponse } from './api-get-specification-house.response';
import { ApiBase } from '../api-base';
import { AuthActions } from '../auth/auth.action';
import { SystemActions } from '../../redux/system/system.action';
import { ApiGetSpecificationSelecteriaResponse } from './api-get-specification-selecteria.response';
import { ApiGetSpecificationRoomResponse } from './api-get-specification-room.response';
import { ApiGetSpecificationItemResponse } from './api-get-specification-item.response';
import { ApiSaveSpecificationResponse } from './api-save-specification.response';
import { ApiGetRoomCodeListResponse } from './api-get-room-code-list.response';
import { ApiGetFinishTableCsv } from './api-get-finish-table-csv';

function* tryGetHouse(
  action: ReturnType<typeof SpecificationActions.getHouse>
) {
  try {
    const result: ResType<ApiGetSpecificationHouseResponse> = yield action.payload.run();
    yield put(AuthActions.setToken(action.payload.token));
    if (ApiBase.isSuccess(result)) {
      yield put(SpecificationActions.setHouse(result.data));
    } else if (ApiBase.isTokenError(result)) {
      yield put(SystemActions.tokenError(result.errors));
    } else {
      yield put(DialogActions.pushMessage({ message: [result.errors] }));
      yield put(push(RoutingPath.houseList));
    }
  } catch (e) {
    yield put(SystemActions.networkError());
  }
}

function* tryGetRoom(action: ReturnType<typeof SpecificationActions.getRoom>) {
  try {
    const result: ResType<ApiGetSpecificationRoomResponse> = yield action.payload.run();
    yield put(AuthActions.setToken(action.payload.token));
    if (ApiBase.isSuccess(result)) {
      yield put(SpecificationActions.setRoom(result.data));
    } else if (ApiBase.isTokenError(result)) {
      yield put(SystemActions.tokenError(result.errors));
    } else {
      yield put(DialogActions.pushMessage({ message: [result.errors] }));
      yield put(push(RoutingPath.houseList));
    }
  } catch (e) {
    yield put(SystemActions.networkError());
  }
}

function* tryGetSelecteria(
  action: ReturnType<typeof SpecificationActions.getSelecteria>
) {
  try {
    const result: ResType<ApiGetSpecificationSelecteriaResponse> = yield action.payload.run();
    yield put(AuthActions.setToken(action.payload.token));
    if (ApiBase.isSuccess(result)) {
      yield put(SpecificationActions.setSelecteria(result.data));
    } else if (ApiBase.isTokenError(result)) {
      yield put(SystemActions.tokenError(result.errors));
    } else {
      yield put(DialogActions.pushMessage({ message: [result.errors] }));
      yield put(push(RoutingPath.houseList));
    }
  } catch (e) {
    yield put(SystemActions.networkError());
  }
}

function* tryGetItem(action: ReturnType<typeof SpecificationActions.getItem>) {
  yield put(DialogActions.isLoading(true));
  yield put(SpecificationActions.setItem(null));
  try {
    const result: ResType<
      ApiGetSpecificationItemResponse>
      = yield action.payload.api.run();
    yield put(AuthActions.setToken(action.payload.api.token));
    if (ApiBase.isSuccess(result)) {
      yield put(SpecificationActions.setItem(result.data));
      if (action.payload.onSuccess) action.payload.onSuccess();
    } else if (ApiBase.isTokenError(result)) {
      yield put(SystemActions.tokenError(result.errors));
    } else {
      yield put(DialogActions.pushMessage({ message: [result.errors] }));
    }
  } catch (e) {
    yield put(SystemActions.networkError());
  }
  yield put(DialogActions.isLoading(false));
}

function* tryGetFinishTableCsv(action: ReturnType<typeof SpecificationActions.getFinishTableCsv>) {
  try {
    const result: ResFileType = yield action.payload.api.run();
    if (ApiBase.isSuccess(result)) {
      const date = new Date();
      const y = date.getFullYear() + '';
      const m = ('0' + String(date.getMonth() + 1)).slice(-2);
      const d = ('0' + String(date.getDate())).slice(-2);

      const url = URL.createObjectURL(result.file);
      const a: HTMLAnchorElement = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.setAttribute('download', `${action.payload.api.id} ${y}${m}${d}.csv`);
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      action.payload.onSuccess?.();
    } else {
      yield put(DialogActions.pushMessage({message: [result.errors]}));
    }
  } catch (e) {
    action.payload.onError?.();
    yield put(SystemActions.networkError());
  }
}

function* tryGetRoomCodeList(action: ReturnType<typeof SpecificationActions.getRoomCodeList>) {
  try {
    const result: ResType<
      ApiGetRoomCodeListResponse>
      = yield action.payload.api.run();
    yield put(AuthActions.setToken(action.payload.api.token));
    localStorage.setItem('token', `${action.payload.api.token}`)
    if (ApiBase.isSuccess(result)) {
      yield put(SpecificationActions.setRoomCodeList(result.data));
      if (action.payload.onSuccess) action.payload.onSuccess();
    } else if (ApiBase.isTokenError(result)) {
      yield put(SystemActions.tokenError(result.errors));
    } else {
      yield put(DialogActions.pushMessage({ message: [result.errors] }));
    }
  } catch (e) {
    yield put(SystemActions.networkError());
  }
  yield put(DialogActions.isLoading(false));
}

function* tryGetSpecificationPdf(
  action: ReturnType<typeof SpecificationActions.getPdf>
) {
  yield put(DialogActions.isLoading(true));
  try {
    const result: ResFileType = yield action.payload.api.run();
    yield put(AuthActions.setToken(action.payload.api.token));
    if (ApiBase.isSuccess(result)) {
      action.payload.onSuccess(URL.createObjectURL(result.file), result.file.name);
    } else {
      yield put(DialogActions.pushMessage({
        message: [result.errors],
        onClick: () => {
          window.close();
        }
      }));
    }
  } catch (e) {
    window.close();
  }
  yield put(DialogActions.isLoading(false));
}
function* trySaveSpecification(
  action: ReturnType<typeof SpecificationActions.saveSpecification>
) {
  const { api, isEnd, onError } = action.payload;
  try {
    const result: ResType<ApiSaveSpecificationResponse> = yield api.run();
    yield put(AuthActions.setToken(action.payload.api.token));
    if (ApiBase.isSuccess(result)) {
      if (isEnd) {
        yield put(
          DialogActions.push({
            message: ['保存が完了しました'],
            dialogType: 'message',
            btnProps: [
              { label: 'OK', action: () => Store.dispatch(DialogActions.pop()) },
            ],
          })
        );
      }
      if (action.payload.onSuccess) action.payload.onSuccess();
    } else if (ApiBase.isTokenError(result)) {
      yield put(SystemActions.tokenError(result.errors));
    } else {
      yield put(DialogActions.pushMessage({ message: [result.errors] }));
    }
  } catch (e) {
    onError?.();
    yield put(SystemActions.networkError());
  }
}

function* trySetFloorTheme(
  action: ReturnType<typeof SpecificationActions.setFloorTheme>
) {
  const { api, onSuccess } = action.payload;
  try {
    const result:
      ApiSaveSpecificationResponse
      & { status: number; errors: string | string[]; }
      = yield api.run();
    yield put(AuthActions.setToken(action.payload.api.token));
    if (ApiBase.isSuccess(result)) {
      yield put(
        DialogActions.push({
          message: ['一括設定が完了しました'],
          dialogType: 'message',
          btnProps: [
            {
              label: 'OK',
              action: () => {
                if (onSuccess) onSuccess();
                Store.dispatch(DialogActions.clear());
              }
            },
          ],
        })
      );
    } else if (ApiBase.isTokenError(result)) {
      yield put(SystemActions.tokenError(typeof result.errors === 'string' ? result.errors : ''));
    } else {
      yield put(DialogActions.pushMessage({ message: typeof result.errors !== 'string' ? result.errors : [''] }));
    }
  } catch (e) {
    yield put(SystemActions.networkError());
  }
}

export function* SpecificationSaga() {
  yield takeEvery(SpecificationActions.getPdf, tryGetSpecificationPdf);
  yield takeEvery(SpecificationActions.getHouse, tryGetHouse);
  yield takeEvery(SpecificationActions.getRoom, tryGetRoom);
  yield takeEvery(SpecificationActions.getSelecteria, tryGetSelecteria);
  yield takeEvery(SpecificationActions.getItem, tryGetItem);
  yield takeEvery(SpecificationActions.saveSpecification, trySaveSpecification);
  yield takeEvery(SpecificationActions.setFloorTheme, trySetFloorTheme);
  yield takeEvery(SpecificationActions.getRoomCodeList, tryGetRoomCodeList);
  yield takeEvery(SpecificationActions.getFinishTableCsv, tryGetFinishTableCsv);
}