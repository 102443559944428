import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';
import { CoordinatorActions } from './coordinator.action';
import { CoordinatorState } from './coordinator.type';

const initialState: CoordinatorState = {
  coordinatorList: [],
};

export const CoordinatorReducer = reducerWithInitialState<CoordinatorState>(initialState)
  .case(CoordinatorActions.setCoordinatorList, (state, payload) => ({
    ...state,
    coordinatorList: lodash.cloneDeep(payload),
  }))
  .case(CoordinatorActions.resetState, () => initialState)
  .default((state) => state);
