import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';
import { SpecificationState } from './specification.type';
import { SpecificationActions } from './specification.action';

const initialState: SpecificationState = {
  houseSpecification: null,
  selecteria: null,
  specificationHouse: null,
  specificationItem: null,
  roomCodeList: null,
  specificationRooms: null,
};

export const SpecificationReducer = reducerWithInitialState<SpecificationState>(
  initialState
)
  .case(SpecificationActions.setHouse, (state, payload) => ({
    ...state,
    specificationHouse: lodash.cloneDeep(payload),
  }))
  .case(SpecificationActions.setItem, (state, payload) => ({
    ...state,
    specificationItem: lodash.cloneDeep(payload),
  }))
  .case(SpecificationActions.setRoom, (state, payload) => ({
    ...state,
    specificationRooms: lodash.cloneDeep(payload),
  }))
  .case(SpecificationActions.setRoomCodeList, (state, payload) => ({
    ...state,
    roomCodeList: lodash.cloneDeep(payload),
  }))
  .case(SpecificationActions.setSelecteria, (state, payload) => ({
    ...state,
    selecteria: lodash.cloneDeep(payload),
  }));
