import { ApiBase } from '../api-base';
import { HeadersAuthJson } from '../api-headers';
import { ApiGetSpecificationHouseResponse } from './api-get-specification-house.response';

export type Param = {
  article_code: string;
};

export class ApiGetSpecificationHouse extends ApiBase<ApiGetSpecificationHouseResponse> {
  constructor(param: Param) {
    super({
      httpMethod: 'GET',
      contents: 'JSON',
      url: `/api/article/${param.article_code}`,
      param: {},
      header: HeadersAuthJson(),
      host: 'https://hinokiya-specification-dev.marietta.co.jp'
    });
  }
}
