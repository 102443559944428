type Param = {
  result: any;
  onSuccess: () => void;
  onError: () => void;
};

export class RequestBase {
  constructor(public param: Param) {}

  public run() {
    // if (this.param.result !== null && this.param.result.status === 200) {
    if (this.param.result !== null) {
      this.onSuccess();
    } else {
      this.onError();
    }
  }

  public onSuccess() {
    this.param.onSuccess();
  }

  public onError() {
    this.param.onError();
  }
}
