import { SpecificationItem, RoomCode } from '../../../api/specification/specification.type';
import { DisplayElements } from '../../../models/display-elements';
import * as lodash from 'lodash';

type SpecificationControllerParam = {
  data: SpecificationItem;
  roomCodeList: RoomCode[] | null;
  setTableData: (listData: any) => void;
  setRowSpanOption: (optionData: any) => void;
};

/**
* 保存時に送るパラメータ
*/
export type SaveParam = {
  element_group_code: string;
  element_code: string;
  item_code: string;
  room_code?: string;
}[];



export class SpecificationController {
  /** SpecificationItem */
  public data: SpecificationItem;
  /** 部材にデフォルトで紐付けるコード */
  public defaultBuiCodeList: string[][] = [];
  /** 部位と紐付いた色軍のIndexのList */
  public selectColorsIndexList: number[][] = [];
  /** 同じ部材が追加された場合カウントする */
  public sameBuzaiCountList: { element_code: string, count: number; }[][] = [];

  public selectRoomCodeList: string[] = [];


  /** State */
  public setTableData: (listData: any) => void;
  public setRowSpanOption: (optionData: any) => void;

  public listArr: DisplayElements[][] = [];
  public rowSpanOptionListArr: any[] = [];
  public lineCount: number = 0;
  public totalIndex: number = 0;

  constructor(param: SpecificationControllerParam) {
    this.data = lodash.cloneDeep(param.data);
    this.setTableData = param.setTableData;
    this.setRowSpanOption = param.setRowSpanOption;
    this.addSameBuzai();
    this.setInitialList();
    this.makeRoomCodeList(param.roomCodeList);
  };

  private makeRoomCodeList(roomCodeList: RoomCode[] | null) {
    this.selectRoomCodeList = new Array(this.defaultBuiCodeList[0].length).fill('');
    let remakeData: { item_code: string, room_code: string[]; }[] = [];

    if (roomCodeList) {
      let oldCode = '';
      roomCodeList?.forEach((v, i) => {
        if (oldCode === v.item_code) {
          const findIndex = remakeData.findIndex((v2) => v2.item_code === v.item_code);
          remakeData[findIndex].room_code.push(v.room_code);
        } else {
          remakeData.push({ item_code: v.item_code, room_code: [v.room_code] });
        }
        oldCode = v.item_code;
      });
    }

    remakeData.forEach((v, i) => {
      const findIndex = this.defaultBuiCodeList[0].findIndex((val) => val === v.item_code);
      v.room_code.forEach((v2, i2) => {
        this.selectRoomCodeList[findIndex + i2] = v2
      })
    });
  }

  /**
   * 同じ部材だった場合行を追加する
   */
  private addSameBuzai() {
    // 部材被りチェック用変数
    let element_code = '';
    const data = lodash.cloneDeep(this.data);

    data.forEach((group, i1) => {
      group.elements.forEach((buzai: any, i2: number) => {

        if (element_code !== buzai.element_code) {

          if (buzai.default_item_code && buzai.default_item_code.length >= 2) {
            let count = 1;
            buzai.default_item_code.forEach((v: any, i3: number) => {
              if (i3) {
                const addArr = {
                  ...lodash.cloneDeep(buzai),
                  isAddCell: true,
                };
                const findIndex = this.data[i1].elements.findIndex((v: any) => v.element_code === addArr.element_code);
                this.data[i1].elements.splice(findIndex + count, 0, lodash.cloneDeep(addArr));
                // this.data[i1].elements[count].default_item_code = lodash.cloneDeep([this.data[i1].elements[i2].default_item_code[count]]);
                count++;
              }
            });
            element_code = buzai.element_code;
          }
        }
      });
    });
  }

  /**
   * リスト郡に初期値を設定する
   */
  private setInitialList() {
    this.data.forEach((group, i1) => {
      /* 一つ前の部材保管用 */
      let oldBuzai = '';
      /* デフォルトコードの配列の何番目を見るか */
      let defaultCodeIndex = 0;

      this.defaultBuiCodeList.push([]);
      this.selectColorsIndexList.push([]);
      this.sameBuzaiCountList.push([]);


      group.elements.forEach((buzai: any, i2: number) => {

        if (buzai.element_code === oldBuzai) {
          defaultCodeIndex += 1;
        } else {
          defaultCodeIndex = 0;
        }

        this.defaultBuiCodeList[i1].push(
          buzai.default_item_code
            ? buzai.default_item_code[defaultCodeIndex]
            : ''
        );

        const buiCode = this.defaultBuiCodeList[i1][i2];
        if (buiCode !== '') {
          const index = buzai.items.findIndex(
            (element: any) => {
              return element.default_item_code === this.defaultBuiCodeList[i1][i2];
            });

          if (index === -1) {
            let index2 = -1;
            let isFind = false;
            buzai.items.forEach((item: any, i3: number) => {
              index2 = item.detail.findIndex((detail: any) => {
                return detail.item_code === this.defaultBuiCodeList[i1][i2];
              });
              if (index2 !== -1) {
                this.data[i1].elements[i2].items[i3].default_item_code = buiCode;
                isFind = true;
                this.selectColorsIndexList[i1].push(i3);
                return;
              }
            });
            if (!isFind) {
              this.selectColorsIndexList[i1].push(-1);
            }
          } else {
            this.selectColorsIndexList[i1].push(-1);
          }
        } else {
          this.selectColorsIndexList[i1].push(-1);
        }
        oldBuzai = buzai.element_code;
        this.sameBuzaiCountList[i1].push({ element_code: buzai.element_code || '', count: 0 });
      });
    });
  }



  /**
  * 部屋を紐付ける
  */
  public addSelectRoom(index: number) {
    this.selectRoomCodeList.splice(index, 0, this.selectRoomCodeList[index]);
  }

  /**
  * 部屋を紐付ける
  */
  public deleteSelectRoom(index: number) {
    this.selectRoomCodeList.splice(index, 1);
  }

  /**
  * 部屋を紐付ける
  */
  public setSelectRoom(index: number, roomCode: string) {
    this.selectRoomCodeList[index] = roomCode;
  }

  /**
  * 部材のデフォルトアイテムコードを変更する
  * itemIndexはカラー変更時に渡す
  */
  public setDefaultItemCode(groupIndex: number, buzaiIndex: number, defaultIndex: number, itemCode: string, itemIndex?: number) {
    if (itemIndex !== undefined && itemCode !== '') {
      this.data[groupIndex].elements[buzaiIndex].items[itemIndex].default_item_code = itemCode;
    }

    if (this.data[groupIndex].elements[buzaiIndex].default_item_code === null) {
      this.data[groupIndex].elements[buzaiIndex].default_item_code = [itemCode];
    } else {
      this.data[groupIndex].elements[buzaiIndex].default_item_code[defaultIndex] = itemCode;
    }
    this.defaultBuiCodeList[groupIndex][buzaiIndex] = itemCode;
  }

  /**
  * 部材のデフォルトアイテムコードを追加する
  */
  public addDefaultItemCode(groupIndex: number, buzaiIndex: number, itemCode: string | null) {
    if (this.data[groupIndex].elements[buzaiIndex].default_item_code === null) {
      this.data[groupIndex].elements[buzaiIndex].default_item_code = null;
    } else {
      this.data[groupIndex].elements[buzaiIndex].default_item_code.push(itemCode);
    }
    this.defaultBuiCodeList[groupIndex].splice(buzaiIndex + 1, 0, itemCode || '');
  }

  /**
   * 部位に紐付いたアイテム(色)郡を取得する
   */
  public getColors(groupIndex: number, buzaiIndex: number, buiIndex: number) {
    return (
      this.data[groupIndex]
        .elements[buzaiIndex]
        .items[buiIndex].detail
    );
  }

  // /**
  //  * 同じ部材のカウントをする
  //  */
  // public setSameBuzaiCountUp() {
  //   this.sameBuzaiCountList.forEach((v1, i) => {
  //     this.sameBuzaiCountList[i].forEach((v2, i2) => {
  //       let counter = 0;
  //       this.sameBuzaiCountList[i].forEach((v3, i3) => {
  //         if (i2 !== i3) {
  //           const flag = v2.element_code === v3.element_code;
  //           counter = flag ? counter + 1 : counter;
  //           this.sameBuzaiCountList[i][i2] =
  //             flag
  //               ? { element_code: v2.element_code, count: counter }
  //               : this.sameBuzaiCountList[i][i2];
  //         }
  //       });
  //     });
  //   });
  // }

  /**
   * 保存するための値を取得する
   */
  public getSendData(roomSelection: number): SaveParam {
    const list: SaveParam = [];
    this.data.forEach((group: any, i1) => {
      group.elements.forEach((buzai: any, i2: number) => {
        const { element_group_code } = group;
        const { element_code } = buzai;
        if (this.defaultBuiCodeList[i1][i2] !== '' && this.defaultBuiCodeList[i1][i2] !== undefined && this.defaultBuiCodeList[i1][i2] !== null) {
          if (roomSelection === 1) {
            list.push({ element_group_code, element_code, item_code: this.defaultBuiCodeList[i1][i2], room_code: this.selectRoomCodeList[i2] });
          } else {
            list.push({ element_group_code, element_code, item_code: this.defaultBuiCodeList[i1][i2] });
          }
        }
      });
    });
    return list;
  }

  /**
   * 部位郡取得
   */
  public getSelectBuis(buzaiItems: any) {
    return [...buzaiItems].map((bui: any) => {
      return {
        value: bui.default_item_code || '',
        label: bui.item_name,
      };
    });
  }

  /**
   * カラー郡取得
   */
  public getSelectColors(i1: number, i2: number, buzaiItems: any) {
    return this.selectColorsIndexList[i1][i2] !== -1
      ? buzaiItems[this.selectColorsIndexList[i1][i2]].detail.map(
        (color: any) => {
          return {
            value: color.item_code || '',
            label: color.color || '色情報無し',
            imgUrl: color.image_url,
          };
        }
      )
      : [];
  }

  /**
   * 部位タイトル取得
   */
  public getBuiTitle(i1: number, i2: number, buzaiItems: any): string {
    return this.selectColorsIndexList[i1][i2] !== -1
      ? buzaiItems[
        this.selectColorsIndexList[i1][i2]
      ].item_name
      : '';
  }
  /**
   * カラータイトル取得
   */
  public getColorTitle(i1: number, i2: number, buzaiItems: any): string {
    return this.selectColorsIndexList[i1][i2] !== -1
      ? buzaiItems[
        this.selectColorsIndexList[i1][i2]
      ].detail.filter(
        (v: any) =>
          v.item_code === this.defaultBuiCodeList[i1][i2]
      )[0].color
      : '';
  }

  /**
   * カウント用変数初期化
   * ここのデータ成形というか「rowSpanOptionListArr」と「lineCount」の作成
   */
  public formingTableData() {
    this.data.forEach((_: any) => {
      this.totalIndex += 1;
    });
  }

  /**
   * data state更新
   */
  public updateState() {
    this.setTableData(this.listArr);
    this.setRowSpanOption(this.rowSpanOptionListArr);
  }

  /**
   * ロウスパンオブション / ラインカウント の追加
   */
  public pushRowSpanOptionListArr(length: number) {
    this.rowSpanOptionListArr.push({
      line: this.lineCount,
      item: 0,
      length,
    });
    this.lineCount += length;
  }

  /**
   * カウント用変数初期化
   */
  public setInitialData() {
    this.listArr = [];
    this.rowSpanOptionListArr = [];
    this.lineCount = 0; // rowSpan指定用
    this.totalIndex = 0; // 全体の順番
  }
}