import React, {useCallback, useState} from 'react';
import {GlobalStyleComponent} from '../../../styles/component-global-style';
import {CsvUploadStyle as S} from '../../../styles/pages/csv-upload/csv-upload.style';
import {InputFile} from '../../ui/input/input-file';
import {ButtonDefault} from '../../ui/button';
import img_fileUpload from '../../../assets/images/file_upload.svg';
import {FormatBytes} from '../../../utilities/format-bytes';
import {useDispatch, useSelector} from 'react-redux';
import {UploadMasterActions} from '../../../api/upload-master/upload-master.action';
import {State} from '../../../redux/root.reducer';
import {Select} from '../../ui/select/select';
import * as lodash from 'lodash';
import {DialogActions} from '../../../redux/dialog/dialog.action';
import {DialogBodyEle2} from '../master/master-upload';

const CsvUpload = () => {
  const dispatch = useDispatch();
  const masterType = useSelector((state: State) => state.upload.masterType);
  const btnOption = {
    paddingSize: {
      paddingTop: 15,
      paddingRight: 20,
      paddingBottom: 15,
      paddingLeft: 20,
    },
    fontSize: 20,
  };
  const [uploadMasterType, setUploadMasterType] = useState<string>(masterType);
  const [fileList, setFileList] = useState<File[]>([]);
  const [isDrag, setIsDrag] = useState<boolean>(false);
  const [extensionsError, setExtensionsError] = useState<boolean>(false);
  const handlerClickUpload = useCallback(() => {
    dispatch(
      UploadMasterActions.upload({type: uploadMasterType, csvs: fileList})
    );
  }, [dispatch, fileList, uploadMasterType]);

  const handleChangeSelect = useCallback(
    (type: string) => {
      setUploadMasterType(type);
      dispatch(UploadMasterActions.setMasterType(type));
    },
    [dispatch]
  );

  const handleClickDownload = useCallback(() => {
    dispatch(
      DialogActions.push({
        dialogType: 'default',
        title: '認証',
        handlerClickCloseBtn: () => {
          dispatch(DialogActions.pop());
        },
        bodyEle: <DialogBodyEle2 />,
      })
    );
  }, [dispatch]);

  const handleClickDeleteFile = useCallback(
    (fileIndex: number) => {
      const copyFileList = lodash.cloneDeep(fileList);
      copyFileList.splice(fileIndex, 1);
      setFileList(copyFileList);
    },
    [fileList]
  );

  // drag & drop関連ハンドラ
  const handlerOnDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDrag(true);
  }, []);
  const handlerOnDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDrag(false);
  }, []);
  const handlerOnDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      setIsDrag(false);
      let fileArr: File[] = [];
      Object.keys(e.dataTransfer.files).forEach((keys: any, i) => {
        const fileType = e.dataTransfer.files[keys].type;
        if (
          fileType === 'text/csv' ||
          fileType === 'application/vnd.ms-excel'
        ) {
          fileArr.push(e.dataTransfer.files[keys]);
        } else {
          setExtensionsError(true);
        }
      });
      if (!fileArr.length) {
        setExtensionsError(true);
      } else {
        setExtensionsError(false);
      }
      // setFileList(Array.prototype.map.call(e.dataTransfer.files, (v: File) => v) as File[]);
      setFileList(fileList.concat(fileArr));
    },
    [fileList]
  );
  const handlerOnClick = useCallback(
    (files: File[]) => {
      setFileList([...fileList, ...files]);
    },
    [fileList]
  );

  // select用
  const selectoptionList = [
    {value: 'house', label: '物件情報'},
    {value: 'finish_table', label: '仕上表情報'},
    {value: 'room', label: '部屋名称'},
    {value: 'element_group', label: '部材グループマスタ'},
    {value: 'element', label: '部材マスタ'},
    {value: 'item', label: 'アイテムマスタ'},
    {value: 'series', label: '商品シリーズマスタ'},
    {value: 'selecteria', label: 'セレクテリアマスタ'},
    {value: 'user', label: 'ユーザマスタ'},
  ];

  return (
    <S.CsvUploadWrap id="Component_CsvUpload">
      <GlobalStyleComponent.FlexAlignCenter>
        CSV アップロード
        <GlobalStyleComponent.spacer theme={{size: 20}} />
        <div className="uploadTypeSelect_wrap">
          <Select
            optionList={selectoptionList}
            value={uploadMasterType}
            onChange={(e) => handleChangeSelect(e)}
            allowUnselected
          />
        </div>
        <GlobalStyleComponent.FlexAlignCenter>
          <GlobalStyleComponent.spacer theme={{size: 100}} />

          <ButtonDefault
            label="CSV アップロード"
            option={btnOption}
            onClick={handlerClickUpload}
          />

          <GlobalStyleComponent.spacer theme={{size: 50}} />

          <ButtonDefault
            label="CSV ダウンロード"
            option={btnOption}
            onClick={handleClickDownload}
          />
        </GlobalStyleComponent.FlexAlignCenter>
      </GlobalStyleComponent.FlexAlignCenter>

      <GlobalStyleComponent.divide theme={{size: 40}} />

      <S.DragArea
        className={isDrag ? ' isDragActive' : ''}
        onDragOver={(e) => handlerOnDrag(e)}
        onDragLeave={(e) => handlerOnDragLeave(e)}
        onDrop={(e) => handlerOnDrop(e)}
      >
        <img
          src={img_fileUpload}
          alt="upload"
          className="img_upLoad marg_auto"
          draggable="false"
        />
        <GlobalStyleComponent.divide theme={{size: 30}} />
        ファイルをドラッグ＆ドロップするかクリップボードから画像を貼り付けしてください
        <GlobalStyleComponent.divide theme={{size: 30}} />
        または
        <GlobalStyleComponent.divide theme={{size: 30}} />
        <div className="inputFile_wrap marg_auto">
          <InputFile
            value={fileList}
            onChange={handlerOnClick}
            acceptType=".csv"
            multiple
          />
        </div>
        <GlobalStyleComponent.divide theme={{size: 15}} />
        ファイル追加（Shiftキーを押しながらファイルを複数選択可能）
      </S.DragArea>

      {fileList && fileList.length > 0 ? (
        <>
          <GlobalStyleComponent.divide theme={{size: 20}} />
          <S.SelectedList>
            <div className="align_c">- 選択中 -</div>
            <GlobalStyleComponent.divide theme={{size: 10}} />
            <GlobalStyleComponent.FlexWrap className="list_wrap">
              {fileList?.map((file, i) => {
                if (file) {
                  return (
                    <div key={i} className="flex_box flex_align_center">
                      {file.name}({FormatBytes(file.size, 0)}){' '}
                      <GlobalStyleComponent.spacer theme={{size: 5}} />
                      <button
                        className="reset delete_btn"
                        onClick={() => {
                          handleClickDeleteFile(i);
                        }}
                      >
                        &#10005;
                      </button>
                      <GlobalStyleComponent.spacer theme={{size: 5}} />
                    </div>
                  );
                }
              })}
            </GlobalStyleComponent.FlexWrap>
          </S.SelectedList>
        </>
      ) : (
        <></>
      )}
      {extensionsError ? (
        <>
          <S.ExtensionsError>
            <GlobalStyleComponent.divide theme={{size: 10}} />
            ※「.csv」以外のファイルはアップロードできません。
          </S.ExtensionsError>
        </>
      ) : (
        <></>
      )}

      <GlobalStyleComponent.divide theme={{size: 80}} />

      <GlobalStyleComponent.FlexAlignCenterContentCenter>
        {/* <ButtonDefault
          label="CSV アップロード"
          option={uploadBtnOption}
          onClick={handlerClickUpload}
        /> */}
      </GlobalStyleComponent.FlexAlignCenterContentCenter>

      <GlobalStyleComponent.divide theme={{size: 80}} />

      <GlobalStyleComponent.FlexAlignCenterContentCenter>
        {/* <ButtonDefault
          label="CSV ダウンロード"
          option={downloadBtnOption}
          onClick={handleClickDownload}
        /> */}
      </GlobalStyleComponent.FlexAlignCenterContentCenter>

      <GlobalStyleComponent.divide theme={{size: 60}} />
    </S.CsvUploadWrap>
  );
};

export default CsvUpload;
