import React from 'react';
import { PageBaseStyle as S } from '../../../styles/ui/page-base/page-base.style';
import {PageBaseHeader} from "./page-base-header";

type PageBaseProps = {
  title?: string,
  children?: JSX.Element | JSX.Element[],
  option?: {
    headerRightHide?: boolean,
  },
  pageId: string,
};

export const PageBase = (props: PageBaseProps) => {
  const { title, children, option, pageId } = props;
  return (
    <S.BaseWrap id={pageId}>
      <div className='PageBaseInner'>

        <PageBaseHeader
          title={title}
          option={option}
        />

        <S.BaseBody>
          {children}
        </S.BaseBody>

      </div>
    </S.BaseWrap>
  );
};
