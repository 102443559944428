import actionCreatorFactory from 'typescript-fsa';
import { ApiGetHouseDetail } from './api-get-house-detail';
import { ApiGetHouseList } from './api-get-house-list';
import * as Type from './house.type';
import { ApiPostHouseStatus } from './api-post-house-status';

type CallbackHouseDetail = {
  api: ApiGetHouseDetail;
  onSuccess: (data: Type.HouseDetail) => void;
};

const ActionCreator = actionCreatorFactory('house');

export const HouseActions = {
  getHouseList: ActionCreator<ApiGetHouseList>('get/house/list'),
  setHouseList: ActionCreator<Type.HouseData[]>('set/house/list'),
  getHouseDetail: ActionCreator<CallbackHouseDetail>('get/HouseDetail'),
  setHouseDetail: ActionCreator<Type.HouseDetail>('set/HouseDetail'),
  setSort: ActionCreator<{ name: string | null; }>('set/sort'),
  postHouseStatus: ActionCreator<{ api: ApiPostHouseStatus, callback: () => void; }>('post/house/status'),
  resetState: ActionCreator('reset/state'),
};