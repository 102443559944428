import { ApiBase } from '../api-base';
import { HeadersAuthJson } from '../api-headers';
import { ApiLogoutResponse } from './api-logout.response';

export class ApiLogout extends ApiBase<ApiLogoutResponse> {
  constructor() {
    super({
      httpMethod: 'POST',
      contents: 'JSON',
      url: `/api/auth/logout`,
      header: HeadersAuthJson(),
      host: 'https://hinokiya-specification-dev.marietta.co.jp'
    });
  }
}
