import { ApiBase } from '../api-base';
import { HeadersAuthJson } from '../api-headers';
import { ApiGetSpecificationRoomResponse } from './api-get-specification-room.response';

export type Param = {
  article_code: string;
}

export class ApiGetSpecificationRoom extends ApiBase<ApiGetSpecificationRoomResponse> {
  constructor(param:Param) {
    super({
      httpMethod: 'GET',
      contents: 'JSON',
      url: `/api/article/${param.article_code}/room`,
      param: {},
      header: HeadersAuthJson(),
      host: 'https://hinokiya-specification-dev.marietta.co.jp'
    });
  }
}
