import { ApiBase } from '../api-base';
import { HeadersDefaultJson } from '../api-headers';
import { ApiChangePasswordResponse } from './api-change-password.response';

type Param = {
  email: string;
  password: string;
  password_confirmation: string;
};

export class ApiChangePassword extends ApiBase<ApiChangePasswordResponse> {
  constructor(param: Param) {
    super({
      httpMethod: 'POST',
      header: HeadersDefaultJson(),
      contents: 'JSON',
      url: '/api/auth/password',
      body: param,
      host: 'https://hinokiya-specification-dev.marietta.co.jp'
    });
  }
}
