import { createGlobalStyle } from 'styled-components';
import { CommonDefine } from './define';
import {GlobalStyleComponent} from './component-global-style';


const GlobalStyle = createGlobalStyle`
html {
 width: 100%;
 height: 100%;
 //overflow: hidden;
 overflow: auto;
}

body {
 font-family: ${CommonDefine.fontFamily};
 width: 100%;
 height: 100%;
 min-width: 1200px;
 //min-height: 768px;
 //overflow: auto;
 font-size: ${CommonDefine.mainFontSize}px !important;
 margin: 0 auto;
 padding: 0;
 color: ${CommonDefine.mainFontColor};
 line-height: ${CommonDefine.lineHeight};
 word-wrap: break-word;
 background-color: #ffffff;
}

h1, h2, h3, h4, h5, p, a, li {
 margin: 0;
 padding: 0;
 line-height: ${CommonDefine.lineHeight};
 font-weight: normal !important;
   font-family: ${CommonDefine.fontFamily};
}

select {
   font-family: ${CommonDefine.fontFamily};
}

button {
 cursor: pointer;
   font-family: ${CommonDefine.fontFamily};
 
 &.reset {
   margin: 0;
   padding: 0;
   background: none;
   border: 0;
 }

 &:disabled,
 &.disabled {
   opacity: 0.3;
   pointer-events: none;
   cursor: default;
 }
}

a,
input[type='button'],
button {
 &:hover {
   ${CommonDefine.animationSlow}
 }
}

a {
  color: #2ea7e0;
  text-decoration: underline;
  
  &:hover {
    text-decoration: none;
  }
}

input,
textarea {
   font-family: ${CommonDefine.fontFamily};
   font-size: 18px;
}

img {
 display  : block;
 width    : auto;
 max-width: 100%;
}

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
 border-radius: 0;
 -webkit-box-sizing: content-box;
 -webkit-appearance: button;
 appearance: button;
 border: none;
 box-sizing: border-box;
 cursor: pointer;
}
input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
 display: none;
}
input[type="submit"]:focus,
input[type="button"]:focus {
 outline-offset: -2px;
}
input[type="date"]::-webkit-inner-spin-button{
 -webkit-appearance: none;
}
input[type="date"]::-webkit-clear-button{
 -webkit-appearance: none;
}

ul {
 list-style-type: none;
 margin: 0;
 padding: 0;

 &.style_disc {
   list-style-type: disc;
   padding-left: 2rem;
 }

 &.style_decimal {
   list-style-type: decimal;
   padding-left: 2rem;
 }

 &.style_lower_alpha {
   list-style-type: lower-alpha;
   padding-left: 2rem;
 }
}

*            {box-sizing: border-box;}
*:focus      {outline:none;}
.cursor_p    {cursor: pointer;}
.padd_0      {padding: 0 !important;}
.marg_0      {margin: 0 !important;}
.marg_auto   {margin: 0 auto!important;}
.font_b      {font-weight: bold!important;}
.font_n      {font-weight: normal!important;}
.line_h_1    {line-height: 1;}
.flex_1      {flex: 1;}
.display_b   {display: block;}
.display_inb {display: inline-block;}
.align_c     {text-align: center!important;}
.align_r     {text-align: right!important;}
.align_l     {text-align: left!important;}
.position_r  {position: relative;}
.c_red       {color: ${CommonDefine.errorColor};}
.point_none  {pointer-events: none;}

.flex_box              {${CommonDefine.flex_box}}
.flex_align_start      {${CommonDefine.flex_align_start}}
.flex_align_stretch    {${CommonDefine.flex_align_stretch}}
.flex_align_center     {${CommonDefine.flex_align_center}}
.flex_align_end        {${CommonDefine.flex_align_end}}
.flex_space_between    {${CommonDefine.flex_space_between}}
.flex_content_center   {${CommonDefine.flex_content_center}}
.flex_content_end      {${CommonDefine.flex_content_end}}
.flex_content_around   {${CommonDefine.flex_content_around}}
.flex_container_wrap   {${CommonDefine.flex_container_wrap}}
.flex_direction_column {${CommonDefine.flex_direction_column}}

select::-ms-expand {
 display: none;
}

// /*スクロールバー全体*/
// ::-webkit-scrollbar {
//   width: 10px;
// }
//
// /*スクロールバーの軌道*/
// ::-webkit-scrollbar-track {
//   border-radius: 10px;
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
// }
//
// /*スクロールバーの動く部分*/
// ::-webkit-scrollbar-thumb {
//   background-color: ${CommonDefine.baseBgColor};
//   border-radius: 10px;
//   box-shadow:0 0 0 1px rgba(255, 255, 255, .3);
// }

.disabled {
  opacity: 0.3!important;
  pointer-events: none!important;
  cursor: default!important;
}

.hover_opa {
  &:hover {
    opacity: 0.7;
  }
}

// デバッグ用
.debug_wrap {
  position: fixed;
  right: 15px;
  bottom: 15px;
  background-color: rgba(255,255,255,0.7);
  padding: 15px;
  border: 1px solid ${CommonDefine.inputBorderColor};
  border-radius: 5px;
  box-shadow:4px 4px 9px -1px #999999;
  z-index: 100000;
}

.pdfDlBtn_wrap {
   position: fixed;
   right: 45px;
   top: 70px;
   background-color: #ffffff;
   border: 3px solid ${CommonDefine.baseBgColor};
   border-radius: 5px;
   box-shadow:4px 4px 9px -1px #999999;
   z-index: 100000;
   
   button {
      ${CommonDefine.flex_box};
      ${CommonDefine.flex_align_center};
      background: none;
      border: 0;
      padding: 15px;
      margin: 0;
      
      img {
         width: 25px;
      }
   }
}//.pdfDlBtn_wrap

#root {
 height: 100%;

  >div {
    height: 100%;
  }
 .App {
   height: 100%;
   min-width: ${CommonDefine.minWidth}px;
   overflow-x: auto;
    
    &.isTools {
       min-width: 1400px;
    }
 }
 .page_wrap {
    height: 100%;
 }
   
   // select内に画像サムネ表示する用
   .selectImg_wrap {
      ${CommonDefine.flex_box};
      
      .innerImg {
         width: 30px;
         height: 30px;
         ${CommonDefine.flex_box};
         ${CommonDefine.flex_align_center};
         ${CommonDefine.flex_content_center};
         margin-right: 10px;
         
         img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
         }
      }
      .innerLabel {
         flex: 1;
         max-width: calc(100% - 40px);
         padding-top: 5px;
      }
   }
}//#root
`;

export default GlobalStyle;
