import { ApiBase } from '../api-base';
import { ApiCoordinatorCheckResponse } from './api-coodinator-check.response';
import { HeadersAuthJson } from '../api-headers';
type Param = {
  article_code: string;
};

export class ApiCoordinatorCheck extends ApiBase<
  ApiCoordinatorCheckResponse[]
> {
  constructor(param: Param) {
    super({
      httpMethod: 'GET',
      contents: 'JSON',
      url: `/api/article/${param.article_code}/validate`,
      header: HeadersAuthJson(),
      host: 'https://hinokiya-specification-dev.marietta.co.jp',
    })
  }
}