import { Connection } from '../service/connection';
import queryString from 'query-string';
import { ContentsType, HttpMethodType, ParamType } from './api.type';
import { Config } from '../config/config';

// テスト用
const isDev = true;
// export const host = isDev
//   ? 'https://hinokiya-specification-dev.marietta.co.jp'
//   : 'http://13.114.171.38'
export const host = Config.endPoint;

/** 実際にAPIと接続するための元のクラス */
export class ApiBase<T = any, J = any> {
  public httpMethod: HttpMethodType;
  public contents: ContentsType;
  public url: string;
  public param?: ParamType;
  public header: { [key: string]: string | undefined; };
  public body?: any;
  public sendParam?: J;
  public host?: string;
  public token: string | null = null;
  public isBinary?: boolean = false;

  constructor(data: {
    httpMethod: HttpMethodType;
    contents: ContentsType;
    url: string;
    param?: ParamType;
    header: { [key: string]: string | undefined; };
    body?: any;
    sendParam?: J;
    host?: string;
    isBinary?: boolean;
  }) {
    this.httpMethod = data.httpMethod;
    this.contents = data.contents;
    this.url = data.url;
    this.param = data.param || undefined;
    this.header = data.header;
    this.body = data.body;
    this.sendParam = data.sendParam;
    this.host = data.host;
    this.isBinary = data.isBinary;
  }

  /** API接続を実行させる */
  public async run(): Promise<T> {
    return Connection.run<T>(this)
      .then((v) => v)
      .catch((error) => {
        throw error;
      });
  }

  /** URLの生成 */
  public createUrl() {
    const hostName = host;
    const param = this.httpMethod === 'GET' && this.param ? ApiBase.createQueryParam(this.param) : '';
    return `${hostName}${this.url}${param}`;
  }

  /** ヘッダーの生成 */
  public createHeader() {
    const headers = new Headers();
    const keys = Object.keys(this.header);
    const values = Object.values(this.header);

    console.log(this.header)
    keys.forEach((key, i) => {
      if (values[i]) {
        headers.append(key, String(values[i]));
      }
    });
    return headers;
  }

  /** ボディの生成 */
  public createBody() {
    if (!this.isBinary) return JSON.stringify(this.body);

    const formData = new FormData();
    const keys = Object.keys(this.body);
    const values
      : (string | string[] | number | number[] | undefined | File)[] = Object.values(this.body);
    for (let i = 0; i < keys.length; i += 1) {
      if (values[i] !== undefined) {
        formData.append(keys[i], values[i] as string);
      }
    }
    return formData;
  }

  /** 送信情報 */
  public createRequestInit() {
    let requestInit: RequestInit;
    switch (this.httpMethod) {
      case 'GET':
        requestInit = {
          headers: this.createHeader(),
        };
        break;
      case 'POST':
      case 'DELETE':
        requestInit = {
          headers: this.createHeader(),
          method: this.httpMethod,
          body: this.createBody(),
          // mode: 'cors'
        };
        break;
    }
    return requestInit;
  }

  /** パース */
  public parse(response: any): any {
    return response;
  }


  /** 成功判定 */
  static isSuccess(response: any) {
    return (
      response !== null && response.status === 200
    );
  }

  /** トークン判定 */
  static isTokenError(response: any) {
    return (
      response !== null && response.status === 401
    );
  }

  /** クエリパラメータの生成 */
  public static createQueryParam(param: ParamType) {
    return queryString.stringifyUrl(
      { url: '?', query: param },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );
  }
}
