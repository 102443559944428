import React, {ChangeEvent, useCallback, useState} from 'react';
import { InputStyle as S } from '../../../styles/ui/input/input.style';
import { InputText } from './input-text';

/**
 * FileList -> File[] 変換
 * @param fileList
 */
const convertFileList2FileArray = (fileList: FileList): File[] => {
  return [...new Array(fileList.length)]
    .map((_, i) => fileList.item(i))
    .filter((v) => Boolean(v)) as File[];
};

type InputFileProps = {
  multiple?: boolean,
  errorMessageList?: string[],
  value?: File[],
  onChange?: (files: File[]) => void,
  disabled?: boolean,
  acceptType?: string,
};

export const InputFile = (props: InputFileProps) => {
  const {
    multiple, value, errorMessageList = [], onChange, disabled, acceptType,
  } = props;
  const error = Boolean(errorMessageList.length);
  const onChangeFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;
      if (onChange && fileList?.length) {
        onChange(convertFileList2FileArray(fileList));
      }
    },
    [onChange],
  );
  const [checkBoxFocus, setCheckBoxFocus] = useState<boolean>(false);
  return (
    <div className='position_r'>
      {error ? (
        errorMessageList.map((v, i) => (
          <S.InputErrorWrap
            key={i}
          >
            {v}
          </S.InputErrorWrap>
        ))
      ) : (<></>)}
      <S.typeFileInputWrap>
        <label
          className={
            `
            cursor_p
            ${checkBoxFocus ? ' onFocus' : ''}
            `
          }
          onFocus={() => (
            setCheckBoxFocus(true)
          )}
          onBlur={() => (
            setCheckBoxFocus(false)
          )}
        >
          <InputText
            multiple={multiple}
            disabled={disabled}
            type="file"
            className={error ? 'InputTextError' : `${disabled ? (' disabled') : ('')}`}
            onChange={onChangeFile}
            accept={acceptType}
          />
          <div className="typeFileInputStyle font_b">CSV読込</div>
        </label>
      </S.typeFileInputWrap>
    </div>
  );
};
