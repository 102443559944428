import React, {useCallback} from 'react';
import {PageBaseStyle as S} from '../../../styles/ui/page-base/page-base.style';
import img_logo from '../../../assets/images/logo.jpg';
import {ButtonBack} from '../button';
import {State} from '../../../redux/root.reducer';
import {useDispatch, useSelector} from 'react-redux';
import {AuthActions} from '../../../api/auth/auth.action';
import * as lodash from 'lodash';
import {RoutingPath} from '../../../routes/routing-path';
import {push} from 'connected-react-router';
import {useLocation} from 'react-router-dom';

type PageBaseHeaderProps = {
  title?: string;
  option?: {
    headerRightHide?: boolean;
  };
};

export const PageBaseHeader = (props: PageBaseHeaderProps) => {
  const dispatch = useDispatch();
  const isMasterUpload = useLocation().pathname.match('master');

  const userName = useSelector(
    (state: State) => state.auth.loginUser?.user.name,
    lodash.isEqual
  );
  const {title, option} = props;
  // const [userName, setUserName] = useState<string>('');
  const handlerClickLogo = useCallback(() => {
    dispatch(
      push(isMasterUpload ? RoutingPath.masterUpload : RoutingPath.houseList)
    );
  }, [dispatch, isMasterUpload]);
  const handlerClickLogout = useCallback(() => {
    dispatch(AuthActions.logout());
  }, [dispatch]);
  return (
    <S.BaseHeader>
      <div className="logo_wrap" onClick={handlerClickLogo}>
        <img src={img_logo} alt="HINOKIYA"/>
      </div>
      <div className={option?.headerRightHide ? ('title_wrap isHide') : ('title_wrap')}>
        ＩＣマッチングシステム
        {title ? (
          <>
            &nbsp;&nbsp;-&nbsp;&nbsp;
            {title}
          </>
        ) : (
          <></>
        )}
      </div>
      {option?.headerRightHide ? (
        <></>
      ) : (
        <>
          <div className="user_wrap">
            {userName}
            <div className="min_text">&nbsp;&nbsp;さんでログイン中</div>
          </div>
          <div className="btn_wrap">
            <ButtonBack label="ログアウト" onClick={handlerClickLogout} />
          </div>
        </>
      )}
    </S.BaseHeader>
  );
};
