import React, {useEffect} from 'react';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {GlobalStyleComponent} from '../../../styles/component-global-style';
import {LoadingStyle as S} from '../../../styles/ui/loading/loading.style';
import {State} from '../../../redux/root.reducer';

export const Loading = () => {
  const isLoading = useSelector((state: State) => state.dialog.isLoading);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isAnim, setIsAnim] = useState<boolean>(false);
  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsAnim(true);
    }, 400);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
        setIsShow(false);
      return () => {
        setIsShow(false);
      };
    } else {
      setIsShow(true);
    }
  }, [isLoading]);

  return isShow ? (
    <S.LoadingWrap>
      <div className={isAnim ? 'isAnim' : ''}>
        <S.LoadingBody>
          <GlobalStyleComponent.FlexAlignCenterContentCenter>
            <div className="loadingBodyInner">
              <div className="loading-container marg_auto">
                <div className="loading" />
                <div id="loading-text">
                  <span>L</span>
                  <span>O</span>
                  <span>A</span>
                  <span>D</span>
                  <span>I</span>
                  <span>N</span>
                  <span>G</span>
                </div>
              </div>
            </div>
          </GlobalStyleComponent.FlexAlignCenterContentCenter>
        </S.LoadingBody>
      </div>
    </S.LoadingWrap>
  ) : (
    <></>
  );
};
