import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {PageBase} from '../../ui/page-base/page-base';
import {SpecificationToolStyle as S} from '../../../styles/pages/specification-tool/specification-tool.style';
import {Table} from '../../ui/table/table';
import SpecificationToolSelect from './specification-tool-select';
import {useDispatch, useSelector} from 'react-redux';
import {State} from '../../../redux/root.reducer';
import {SpecificationActions} from '../../../api/specification/specification.action';
import * as lodash from 'lodash';
import {GlobalStyleComponent} from '../../../styles/component-global-style';
import {Button, ButtonBack} from '../../ui/button';
import {ApiGetSpecificationHouse} from '../../../api/specification/api-get-specification-house';
import {ApiGetSpecificationRoom} from '../../../api/specification/api-get-specification-room';
import {ApiGetSpecificationSelecteria} from '../../../api/specification/api-get-specification-selecteria';
import {ApiGetSpecificationItem} from '../../../api/specification/api-get-specification-item';
import {
  Buzai,
  SpecificationItem,
  SpecificationRoom,
} from '../../../api/specification/specification.type';
import {SpecificationController} from './specification-controller';
import {useWillUnMount} from '../../../hooks/life-cycle';
import {SearchSelect} from '../../ui/select/search-select';

const MakeTableListData = (
  data: SpecificationController,
  type: 'first' | 'other',
  roomSelection: 0 | 1 | 2,
  specificationRooms: SpecificationRoom[],
  disableSelect?: boolean
) => {
  data.setInitialData();
  /**
   * データをテーブル表示用に成形する関数
   */
  const moldingData = (allData: SpecificationItem) => {
    if (!allData) return;
    data.setInitialData();

    allData.forEach((group, i1: number) => {
      const disabled = disableSelect && group.element_group_code !== '1900';
      group.elements.forEach((buzai: any, i2: number) => {
        data.selectColorsIndexList[i1][i2] = buzai.items.findIndex(
          (element: any) =>
            element.default_item_code === data.defaultBuiCodeList[i1][i2]
        );

        // 追加ボタンを表示するかどうか
        const isAddBtn = '';
        //   data.sameBuzaiCountList[i1][i2].count < 4 ? '' : ' isOver';

        if (i2 === 0) {
          data.listArr.push(
            roomSelection === 1
              ? [
                  // 部材グループ
                  group.element_group_name,
                  // 部材
                  buzai.element_name,
                  // 部位
                  <>
                    {!disabled ? (
                      <SearchSelect
                        title={data.getBuiTitle(i1, i2, buzai.items)}
                        optionList={data.getSelectBuis(buzai.items)}
                        value={data.defaultBuiCodeList[i1][i2]}
                        onChange={(v) => {
                          data.setDefaultItemCode(i1, 0, 0, v);
                          moldingData(data.data);
                          data.updateState();
                        }}
                        disabled={disabled}
                      /> ) : (
                        data.getSelectBuis(buzai.items).find((_data) => _data.value === data.defaultBuiCodeList[i1][i2])?.label ?? ''
                      )}
                  </>,
                  // 色
                  <SearchSelect
                    isColor
                    title={data.getColorTitle(i1, i2, buzai.items)}
                    optionList={data.getSelectColors(i1, i2, buzai.items)}
                    value={data.defaultBuiCodeList[i1][i2]}
                    onChange={(v) => {
                      data.setDefaultItemCode(
                        i1,
                        0,
                        0,
                        v,
                        data.selectColorsIndexList[i1][i2]
                      );
                      moldingData(data.data);
                      data.updateState();
                    }}
                    allowUnselected={true}
                  />,
                  roomSelection === 1 && (
                    <SearchSelect
                      optionList={specificationRooms
                        .filter((v) => v.room_selection === 0)
                        .map((v) => ({
                          value: v.code,
                          label: v.floor,
                        }))}
                      value={data.selectRoomCodeList[i2]}
                      onChange={(v) => {
                        data.setSelectRoom(i2, v);
                        moldingData(data.data);
                        data.updateState();
                      }}
                    />
                  ),
                  <div
                    className={`flex_box flex_align_center cellBtn_wrap${
                      buzai.isAddCell ? ' _wrap isAddCell' : ''
                    }${isAddBtn}`}
                  >
                    <Button
                      label="追加"
                      onClick={() => handlerClickAddCell(i1, i2)}
                      disabled={disabled}
                    />
                    <GlobalStyleComponent.spacer theme={{size: 10}} />
                    <ButtonBack
                      label="削除"
                      onClick={() => handlerCLickDeleteCell(i1, i2)}
                      disabled={disabled}
                    />
                  </div>,
                ]
              : [
                  // 部材グループ
                  group.element_group_name,
                  // 部材
                  buzai.element_name,
                  // 部位
                  <>
                    {!disabled ? (
                      <SearchSelect
                        title={data.getBuiTitle(i1, i2, buzai.items)}
                        optionList={data.getSelectBuis(buzai.items)}
                        value={data.defaultBuiCodeList[i1][i2]}
                        disabled={disabled}
                        onChange={(v) => {
                          data.setDefaultItemCode(i1, 0, 0, v);
                          moldingData(data.data);
                          data.updateState();
                        }}
                      />) : (
                        data.getSelectBuis(buzai.items).find((_data) => _data.value === data.defaultBuiCodeList[i1][i2])?.label ?? ''
                      )}
                  </>,
                  // 色
                  <SearchSelect
                    isColor
                    title={data.getColorTitle(i1, i2, buzai.items)}
                    optionList={data.getSelectColors(i1, i2, buzai.items)}
                    value={data.defaultBuiCodeList[i1][i2]}
                    onChange={(v) => {
                      data.setDefaultItemCode(
                        i1,
                        0,
                        0,
                        v,
                        data.selectColorsIndexList[i1][i2]
                      );
                      moldingData(data.data);
                      data.updateState();
                    }}
                    allowUnselected={true}
                  />,
                  <div
                    className={`flex_box flex_align_center cellBtn_wrap${
                      buzai.isAddCell ? ' _wrap isAddCell' : ''
                    }${isAddBtn}`}
                  >
                    <Button
                      label="追加"
                      onClick={() => handlerClickAddCell(i1, i2)}
                      disabled={disabled}
                    />
                    <GlobalStyleComponent.spacer theme={{size: 10}} />
                    <ButtonBack
                      label="削除"
                      onClick={() => handlerCLickDeleteCell(i1, i2)}
                      disabled={disabled}
                    />
                  </div>,
                ]
          );
        } else {
          data.listArr.push(
            roomSelection === 1
              ? [
                  buzai.element_name,
                  !disabled ? (
                    <SearchSelect
                      title={data.getBuiTitle(i1, i2, buzai.items)}
                      optionList={data.getSelectBuis(buzai.items)}
                      value={data.defaultBuiCodeList[i1][i2]}
                      disabled={disabled}
                      onChange={(v) => {
                        data.setDefaultItemCode(
                          i1,
                          i2,
                          data.selectColorsIndexList[i1][i2],
                          v
                        );
                        moldingData(data.data);
                        data.updateState();
                      }}
                    />) : data.getSelectBuis(buzai.items).find((_data) => _data.value === data.defaultBuiCodeList[i1][i2])?.label ?? '',
                  <SearchSelect
                    isColor
                    title={data.getColorTitle(i1, i2, buzai.items)}
                    optionList={data.getSelectColors(i1, i2, buzai.items)}
                    value={data.defaultBuiCodeList[i1][i2]}
                    onChange={(v) => {
                      data.setDefaultItemCode(
                        i1,
                        i2,
                        data.selectColorsIndexList[i1][i2],
                        v,
                        data.selectColorsIndexList[i1][i2]
                      );
                      moldingData(data.data);
                      data.updateState();
                    }}
                    allowUnselected={true}
                  />,
                  roomSelection === 1 && (
                    <SearchSelect
                      optionList={specificationRooms
                        .filter((v) => v.room_selection === 0)
                        .map((v) => ({
                          value: v.code,
                          label: v.floor,
                        }))}
                      value={data.selectRoomCodeList[i2]}
                      onChange={(v) => {
                        data.setSelectRoom(i2, v);
                        moldingData(data.data);
                        data.updateState();
                      }}
                    />
                  ),
                  <div
                    className={`flex_box flex_align_center cellBtn_wrap${
                      buzai.isAddCell ? ' isAddCell' : ''
                    }${isAddBtn}`}
                  >
                    <Button
                      label="追加"
                      onClick={() => handlerClickAddCell(i1, i2)}
                      disabled={disabled}
                    />
                    <GlobalStyleComponent.spacer theme={{size: 10}} />
                    <ButtonBack
                      label="削除"
                      onClick={() => handlerCLickDeleteCell(i1, i2)}
                      disabled={disabled}
                    />
                  </div>,
                ]
              : [
                  buzai.element_name,
                  !disabled ? (
                    <SearchSelect
                      title={data.getBuiTitle(i1, i2, buzai.items)}
                      optionList={data.getSelectBuis(buzai.items)}
                      value={data.defaultBuiCodeList[i1][i2]}
                      disabled={disabled}
                      onChange={(v) => {
                        data.setDefaultItemCode(
                          i1,
                          i2,
                          data.selectColorsIndexList[i1][i2],
                          v
                        );
                        moldingData(data.data);
                        data.updateState();
                      }}
                    />) : (
                      data.getSelectBuis(buzai.items).find((_data) => _data.value === data.defaultBuiCodeList[i1][i2])?.label ?? ''
                    ),
                  <SearchSelect
                    isColor
                    title={data.getColorTitle(i1, i2, buzai.items)}
                    optionList={data.getSelectColors(i1, i2, buzai.items)}
                    value={data.defaultBuiCodeList[i1][i2]}
                    onChange={(v) => {
                      data.setDefaultItemCode(
                        i1,
                        i2,
                        data.selectColorsIndexList[i1][i2],
                        v,
                        data.selectColorsIndexList[i1][i2]
                      );
                      moldingData(data.data);
                      data.updateState();
                    }}
                    allowUnselected={true}
                  />,
                  <div
                    className={`flex_box flex_align_center cellBtn_wrap${
                      buzai.isAddCell ? ' isAddCell' : ''
                    }${isAddBtn}`}
                  >
                    <Button
                      label="追加"
                      onClick={() => handlerClickAddCell(i1, i2)}
                      disabled={disabled}
                    />
                    <GlobalStyleComponent.spacer theme={{size: 10}} />
                    <ButtonBack
                      label="削除"
                      onClick={() => handlerCLickDeleteCell(i1, i2)}
                      disabled={disabled}
                    />
                  </div>,
                ]
          );
        }
      });
      data.pushRowSpanOptionListArr(group.elements.length);
    });
  };

  // 追加ボタンハンドラ
  const handlerClickAddCell = (i1: number, i2: number) => {
    let addArrI: number = i2 + 1;
    let addArr: Buzai[] = [];
    data.data.forEach((group: any, index1: number) => {
      if (i1 === index1) {
        group.elements.forEach((buzai: any, index2: number) => {
          if (i2 === index2) {
            const targetDefaultCode = buzai.default_item_code ? i2 : null;
            data.addDefaultItemCode(
              i1,
              index2,
              targetDefaultCode !== null
                ? data.defaultBuiCodeList[i1][i2]
                : null
            );
            data.addSelectRoom(index2);
            data.sameBuzaiCountList[i1].splice(index2 + 1, 0, {
              element_code: buzai.element_code || '',
              count: 0,
            });
            addArr = lodash.cloneDeep(buzai);
          }
        });
      }
    });
    const _addArr = {
      ...addArr,
      isAddCell: true,
    };
    data.data[i1].elements.splice(addArrI, 0, lodash.cloneDeep(_addArr));
    moldingData(data.data);
    data.updateState();
  };

  // 削除ボタンハンドラ
  const handlerCLickDeleteCell = (i1: number, i2: number) => {
    data.defaultBuiCodeList[i1].splice(i2, 1);
    data.sameBuzaiCountList[i1].splice(i2, 1);
    data.data[i1].elements.splice(i2, 1);
    data.deleteSelectRoom(i2);
    moldingData(data.data);
    data.updateState();
  };

  if (type === 'first') {
    moldingData(data.data);
  } else {
    data.formingTableData();
  }
  data.updateState();
};

const SpecificationToolBodyComponent = () => {
  const dispatch = useDispatch();
  const {id} = useParams<{id: string}>();
  const {
    specificationRooms,
    specificationItem,
    specificationHouse,
    selecteria,
    roomCodeList,
  } = useSelector(
    (state: State) => ({
      specification: state.specification.houseSpecification,
      specificationRooms: state.specification.specificationRooms,
      specificationHouse: state.specification.specificationHouse,
      specificationItem: state.specification.specificationItem,
      roomCodeList: state.specification.roomCodeList,
      selecteria: state.specification.selecteria,
    }),
    lodash.isEqual
  );

  /**
   * table rowSpan対応用
   * */
  const [isList, setIsList] = useState(false);
  const [roomSelection, setRoomSelection] = useState<0 | 1 | 2>(0);
  const [note, setNote] = useState<string>('');
  const [tableListData, setTableListData] = useState<any>();
  const [rowSpanOption, setRowSpanOption] = useState<any>();
  const [data, setData] = useState<SpecificationController | null>(null);
  const [noteError, setNoteError] = useState<boolean>(false);

  useEffect(() => {
    if (specificationItem) {
      setData(
        new SpecificationController({
          data: specificationItem,
          roomCodeList,
          setTableData: setTableListData,
          setRowSpanOption,
        })
      );
    }
  }, [roomCodeList, specificationItem]);

  useEffect(() => {
    if (data && specificationRooms) {
      MakeTableListData(
        data,
        'first',
        roomSelection,
        specificationRooms,
        !!specificationHouse?.is_csv
      );
      setIsList(true);
    }
  }, [data, roomSelection, specificationRooms]);

  /* 初期値取得 */
  useEffect(() => {
    const article_code = {article_code: id};
    dispatch(
      SpecificationActions.getRoom(new ApiGetSpecificationRoom(article_code))
    );
    dispatch(
      SpecificationActions.getHouse(new ApiGetSpecificationHouse(article_code))
    );
    dispatch(
      SpecificationActions.getSelecteria(new ApiGetSpecificationSelecteria())
    );
  }, [dispatch, id]);

  useEffect(() => {
    setNote(specificationHouse?.note || '');
  }, [specificationHouse]);
  useEffect(() => {
    if (note.length > 255) {
      setNoteError(true);
    }
  }, [note]);

  /* アイテム取得 */
  useEffect(() => {
    if (!specificationRooms) {
      return;
    }
    setIsList(false);
    dispatch(
      SpecificationActions.getItem({
        api: new ApiGetSpecificationItem({
          article_code: id,
          room_code: specificationRooms[0].code,
        }),
      })
    );
  }, [dispatch, id, specificationRooms]);

  /* ページを離れるときにStoreStateリセット */
  useWillUnMount(() => {
    dispatch(SpecificationActions.setRoom(null));
    dispatch(SpecificationActions.setHouse(null));
    dispatch(SpecificationActions.setItem(null));
  });

  // 表示エリア高さ算出
  const topEle = useRef<any>();
  const noteEle = useRef<any>();
  const [topELeHeight, setTopELeHeight] = useState<number>(0);
  const [noteELeHeight, setNoteELeHeight] = useState<number>(0);
  useEffect(() => {
    setTopELeHeight(topEle.current?.getBoundingClientRect().height || 0);
    setNoteELeHeight(noteEle.current?.getBoundingClientRect().height || 0);
  }, [topEle, noteEle, noteError]);

  return (
    <S.SpecificationToolBody>
      <S.SpecificationToolBodyTop ref={topEle}>
        <SpecificationToolSelect
          roomSelection={roomSelection}
          setTableListData={setTableListData}
          setRoomSelection={setRoomSelection}
          data={data}
          note={note}
          specificationItem={specificationItem}
          selecteria={selecteria}
          specificationHouse={specificationHouse}
          specificationRooms={specificationRooms}
          btnDisabled={noteError}
        />
      </S.SpecificationToolBodyTop>

      {/* *********** ↑↑↑ top ↑↑↑ *********** ↓↓↓ bottom ↓↓↓ *********** */}

      <S.SpecificationToolBodyBottom
        style={{
          height:
            'calc(100% - ' + topELeHeight + 'px - ' + noteELeHeight + 'px)',
        }}
      >
        <Table
          header={
            roomSelection === 1
              ? ['部材グループ', '部材', '部位', '色', '設置場所', '']
              : ['部材グループ', '部材', '部位', '色', '']
          }
          lists={isList ? tableListData : []}
          rowSpanOptionArr={rowSpanOption}
          option={{
            isSingleColor: true,
            stringWidth:
              roomSelection === 1
                ? [
                    {index: 0, width: 210},
                    {index: 1, width: 210},
                    {index: 3, width: 230},
                    {index: 4, width: 230},
                    {index: 5, width: 193},
                  ]
                : [
                    {index: 0, width: 210},
                    {index: 1, width: 210},
                    {index: 3, width: 230},
                    {index: 4, width: 193},
                  ],
          }}
        />
      </S.SpecificationToolBodyBottom>

      <S.SpecificationToolNote ref={noteEle}>
        <GlobalStyleComponent.divide theme={{size: 30}} />
        <GlobalStyleComponent.FlexAlignCenter>
          <div className="col_wrap col_left align_c" id="remarks">
            備考
            <br />
            （255文字まで）
          </div>
          <div className="col_wrap col_right">
            <textarea
              aria-labelledby="remarks"
              value={note}
              onChange={(e) => {
                setNote(e.currentTarget.value);
                e.currentTarget.value.length > 255
                  ? setNoteError(true)
                  : setNoteError(false);
              }}
            />
            {noteError ? (
              <div className="error_wrap">255文字以下で入力してください。</div>
            ) : (
              <></>
            )}
          </div>
        </GlobalStyleComponent.FlexAlignCenter>
      </S.SpecificationToolNote>
    </S.SpecificationToolBody>
  );
};

const SpecificationTool = () => {
  return (
    <PageBase pageId="Component_SpecificationTool">
      <SpecificationToolBodyComponent />
    </PageBase>
  );
};

export default SpecificationTool;
