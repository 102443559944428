import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { TestState } from './test.type';
import { TestActions } from './test.actions';

const initialState: TestState = {
  testData: { message: '' },
};

export const TestReducer = reducerWithInitialState<TestState>(
  initialState
).case(TestActions.successTest, (state, payload) => {
  return {
    ...state,
    testData: payload,
  };
});
