import styled from 'styled-components';

export const MasterUploadStyle = {
  MasterUploadStyle: styled.div`
    height: 100%;
  `,
  InputDialogWrap: styled.div`
    input {
      font-size: 22px;
      height: 65px;
    }
    button {
      width: 200px;
      border-radius: 10px;
      margin: 0;
      padding: 20px 0;
      text-align: center;
      font-size: 18px;
    }
  `,
};