import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {DialogStyle as S} from '../../../styles/ui/dialog/dialog.style';
import {GlobalStyleComponent} from '../../../styles/component-global-style';
import {Button, ButtonBack, ButtonDefault} from '../button';
import {DisplayElements} from '../../../models/display-elements';
import {useSelector} from 'react-redux';
import {State} from '../../../redux/root.reducer';

export type DialogProps = {
  title?: string;
  dialogType: 'default' | 'message';
  bodyEle?: DisplayElements;
  handlerClickCloseBtn?: () => void;
  message?: string[];
  btnProps?: Array<{
    label?: string;
    action: () => void;
  }>;
  option?: {
    dialogWidth?: number;
  };
};

export const Dialog = () => {
  const dialogs = useSelector((state: State) => state.dialog.dialogs);
  return (
    <>
      {dialogs.length ? (
        <>
          <S.DialogBg />
          {dialogs.map((v, i) => {
            return (
              <S.DialogWrap key={i}>
                <S.DialogInnerWrap>
                  <DialogBody
                    title={v.title}
                    dialogType={v.dialogType}
                    bodyEle={v.bodyEle}
                    closeBtnClick={v.handlerClickCloseBtn}
                    message={v.message}
                    btnProps={v.btnProps}
                  />
                </S.DialogInnerWrap>
              </S.DialogWrap>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

type DialogBodyProps = {
  closeBtnClick?: () => void;
  option?: {
    dialogWidth?: number;
  };
};

const DialogBody = (props: DialogProps & DialogBodyProps) => {
  const {
    title,
    dialogType,
    bodyEle,
    closeBtnClick,
    message,
    btnProps,
    option,
  } = props;
  const closeBtnOption = {
    paddingSize: {
      paddingTop: 5,
      paddingRight: 5,
      paddingBottom: 5,
      paddingLeft: 5,
    },
    fontSize: 14,
    isReset: true,
  };
  const [isAnim, setIsAnim] = useState<boolean>(false);
  useEffect(() => {
    const timerAnim = setTimeout(() => {
      setIsAnim(true);
    });
    return () => {
      clearTimeout(timerAnim);
    };
  }, []);
  return (
    <S.DialogBody
      className={`${isAnim ? ' isAnim' : ''}`}
      theme={{size: option?.dialogWidth}}
    >
      <S.DialogBodyComponent>
        {dialogType === 'default' ? (
          <>
            <div className="dialogHead">
              <GlobalStyleComponent.FlexAlignCenter>
                <div>{title}</div>
                {closeBtnClick && (
                  <div>
                    <Button
                      label={<>&#x2613;</>}
                      option={closeBtnOption}
                      onClick={closeBtnClick}
                    />
                  </div>
                )}
              </GlobalStyleComponent.FlexAlignCenter>
            </div>
          </>
        ) : (
          <></>
        )}

        <div style={{display: 'flex', flexDirection: 'column', minHeight: '100px', maxHeight: '90vh'}} className="dialogInnerBody">
          {dialogType === 'message' ? (
            <>
              {message?.map((v, i) => (
                <div className="align_c" key={i}>
                  {v}
                </div>
              ))}
              {bodyEle}
              <GlobalStyleComponent.divide theme={{size: 60}} />
              <GlobalStyleComponent.FlexAlignCenterContentCenter>
                {btnProps?.map((v, i) => (
                  <div key={i} className="main_btn_wrap">
                    {v?.label !== 'キャンセル' ? (
                      <>
                        <ButtonDefault label={v?.label} onClick={v?.action} />
                      </>
                    ) : (
                      <>
                        <ButtonBack label={v?.label} onClick={v?.action} />
                      </>
                    )}
                  </div>
                ))}
              </GlobalStyleComponent.FlexAlignCenterContentCenter>
            </>
          ) : (
            <>{bodyEle}</>
          )}
        </div>
      </S.DialogBodyComponent>
    </S.DialogBody>
  );
};
