export const RoutingPath = {
  /** 開発テスト用ホーム */
  testHome: '/test-home',

  /** CADセンター利用画面 */
  // csvUpload: '/cad-center/',
  /** masterアップロード画面 */
  masterUpload: '/master-upload/',
  /** masterアップロード 結果画面 */
  masterUploadResult: '/master-upload-result/',
  /** 物件登録画面 */
  registrationItem: '/registration-item/',
  /** 物件登録 結果画面 */
  registrationItemResult: '/registration-item-result/',
  /** 部屋仕様情報登録画面 */
  registrationRoomSpecifications: '/registration-room-specifications/',
  /** 部屋仕様情報登録 結果画面 */
  registrationRoomSpecificationsResult: '/registration-room-specifications-result/',
  /** 物件一覧 */
  houseList: '/house-list',
  /** ログイン */
  login: '/login',
  /** パスワード忘れ */
  forgetPassword: '/forget-password',
  /** 担当者指定画面 */
  assignCoordinator: '/assign-coordinator',
  /** 出力PDF画面 */
  specificationPdf: '/specification/pdf',
  /** 仕様確認ツール画面 */
  specificationTool: '/specification/tool',
};
