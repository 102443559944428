import actionCreatorFactory from 'typescript-fsa';
import {ApiGetCoordinatorList} from './api-get-coordinator-list';
import {ApiAssignCoordinator} from './api-assign-coordinator';
import * as Type from './coordinator.type';

const ActionCreator = actionCreatorFactory('coordinator');

export const CoordinatorActions = {
  getCoordinator: ActionCreator<ApiAssignCoordinator>('get/coordinator'),
  getCoordinatorList: ActionCreator<ApiGetCoordinatorList>(
    'get/coordinator/list'
  ),
  setCoordinatorList: ActionCreator<Type.Coordinator[]>('set/coordinator/list'),
  resetState: ActionCreator('reset/state'),
};
