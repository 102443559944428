import { ApiBase } from '../api-base';
import { HeadersAuthJson } from '../api-headers';
import { ApiGetHouseDetailResponse } from './api-get-house-detail.response';

type Param = {
  article_code: string; //物件コード
  status: 0 | 1;
};

export class ApiPostHouseStatus extends ApiBase<ApiGetHouseDetailResponse> {
  constructor(param: Param) {
    super({
      httpMethod: 'POST',
      contents: 'JSON',
      url: `/api/article/${param.article_code}/check`,
      header: HeadersAuthJson(),
      body: { status: param.status },
      host: 'https://hinokiya-specification-dev.marietta.co.jp'
    });
  }
}
