
export const FormatBytes = ( a: number, b: number = 2 ) => {
  if ( 0 === a ) {
    return '0 Bytes';
  }
  const c = 0 > b ? 0 : b;
  const d = Math.floor(Math.log(a) / Math.log(1024));
  const value = parseFloat(( a / Math.pow(1024, d)).toFixed(c));
  const unit = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d];
  return `${value} ${unit}`;
};
