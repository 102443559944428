import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { AuthState } from '../api/auth/auth.type';
import { AuthReducer } from '../api/auth/auth.reducer';
import { HouseState } from '../api/house/house.type';
import { HouseReducer } from '../api/house/house.reducer';
import { TestState } from '../api/test/test.type';
import { TestReducer } from '../api/test/test.reducer';
import { DialogState } from './dialog/dialog.type';
import { DialogReducer } from './dialog/dialog.reducer';
import { SpecificationState } from '../api/specification/specification.type';
import { SpecificationReducer } from '../api/specification/specification.reducer';
import { CoordinatorState } from '../api/coordinator/coordinator.type';
import { CoordinatorReducer } from '../api/coordinator/coordinator.reducer';
import { UploadState } from '../api/upload-master/upload-master.type';
import { UploadReducer } from '../api/upload-master/upload-master.reducer';

export type State = {
  router: RouterState,
  upload: UploadState,
  test: TestState;
  auth: AuthState;
  house: HouseState;
  coordinator: CoordinatorState;
  dialog: DialogState;
  specification: SpecificationState;
};

export const RootReducer = (history: History) =>
  combineReducers<State>({
    router: connectRouter(history),
    upload: UploadReducer,
    test: TestReducer,
    auth: AuthReducer,
    house: HouseReducer,
    dialog: DialogReducer,
    coordinator: CoordinatorReducer,
    specification: SpecificationReducer,
  });
