import { takeEvery, put, all, putResolve } from 'redux-saga/effects';
import { ApiGetSpecificationHouse } from '../../../api/specification/api-get-specification-house';
import { SpecificationActions } from '../../../api/specification/specification.action';
import { SpecificationHouse } from '../../../api/specification/specification.type';
import { SpecificationToolActions } from './specification-tool.action';

// function* trySetData(
//   action: ReturnType<typeof SpecificationToolActions.setData>
// ) {
//   const { param, onSuccess, onError } = action.payload;
//   const article_code = { article_code: param.article_code };

//   let house: SpecificationHouse | null = null;
//   yield put(
//     SpecificationActions.getHouse(
//       new ApiGetSpecificationHouse(article_code),
//     )
//   );

//   onSuccess({ house });
// }

export function* SpecificationToolSaga() {
  // yield takeEvery(SpecificationToolActions.setData, trySetData);
}
