import actionCreatorFactory from 'typescript-fsa';
import { ApiUploadAuth } from './api-upload-auth';

const ActionCreator = actionCreatorFactory('upload/master');

export const UploadMasterActions = {
  upload: ActionCreator<{ type: string; csvs: File[]; }>('upload'),
  auth: ActionCreator<ApiUploadAuth>('auth'),
  setMasterType: ActionCreator<string>('set/master/type'),
  setIsAuth: ActionCreator<boolean>('set/is/auth'),
  getZip: ActionCreator('get/zip'),
};
