import styled from 'styled-components';
import {CommonDefine} from "../../define";

export const SelectStyle = {
  SelectWrap: styled.div`
    width: 100%;
    
    .selectBtnInner_wrap {
      width: 100%;
      
      // react-selectカスタマイズ
      .customize_reactSelect {
        >div {
          position: relative;
          font-size: 18px;
          color: #231815;
          padding: 0;
          border-radius: 0;
          width: 100%;
          cursor: pointer;
          white-space: nowrap;

          >div {
            min-height: 1px;
            border: 1px solid #231815;
            border-radius: 0;

            >div {
              &:last-of-type {
                >span {
                  background: none;
                }
                >div {
                  svg {
                    opacity: 0;
                  }
                }
              }
            }
          }
          .css-26l3qy-menu {
            max-width: none;
            width: auto;
            min-width: 100%;
            overflow: visible;
          }
        }
      }//.customize_reactSelect
    }
  `,
  SelectBtn: styled.select`
    position: relative;
    font-size: ${CommonDefine.mainFontSize}px;
    color: ${CommonDefine.mainFontColor};
    padding: 0 ${CommonDefine.selectArrow + 5}px 0 5px;
    height: 40px;
    border: 1px solid ${CommonDefine.borderColor};
    border-radius: 0;
    background: none;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    outline: none;

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    white-space: nowrap;
    
    &:focus {
      ${CommonDefine.focusLayout}
    }
  `,

  Arrow: styled.div`
    ${CommonDefine.flex_box};
    ${CommonDefine.flex_align_center};
    ${CommonDefine.flex_content_center};
    position: absolute;
    top: 0;
    right: 0;
    width: ${CommonDefine.selectArrow}px;
    height: 100%;
    color: ${CommonDefine.borderColor};
    z-index: 0;
    line-height: 1;
    pointer-events: none;
  `,

  ErrorWrap: styled.div`
    word-break: keep-all;
    white-space: nowrap;
    max-width: none;
    width: auto!important;
    font-size: ${CommonDefine.errorFontSize}px;
    color: ${CommonDefine.errorColor};
    position: absolute;
    left: 0;
    bottom: 50px;
    border: 1px solid ${CommonDefine.errorColor};
    border-radius: 4px;
    padding: 8px;
    line-height: 1;
    box-shadow:3px 3px 3px -1px #aaaaaa;
    background-color: #ffffff;
    z-index: 1;
    
    &:after,
    &:before {
      border: solid transparent;
      content:'';
      height:0;
      width:0;
      pointer-events:none;
      position:absolute;
      top:100%;
      left:7%;
    }
    &:after {
      border-color: rgba(255,255,255,0);
      border-top-width: 7px;
      border-bottom-width: 5px;
      border-left-width: 6px;
      border-right-width: 6px;
      margin-left: 2px;
      border-top-color: #FFFFFF;
      margin-top: -1px;
    }
    &:before {
      border-color: rgba(255, 17, 17, 0);
      border-top-width:6px;
      border-bottom-width:6px;
      border-left-width:6px;
      border-right-width:6px;
      margin-left: 2px;
      margin-top: 1px;
      border-top-color:${CommonDefine.errorColor};
    }
  `,
};
SelectStyle.SelectWrap.defaultProps = {
};