import styled from 'styled-components';
import {CommonDefine} from "../../define";

const inputWidth = 420;

export const LoginStyle = {
  LoginBody: styled.div`
    ${CommonDefine.flex_box};
    // ${CommonDefine.flex_align_center};
    ${CommonDefine.flex_content_center};
    
    height: 100%;
    padding: 80px 20px 20px;
  `,

  LoginBodyInner: styled.div`
    width: 100%;
    max-width: 1000px;
    
    .img_wrap {
      border-bottom: 1px solid ${CommonDefine.baseBgColor};
      
      img {
        margin: 0 auto;
        
        &:first-of-type {
          width: 173px;
        }
        &:last-of-type {
          width: 350px;
        }
      }
    }
  `,

  LoginFormInner: styled.div`
    width: calc(100% - 40px);
    max-width: ${inputWidth}px;
    margin: 0 auto;
    
    .input_wrap {
      .inputLabel {
        position: absolute;
        right: ${inputWidth + 30}px;
        top: 50%;
        transform: translateY(-50%);
        width: 9rem;
      }
      input {
        font-size: 22px;
        height: 65px;
      }
      .min_input {
        width: 8em;
      }
      .passError {
        position: absolute;
        bottom: -28px;
        word-break: keep-all;
        color: ${CommonDefine.errorColor};
      }
    }
    label {
      &.checkBoxLabel {
        ${CommonDefine.flex_box};
        ${CommonDefine.flex_align_center};
        cursor: pointer;
        
        input {
          // display: none;だとfocusが死ぬ
          filter: alpha(opacity=0);
          -moz-opacity:0;
          opacity:0;
          -webkit-appearance: none;
          appearance: none;
          position: absolute;
          top: -2000px;
          left: -2000px;
        }
        .img_wrap {
          border: 0;
          
         
          img {
            &:not(.isChecked) {
              width: 25px;
              height: 25px;
            }
            &.isChecked {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        
        &.onFocus {
          img {
            ${CommonDefine.focusLayout}
          }
        }
      }
    }
    .error_wrap {
      font-size: 15px;
      color: ${CommonDefine.errorColor};
    }
  `,

  // パスワード忘れ画面
  ForgetPasswordBody: styled.div`
    ${CommonDefine.flex_box};
    // ${CommonDefine.flex_align_center};
    ${CommonDefine.flex_content_center};
    
    height: 100%;
    padding: 80px 20px 20px;

    &#Component_ForgetPassword {
      .input_wrap {
        &.largeInput {
          font-size: 30px;
        }
        .inputLabel {
          width: 15rem;
        }
      }
    }
  `,
};
LoginStyle.LoginBody.defaultProps = {};