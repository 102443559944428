import { ApiBase } from '../api-base';
import { HeadersAuthJson } from '../api-headers';
import { ApiGetHouseListResponse } from './api-get-house-list.response';

type Param = { name: string };

export class ApiGetHouseList extends ApiBase<ApiGetHouseListResponse> {
  constructor(param: Param) {
    super({
      httpMethod: 'GET',
      contents: 'JSON',
      url: '/api/article',
      param,
      header: HeadersAuthJson(),
      host: 'https://hinokiya-specification-dev.marietta.co.jp'
    });
  }
}
