import React from 'react';
import {Button, ButtonProps} from './button';

export const ButtonChange = (props: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const {
    disabled, label, src, selected, option,
  } = props;
  return (
    <>
      <Button
        {...props}
        disabled={disabled}
        label={label}
        src={src}
        bgColor='btnBg_change'
        selected={selected}
        option={option}
      />
    </>
  );
};