import React from 'react';
import { ButtonStyle as S } from '../../../styles/ui/button/button.style';
import { DisplayElements } from '../../../models/display-elements';

export type ButtonProps = {
  disabled?: boolean,
  /** ボタン内のテキスト */
  label?: DisplayElements,
  /** ボタン内の画像 (URL) */
  src?: string,
  /** 選択状態 */
  selected?: boolean,
  /** ボタン背景色 */
  bgColor?: BtnBgColorList,
  option?: {
    /** padding */
    paddingSize?: {
      paddingTop?: number,
      paddingRight?: number,
      paddingBottom?: number,
      paddingLeft?: number,
    },
    /** style reset */
    isReset?: boolean,
    /** font size */
    fontSize?: number,
    /** font weight */
    fontWeight?: string,
  },
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export type BtnBgColorList =
  'btnBg_def'
  |'btnBg_delete'
  |'btnBg_change'
  |'btnBg_back'
  |'btnBg_close';

export const Button = (props: ButtonProps) => {
  const {
    disabled, label, src, selected, option, bgColor,
  } = props;
  return (
    <S.ButtonWrap
      className={`
        button_wrap
        ${bgColor ? bgColor : Button.defaultProps.bgColor}
        `
      }
    >
      <S.Button
        {...props}
        disabled={disabled}
        theme={{
          paddingTop: option?.paddingSize?.paddingTop || 10,
          paddingRight: option?.paddingSize?.paddingRight || 20,
          paddingBottom: option?.paddingSize?.paddingBottom || 10,
          paddingLeft: option?.paddingSize?.paddingLeft || 20,
          fontSize: option?.fontSize || 14,
        }}
        className={
          `
          ${option?.isReset ? ' isReset' : ''}
          ${selected ? ' isSelected' : ''}
          ${option?.fontWeight ? (
            option.fontWeight === 'bold' ? ' font_b'
              : option.fontWeight === 'normal' ? ' font_n'
              : ''
          ) : ''}
          `
        }
      >
        {src ? (
          <img alt={typeof label === 'string' ? label : 'button'} src={src} />
        ) : (
          <>{label}</>
        )}
      </S.Button>
    </S.ButtonWrap>
  );
};

Button.defaultProps = {
  label: '',
  src: '',
  bgColor: 'btnBg_def',
};