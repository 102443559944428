// import {ApiLogin} from '../../api/auth/api-login';
import actionCreatorFactory from 'typescript-fsa';
import {HouseDetail} from '../../../api/house/house.type';
import { ApiCoordinatorCheckResponse } from '../../../api/coordinator/api-coodinator-check.response';

const ActionCreator = actionCreatorFactory('assign/coordinator');

type CallbackSetData = {
  article_code: string;
  onSuccess: (houseDetail: HouseDetail | null) => void;
};

export const AssignCoordinatorActions = {
  setData: ActionCreator<CallbackSetData>('set/data'),
  assignCoordinator: ActionCreator<{
    coordinator_code: string | undefined;
    article_code: string | undefined;
  }>('assign/coordinator'),
  checkCoordinator: ActionCreator <{
    article_code: string;
    onSuccess?: (res: ApiCoordinatorCheckResponse[]) => void,
    onError?: () => void,
  }>('check/coordinator'),
};
