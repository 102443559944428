import React, {useCallback, useEffect, useRef, useState} from 'react';
import {GlobalStyleComponent} from '../../../styles/component-global-style';
import {RegistrationItemStyle as S} from '../../../styles/pages/registration-item/registration-item.style';
import {PageBase} from '../../ui/page-base/page-base';
import {ButtonDefault} from '../../ui/button';
import {Table} from '../../ui/table/table';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';
import {RoutingPath} from '../../../routes/routing-path';
import {useLocation, Redirect} from 'react-router-dom';
import { UploadResult } from '../../../api/upload-master/upload-master.type';

const MasterUploadBodyComponent = (props: {
  results: UploadResult[];
}) => {
   const dispatch = useDispatch();
  // state
  const [btnAreaHeight, setBtnAreaHeight] = useState<number>(0);
  // ref
  const btnEle = useRef<any>();
  // effect
  useEffect(() => {
    setBtnAreaHeight(btnEle.current?.getBoundingClientRect().height || 0);
  }, []);
  const handleCsv = useCallback(() => {
    dispatch(push(RoutingPath.masterUpload));
  }, [dispatch]);
  const uploadBtnOption = {
    fontSize: 27,
    paddingSize: {
      paddingTop: 30,
      paddingRight: 40,
      paddingBottom: 30,
      paddingLeft: 40,
    },
  };
  return (
    <S.RoomSpecificationsBody>
      <div
        style={{height: 'calc(100% - ' + btnAreaHeight + 'px)'}}
        className="tableArea"
      >
        <Table
          header={['ファイル名', '結果']}
          lists={props.results.map((v) => [v.filename, v.res])}
        />
      </div>

      <div ref={btnEle}>
        <GlobalStyleComponent.divide theme={{size: 40}} />
        <GlobalStyleComponent.FlexAlignCenterContentCenter>
          <ButtonDefault
            label="CSVアップロード"
            option={uploadBtnOption}
            onClick={handleCsv}
          />
        </GlobalStyleComponent.FlexAlignCenterContentCenter>
        <GlobalStyleComponent.divide theme={{size: 20}} />
      </div>
    </S.RoomSpecificationsBody>
  );
};

export const MasterUploadResult = () => {
  const pageOption = {headerRightHide: true};
  const location = useLocation<{results: UploadResult[]}>()

  return location.state ? (
    <>
      <PageBase
        title="マスタ一覧"
        option={pageOption}
        pageId="Component_MasterUploadResult"
      >
        <MasterUploadBodyComponent {...location.state} />
      </PageBase>
    </>
  ) : (
    <Redirect to={RoutingPath.masterUpload} />
  );
};
