// import {ApiLogin} from '../../api/auth/api-login';
import actionCreatorFactory from 'typescript-fsa';
import { DialogProps } from '../../components/ui/dialog/dialog';

const ActionCreator = actionCreatorFactory('dialog');

export const DialogActions = {
  push: ActionCreator<DialogProps>('push'),
  pushMessage: ActionCreator<{
    message: string[];
    noClear?: boolean;
    label?: string;
    bodyEle?: JSX.Element;
    onClick?: () => void;
  }>('push/message'),
  pop: ActionCreator('pop'),
  clear: ActionCreator('clear'),
  isLoading: ActionCreator<boolean>('is/loading'),
  setIsLoading: ActionCreator<boolean>('set/is/loading'),
};
