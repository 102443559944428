import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { UploadState } from './upload-master.type';
import { UploadMasterActions } from './upload-master.action';

const initialState: UploadState = {
  masterType: 'house',
  isAuth: false
};

export const UploadReducer = reducerWithInitialState<UploadState>(
  initialState
)
  .case(UploadMasterActions.setMasterType, (state, payload) => ({
    ...state,
    masterType: payload,
  }))
  .case(UploadMasterActions.setIsAuth, (state, payload) => ({
    ...state,
    isAuth: payload,
  }));
