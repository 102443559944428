import React, {useEffect, useState} from 'react';
import {useCallback} from 'react';
// @ts-ignore
import {Validation} from '../../../models/validation';
import {GlobalStyleComponent} from "../../../styles/component-global-style";
import {InputStyle as S} from '../../../styles/ui/input/input.style';

export type InputTextProps = {
  validationList?: Validation[],
  touch?: boolean,
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
  option?: {
    disabled?: boolean,
    errorPosBottom?: boolean,
    alignRight?: boolean,
    alignCenter?: boolean,
    isEllipsis?: boolean,
    inputWidth?: string,
    isNoPointerEvents?: boolean,
  },
} & React.InputHTMLAttributes<HTMLInputElement>;

export const InputText = (props: InputTextProps) => {
  const {
    validationList, touch, disabled, onBlur,onChange, value,
    option,
  } = props;
  const {...defaultInputProps} = props;
  const [changed, setChanged] = React.useState<boolean>(false);
  const [errorMessageList, setErrorMessageList] = useState<string[]>([]);
  const [inputFocusState, setInputFocusState] = useState<boolean>(false);
  const [inputHoverState, setInputHoverState] = useState<boolean>(false);
  const error = Boolean((touch || changed) && errorMessageList.length);
  const handlerOnChange = useCallback((v: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    if (onChange) {
      onChange(v);
    }
  }, [changed, onChange]);
  const handlerOnBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    setInputFocusState(false);
    if (onBlur) {
      onBlur(e);
    }
  }, [inputFocusState, onBlur]);
  useEffect(
    () => {
      setTimeout(() => {
        validationList ? setErrorMessageList(
          validationList
            .filter((v) => !v.test(value ? String(value) : ''))
            .map((v) => v.errorMessage),
        ) : setErrorMessageList([])
      });
    },
    [value],
  );
  return (
    <>
      <S.InputWrap theme={{width: option?.inputWidth}}>
        <div
          className={
            `
            error_wrap
            ${option?.errorPosBottom ? (' posBottom') : ('')}
            `
          }
        >
          {error ? (
            <>
              {inputFocusState || inputHoverState ? (
                <S.InputErrorWrap>
                  {errorMessageList.map((v, i) => (
                    <div
                      key={i}
                    >
                      {i !== 0 ? (<><GlobalStyleComponent.divide theme={{size: 5}}/></>) : (<></>)}
                      {v}
                    </div>
                  ))}
                </S.InputErrorWrap>
              ) : (<></>)}
            </>
          ) : (<></>)}
        </div>
        <S.InputText
          {...defaultInputProps}
          className={
            `
              ${error ? (' InputTextError') : ('')}
              ${!error && disabled ? (' disabled') : ('')}
              ${option?.alignRight ? (' align_r') : ('')}
              ${option?.alignCenter ? (' align_c') : ('')}
              ${option?.isEllipsis ? (' isEllipsis') : ('')}
              ${option?.isNoPointerEvents ? (' isNoPointerEvents') : ('')}
            `
          }
          onChange={handlerOnChange}
          onFocus={(e: any) => {
            setInputFocusState(true);
          }}
          onBlur={handlerOnBlur}
          onMouseEnter={() => setInputHoverState(true)}
          onMouseLeave={() => setInputHoverState(false)}
        />
      </S.InputWrap>
    </>
  );
};