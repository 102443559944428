import React, {useEffect, useState} from 'react';
import './App.scss';
import {Route, Redirect, Switch, useLocation} from 'react-router-dom';
import {RoutingPath} from './routes/routing-path';
import Login from './components/pages/login/login';
import AssignUser from './components/pages/assign-coordinator/assign-coordinator';
import SpecificationPdf from './components/pages/specification-pdf/specification-pdf';
import SpecificationTool from './components/pages/specification-tool/specification-tool';
import GlobalStyle from './styles/global-style';
import {Dialog} from './components/ui/dialog/dialog';
import {Loading} from './components/ui/loading/loading';
import HouseList from './components/pages/house-list/house-list';
import {ForgetPassword} from './components/pages/house-list/forget-password';
import MasterUpload from './components/pages/master/master-upload';
import {MasterUploadResult} from './components/pages/master/master-upload-result';

const App = () => {
  const [isTools, setIsTools] = useState<boolean>(false);
  const loc = useLocation();
  useEffect(() => {
    if (loc.pathname.match('specification/tool') !== null) {
      setIsTools(true);
    } else {
      setIsTools(false);
    }
  }, [loc]);
  return (
    <div className={isTools ? ('App isTools') : ('App')}>
      <GlobalStyle />
      <Switch>
        {/* 開発テスト用 */}
        {/* <Route exact path={RoutingPath.testHome} component={TestHome} /> */}

        {/*<Route exact path={RoutingPath.csvUpload} component={CsvUpload} />*/}
        <Route
          exact
          path={RoutingPath.masterUpload}
          component={MasterUpload}
        />
        <Route
          exact
          path={RoutingPath.masterUploadResult}
          component={MasterUploadResult}
        />
        {/* <Route
          exact
          path={RoutingPath.registrationItem}
          component={RegistrationItem}
        /> */}
        {/* <Route
          exact
          path={RoutingPath.registrationItemResult}
          component={RegistrationItemResult}
        /> */}
        {/* <Route
          exact
          path={RoutingPath.registrationRoomSpecifications}
          component={RegistrationRoomSpecifications}
        /> */}
        {/* <Route
          exact
          path={RoutingPath.registrationRoomSpecificationsResult}
          component={RegistrationRoomSpecificationsResult}
        /> */}
        <Route exact path={RoutingPath.houseList} component={HouseList} />
        <Route exact path={RoutingPath.login} component={Login} />
        <Route
          exact
          path={RoutingPath.forgetPassword}
          component={ForgetPassword}
        />
        <Route
          exact
          path={`${RoutingPath.assignCoordinator}/:id`}
          component={AssignUser}
        />
        <Route
          exact
          path={`${RoutingPath.specificationPdf}/:id`}
          component={SpecificationPdf}
        />
        <Route
          exact
          path={`${RoutingPath.specificationTool}/:id`}
          component={SpecificationTool}
        />
        {/* <Route path={'/pdf-viewer'} onEnter={() => window.location.reload()} /> */}
        <Route path="/" render={() => <Redirect to={RoutingPath.login} />} />
      </Switch>

      <Dialog
      />
      <Loading />
    </div>
  );
};

export default App;
