import styled from 'styled-components';
import {CommonDefine} from "../../define";

export const DialogStyle = {
  DialogWrap: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    height: auto!important;
  `,

  DialogInnerWrap: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none;
    z-index: 1;
    
    ${CommonDefine.flex_box}
    ${CommonDefine.flex_align_center}
    ${CommonDefine.flex_content_center}
  `,

  DialogBg: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    z-index: 999;
  `,

  DialogBody: styled.div`
    position: absolute;
    background-color: #E9E9E9;
    top: 50%;
    left: 50%;
    box-shadow:2px 2px 4px 0 #999999;
    width: ${({theme}) => theme.size}px;
    min-width: 400px;
    max-width: 95%;
    max-height: 95%;
    transform: translate(-50%, -50%) scale(0.3, 0.3);
    opacity: 0.7;
    z-index: 1000;
    
    &.isAnim {
      transform: translate(-50%, -50%) scale(1, 1);
      opacity: 1;
      transition-delay: 0.05s;
      transition-duration: 0.15s;
      transition-timing-function: ease-in-out;
    }
  `,

  DialogBodyComponent: styled.div`
    .dialogHead {
      height: 50px;
      padding: 0 5px 0 20px;
      background-color: ${CommonDefine.baseBgColor};
      color: #ffffff;
      
      >div {
        height: 100%;
        
        >div {
          &:first-of-type {
            flex: 1;
            text-align: left;
          }
          &:last-of-type {
            button {
              color: #ffffff;
              line-height: 1;
              font-weight: 800;
              font-size: 19px;
            }
          }
        }
      }
    }//.dialogHead
    
    .dialogInnerBody {
      background-color: #ffffff;
      padding: 60px 160px 40px;
      
      .main_btn_wrap {
        margin: 0 10px;
        
        button {
          //font-size: 27px;
          //padding: 20px 30px 20px 30px;
          //margin: 0 auto;
          font-size: 20px;
          padding: 15px 0 15px 0;
          margin: 0 auto;
          height: 65px;
          width: 150px;
        }
      }
    }//.dialogInnerBody
  `,
};
DialogStyle.DialogBody.defaultProps = {
  theme: {size: 500}
};