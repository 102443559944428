import React, {useState} from 'react';
import { DisplayElements } from "../../../models/display-elements";
import { TableStyle as S } from "../../../styles/ui/table/table.style";

type TableThProps = {
  thList: DisplayElements[],
  thOption?: {
    stringWidth?: {
      index: number,
      width: number,
    }[],
    ellipsisLine?: number[],
  },
  sort?: {
    index: number[] | undefined,
    onClickAction: () => void,
    sortOrder: 'Descending' | 'Ascending',
  },
};

export const TableTh = (props: TableThProps) => {
  const {
    thList, thOption, sort,
  } = props;
  return (
    <>
      {
        thList.map((data, i) => (
          <S.ThWrap
            key={i}
            style={
              thOption?.stringWidth?.find((v) => i === v.index) ? {
                maxWidth: `${thOption?.stringWidth?.find((v) => v.index === i)?.width}px`,
                minWidth: `${thOption?.stringWidth?.find((v) => v.index === i)?.width}px`,
                width: `${thOption?.stringWidth?.find((v) => v.index === i)?.width}px`,
              } : {}
            }
            className={
              `
              ${thOption?.ellipsisLine ? thOption.ellipsisLine.map((v) => (v === i ? ' isEllipsis' : '')) : ('')}
              ${sort?.index ? sort.index.map((v) => v === i ? (' isSortable') : ('')) : ('')}
              ${sort?.sortOrder === 'Descending' ? ' isDescending' : ' isAscending'}
              `
            }
            onClick={sort?.onClickAction}
          >
            {data}

            {/* ↓↓↓ stickyの弊害対策 ↓↓↓ */}
            <div className="topBorder" />
            {i !== 0 ? (
              <><div className="sideBorder" /></>
            ) : (<></>)}
            {/* ↑↑↑ stickyの弊害対策 ↑↑↑ */}
          </S.ThWrap>
        ))
      }
    </>
  );
};
