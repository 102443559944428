import React from 'react';
import {useState} from 'react';
import {useEffect} from 'react';
import {Validation} from '../../../models/validation';
import {SelectStyle as S} from '../../../styles/ui/select/select.style';
import Select from 'react-select';

export type SelectOptionType = {
  value: string;
  // label: string;
  label: any;
  imgUrl?: string;
};

export type SelectProps = {
  touch?: boolean;
  /** selectボタンの選択肢のリスト */
  optionList: SelectOptionType[];
  /** 選択値 */
  value: string;
  /** change ハンドラ */
  onChange?: (v: string) => void;
  /** ディセーブル状態判定フラグ */
  disabled?: boolean;
  /** 値が必須かどうか */
  allowUnselected?: boolean;
  /** バリデーション */
  validationList?: Validation[];
  option?: {
    /** エラーメッセージの表示位置を下に表示する場合 */
    errorPosBottom?: boolean;
    /** selectの左右中央揃えの指定 */
    selectAlign?: string;
  };
  title?: string;
  isColor?: boolean;
};

export const SearchSelect = (props: SelectProps) => {
  const {
    optionList,
    value,
    onChange,
    disabled,
    validationList,
    touch,
    allowUnselected,
    option,
    title,
    isColor,
  } = props;
  const [changed, setChanged] = React.useState<boolean>(false);
  const [errorMessageList, setErrorMessageList] = useState<string[]>([]);
  const [inputFocusState, setInputFocusState] = useState<boolean>(false);
  const [inputHoverState, setInputHoverState] = useState<boolean>(false);
  const error = Boolean((touch || changed) && errorMessageList.length);
  useEffect(() => {
    setTimeout(() => {
      if (validationList?.length) {
        setErrorMessageList(
          validationList
            .filter((v) => !v.test(value ? String(value) : ''))
            .map((v) => v.errorMessage)
        );
      }
    });
  }, [value, validationList]);

  const formatOptionLabel = ({ value, label, imgUrl }: any) => {
    return (
      <div className="selectImg_wrap">
        {value && isColor && (
          <div className="innerImg">
            <img src={ imgUrl || ''} />
          </div>
        )}
        <div className="innerLabel">{label}</div>
      </div>
    );
  };

  return (
    <>
      <S.SelectWrap>
        <div
          className={`selectBtnInner_wrap position_r display_inb
            ${disabled ? ' disabled' : ''}
            ${error ? ' InputTextError' : ''}
            ${option?.selectAlign ? ` selectAlign_${option?.selectAlign}` : ''}
            `}
        >
          <div
            className={`error_wrap${
              option?.errorPosBottom ? ' posBottom' : ''
            }`}
          >
            {error ? (
              <>
                {inputFocusState || inputHoverState ? (
                  errorMessageList.map((v, i) => (
                    <S.ErrorWrap key={i}>{v}</S.ErrorWrap>
                  ))
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>

          <div title={title} className="customize_reactSelect">
            <Select
              // components={isColor ? {ValueContainer} : undefined}
              value={optionList.filter((option) => option.value === value)}
              formatOptionLabel={formatOptionLabel}
              options={
                allowUnselected
                  ? [...optionList].map((v) => {
                      return {
                        value: v.value,
                        label: v.label,
                        title: v.label,
                        imgUrl: v.imgUrl,
                      };
                    })
                  : [
                      {value: '', label: '　', title: ''},
                      ...optionList.map((v) => {
                        return {
                          value: v.value,
                          label: v.label,
                          title: v.label,
                          imgUrl: v.imgUrl,
                        };
                      }),
                    ]
              }
              onChange={(v) => {
                setChanged(true);
                if (onChange) {
                  onChange(v?.value || '');
                }
              }}
              onFocus={() => setInputFocusState(true)}
              onBlur={() => setInputFocusState(false)}
              onMouseEnter={() => setInputHoverState(true)}
              onMouseLeave={() => setInputHoverState(false)}
              placeholder={''}
              noOptionsMessage={() => ''}
            >
              {(allowUnselected
                ? [...optionList]
                : [{value: '', label: ''}, ...optionList]
              ).map((data, i) => (
                <option
                  key={`selct-option_${data.label}-${data.value}_${i}`}
                  value={data.value}
                >
                  {data.label}
                </option>
              ))}
            </Select>
          </div>
          <S.Arrow>&#9660;</S.Arrow>
        </div>
      </S.SelectWrap>
    </>
  );
};

SearchSelect.defaultProps = {
  onChange: () => {},
};
