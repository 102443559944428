import {ApiBase} from '../api-base';
import {HeadersAuthJson} from '../api-headers';
import {ApiAssignCoordinatorResponse} from './api-assign-coordinator.response';

type Param = {
  coordinator_code: string;
  article_code: string;
};

export class ApiAssignCoordinator extends ApiBase<
  ApiAssignCoordinatorResponse
> {
  constructor(param: Param) {
    const {coordinator_code, article_code} = param;
    const coordinator = {user_code: coordinator_code};
    super({
      httpMethod: 'POST',
      contents: 'JSON',
      url: `/api/article/${article_code}/coordinator`,
      header: HeadersAuthJson(),
      body: coordinator,
      host: 'https://hinokiya-specification-dev.marietta.co.jp',
    });
  }
}
