import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useDidMount} from '../../../hooks/life-cycle';
import {SpecificationActions} from '../../../api/specification/specification.action';
import {ApiGetSpecificationPDF} from '../../../api/specification/api-get-specification-PDF';
import {useParams, useLocation} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import imgDownload from '../../../assets/images/download.svg';
import {GlobalStyleComponent} from '../../../styles/component-global-style';

const SpecificationPdf = () => {
  const dispatch = useDispatch();
  const param = useParams<{id: string}>();
  const [pdf, setPdf] = useState('');
  const [fileName, setFileName] = useState('');
  const [title, setTitle] = useState('PDF');
  const [isShow, setIsShow] = useState(false);

  useDidMount(() => {
    dispatch(
      SpecificationActions.getPdf({
        api: new ApiGetSpecificationPDF({article_code: param.id}),
        onSuccess: (file, fileName) => {
          setPdf(file);
          setFileName(fileName);
          setTitle(fileName);
          setIsShow(true);
        },
      })
    );
  });

  const handleClickDownload = useCallback(() => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.download = fileName;
    a.href = pdf;
    a.click();
    a.remove();
    URL.revokeObjectURL(pdf);
  }, [fileName, pdf]);

  return (
    <>
      {/* タイトル書き換え */}
      <Helmet title={title} />
      <object data={pdf} width="100%" height="100%" title={'サンプルPDF'} />
      {isShow ? (
        <div className="pdfDlBtn_wrap">
          <button onClick={handleClickDownload}>
            <img src={imgDownload} alt="download" />
            <GlobalStyleComponent.spacer theme={{size: 10}} />
            PDFダウンロード
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SpecificationPdf;
