import styled from 'styled-components';
import {CommonDefine} from "../../define";

export const LoadingStyle = {
  LoadingWrap: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: auto;
    
    >div {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1001;
      opacity: 0;
    
      &.isAnim {
        opacity: 1;
        transition:  all 0.5s;
      }
    }
  `,

  LoadingBody: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 1003;
    ${CommonDefine.flex_box};
    ${CommonDefine.flex_align_center};
    ${CommonDefine.flex_content_center};
    
    background-color: rgba(0,0,0,0.6);
    
    // animation
    @keyframes rotate-loading {
        0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
        100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
    }
    @-moz-keyframes rotate-loading {
        0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
        100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
    }
    @-webkit-keyframes rotate-loading {
        0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
        100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
    }
    @-o-keyframes rotate-loading {
        0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
        100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
    }
    @keyframes rotate-loading {
        0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
        100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
    }
    @-moz-keyframes rotate-loading {
        0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
        100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
    }
    @-webkit-keyframes rotate-loading {
        0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
        100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
    }
    @-o-keyframes rotate-loading {
        0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
        100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
    }
    .loading-container,
    .loading {
        height: 200px;
        position: relative;
        width: 200px;
        border-radius: 100%;
    }
    .loading {
        border: 12px solid transparent;
        border-color: transparent #fff transparent #FFF;
        -moz-animation: rotate-loading 1.5s linear 0s infinite normal;
        -moz-transform-origin: 50% 50%;
        -o-animation: rotate-loading 1.5s linear 0s infinite normal;
        -o-transform-origin: 50% 50%;
        -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
        -webkit-transform-origin: 50% 50%;
        animation: rotate-loading 1.5s linear 0s infinite normal;
        transform-origin: 50% 50%;
    }
    .loading-container:hover .loading,
    .loading-container .loading {
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }
    #loading-text {
      color: #ffffff;
      font-size: 21px;
      font-weight: bold;
      margin-top: 85px;
      letter-spacing: 3px;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 0;
      width: 200px;
        
      >span {
        display: inline-block;
        margin: 0 -.075em;
        animation: loading .7s infinite alternate;
        
        :nth-child(2) {
          animation-delay: .1s;
        }
        :nth-child(3) {
          animation-delay: .2s;
        }
        :nth-child(4) {
          animation-delay: .3s;
        }
        :nth-child(5) {
          animation-delay: .4s;
        }
        :nth-child(6) {
          animation-delay: .5s;
        }
        :nth-child(7) {
          animation-delay: .6s;
        }
        @keyframes loading {
          0% {
            transform: scale(1);
          }
          100% {
            transform: scale(0.8);
          }
        }
      }
    }
    // animation end
  `,
};
LoadingStyle.LoadingWrap.defaultProps = {
};