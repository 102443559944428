import styled from 'styled-components';
import {CommonDefine} from '../../define';

export const CsvUploadStyle = {
  CsvUploadWrap: styled.div`
    height: 100%;
    
    .uploadTypeSelect_wrap {
      width: 230px;
    }
  `,
  DragArea: styled.div`
    position: relative;
    padding: 80px 20px 50px;
    text-align: center;
    border: 0.5px solid ${CommonDefine.borderColor};
    width: 900px;
    max-width: 100%;
    margin: 0 auto;
    
    img {
      &.marg_auto {
        width: 65px;
        height: auto;
      }
    }
    .inputFile_wrap {
      width: 200px;
    }
    
    &.isDragActive {
      border: 0.5px solid ${CommonDefine.baseBgColor};
      //background-color: rgba(222,93,16,0.2);
      ${CommonDefine.animationSlow}

      // animation
      background: linear-gradient(
              -45deg,
              rgba(222,93,16,0.4),
              rgba(222,93,16,0.2),
              rgba(222,93,16,0.1),
              rgba(222,93,16,0)
      );
      background-size: 400% 400%;
      animation: gradient 2s ease infinite;
      @keyframes gradient {
        0% {
          background-position: 0 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0 50%;
        }
      }
    }
  `,
  SelectedList: styled.div`
    border: 0.5px solid ${CommonDefine.baseBgColor};
    background-color: rgba(222,93,16,0.2);
    width: 900px;
    max-width: 100%;
    margin: 0 auto;
    padding: 20px 30px;
    
    .list_wrap {
      >div {
        margin-right: 20px;
        margin-bottom: 5px;
        
        &:after {
          content: ',';
          display: inline-block;
        }
      }
      button {
        &.delete_btn {
          background-color: ${CommonDefine.baseBgColor};
          color: #ffffff;
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          border-radius: 10px;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  `,
  ExtensionsError: styled.div`
    width: 900px;
    max-width: 100%;
    margin: 0 auto;
    color: ${CommonDefine.errorColor};
  `,
};

