import { HouseData } from '../../../api/house/house.type';

export class HouseListModel {
  constructor(
    public houseList: HouseData[]
  ) { }

  changeStatus(code: string) {
    const data = this.houseList.find((v) => v.code === code);
    if (data) {
      data.status = data?.status ? 0 : 1;
    }
  }

  getStatus(code: string) {
    return this.houseList.find((v) => v.code === code)?.status ?? false;
  }
}