import styled from 'styled-components';
import {GlobalStyleComponent} from '../../component-global-style';
import {CommonDefine} from '../../define';

export const InputStyle = {
  InputWrap: styled.div`
    position: relative;
    width: ${({theme}) => theme.width};
    
    .error_wrap {
      &.posBottom {
        >div {
          top: 45px;
          bottom: auto;
          
          &:after,
          &:before {
            border: solid transparent;
            content:'';
            height:0;
            width:0;
            pointer-events:none;
            position:absolute;
            bottom: calc(100% - 1px);
            left:7%;
            margin-top: 0;
            top: auto;
          }
          
          &:after {
            border-color: rgba(255, 255, 255, 0);
            border-top-width:7px;
            border-bottom-width:5px;
            border-left-width:6px;
            border-right-width:6px;
            margin-left: 2px;
            border-bottom-color:#FFFFFF;
          }
          &:before{
            border-color: rgba(229, 76, 58, 0);
            border-top-width:6px;
            border-bottom-width:5px;
            border-left-width:6px;
            border-right-width:6px;
            margin-left: 2px;
            margin-bottom: 1px;
            border-bottom-color:#E54C3A;
          }
        }
      }
    }
  `,

  InputText: styled.input`
    max-width: 100%;
    width: 100%;
    font-size: ${CommonDefine.mainFontSize}px;
    color: ${CommonDefine.mainFontColor};
    padding: 0 5px;
    height: 40px;
    border: 1px solid ${CommonDefine.inputBorderColor};
    
    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
    &[type="number"] { 
      -moz-appearance:textfield; 
    }
    &.InputTextError {
      border: 1px solid ${GlobalStyleComponent.dangerColor};
      color: ${GlobalStyleComponent.dangerColor};
    }
    &.disabled {
      opacity: 1!important;
      background-color: #ffffff;
      border: 1px solid #dddddd;
      color: #aaaaaa;
    }
    &.isEllipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.isNoPointerEvents {
      pointer-events: none!important;
    }
    &:focus {
      ${CommonDefine.focusLayout}
    }
  `,

  InputErrorWrap: styled.div`
    word-break: keep-all;
    white-space: nowrap;
    max-width: none!important;
    width: auto!important;
    font-size: ${CommonDefine.errorFontSize}px;
    font-weight: normal;
    color: ${CommonDefine.errorColor};
    position: absolute;
    left: 0;
    bottom: 50px;
    border: 1px solid ${CommonDefine.errorColor};
    border-radius: 4px;
    padding: 8px;
    line-height: 1;
    box-shadow:3px 3px 3px -1px #aaaaaa;
    background-color: #ffffff;
    z-index: 1;
    
    &:after,
    &:before {
      border: solid transparent;
      content:'';
      height:0;
      width:0;
      pointer-events:none;
      position:absolute;
      top:100%;
      left:7%;
    }
    &:after {
      border-color: rgba(255,255,255,0);
      border-top-width: 7px;
      border-bottom-width: 5px;
      border-left-width: 6px;
      border-right-width: 6px;
      margin-left: 2px;
      border-top-color: #FFFFFF;
      margin-top: -1px;
    }
    &:before {
      border-color: rgba(255, 17, 17, 0);
      border-top-width:6px;
      border-bottom-width:6px;
      border-left-width:6px;
      border-right-width:6px;
      margin-left: 2px;
      margin-top: 1px;
      border-top-color:${CommonDefine.errorColor};
    }
  `,

  typeFileInputWrap: styled.div`
    label {
      input {
        // display: none;だとfocusが死ぬ
        filter: alpha(opacity=0);
        -moz-opacity:0;
        opacity:0;
        -webkit-appearance: none;
        appearance: none;
        position: absolute;
        top: -2000px;
        left: -2000px;
      }
      
      display: block;
      cursor: pointer;
      color: #ffffff;
      border-radius: 10px;
      margin: 0;
      padding: 20px 0;
      text-align: center;
      background-color: ${CommonDefine.baseBgColor};
      border: 1px solid ${CommonDefine.baseBgColor};
      max-width: 100%;
      
      &:hover {
        color: ${CommonDefine.baseBgColor};
        ${CommonDefine.animationSlow};
        background-color: #ffffff!important;
      }
      &.onFocus {
        ${CommonDefine.focusLayout}
      }
    }
  `,
};
InputStyle.InputWrap.defaultProps = {
  theme: {width: '100%'}
};
InputStyle.InputText.defaultProps = {};