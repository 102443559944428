import { put, takeEvery } from 'redux-saga/effects';
import { SystemActions } from './system.action';
import { DialogActions } from '../dialog/dialog.action';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../routes/routing-path';

function* tryNetworkError() {
  yield put(push(RoutingPath.login))
  yield put(DialogActions.pushMessage({message:['ネットワークエラーです']}));
}

function* tryTokenError(action: ReturnType<typeof SystemActions.tokenError>) {
  yield put(push(RoutingPath.login))
  yield put(DialogActions.pushMessage({message:[action.payload]}));
}

export function* SystemSaga() {
  yield takeEvery(SystemActions.networkError, tryNetworkError);
  yield takeEvery(SystemActions.tokenError, tryTokenError);
}

