import React, {useCallback, useState} from 'react';
import CsvUpload from '../csv-upload/csv-upload';
import {PageBase} from '../../ui/page-base/page-base';
import {MasterUploadStyle as S} from '../../../styles/pages/master/master-upload.style';
import {useDidMount, useWillUnMount} from '../../../hooks/life-cycle';
import {DialogActions} from '../../../redux/dialog/dialog.action';
import {useDispatch, useSelector} from 'react-redux';
import {GlobalStyleComponent} from '../../../styles/component-global-style';
import {ButtonDefault} from '../../ui/button';
import {InputText} from '../../ui/input/input-text';
import {OnlyHalfPointedStrings} from '../../../utilities/only-half-pointed-strings';
import {UploadMasterActions} from '../../../api/upload-master/upload-master.action';
import {State} from '../../../redux/root.reducer';
import {ApiUploadAuth} from '../../../api/upload-master/api-upload-auth';
import { AuthActions } from '../../../api/auth/auth.action';
import { ApiCsvDownloadAuth } from '../../../api/auth/api-csv-download-auth';
import { Config } from '../../../config/config';

export const DialogBodyEle = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState<string>('');
  const [pass, setPass] = useState<string>('');
  const completeInputDialog = useCallback(() => {
    dispatch(
      UploadMasterActions.auth(
        new ApiUploadAuth({
          user: id,
          password: pass,
        })
      )
    );
  }, [dispatch, id, pass]);
  return (
    <S.InputDialogWrap>
      IDとパスワードを入力してください。
      <GlobalStyleComponent.divide theme={{size: 30}} />
      <InputText
        placeholder="ID"
        value={id}
        onChange={(e) => setId(OnlyHalfPointedStrings(e.currentTarget.value))}
        list={'debugList'}
      />
      <GlobalStyleComponent.divide theme={{size: 30}} />
      <InputText
        type="password"
        placeholder="パスワード"
        value={pass}
        onChange={(e) => setPass(e.currentTarget.value)}
      />
      <GlobalStyleComponent.divide theme={{size: 60}} />
      <GlobalStyleComponent.FlexAlignCenterContentCenter>
        <ButtonDefault label="OK" onClick={completeInputDialog} />
      </GlobalStyleComponent.FlexAlignCenterContentCenter>
    </S.InputDialogWrap>
  );
};


export const DialogBodyEle2 = () => {
  const dispatch = useDispatch();
  const [pass, setPass] = useState<string>('');
  const completeInputDialog = useCallback(() => {
    dispatch(
      AuthActions.csvDownloadAuth(
        new ApiCsvDownloadAuth({
          password: pass,
        })
      )
    );
  }, [dispatch, pass]);
  return (
    <S.InputDialogWrap>
      パスワードを入力してください。
      <GlobalStyleComponent.divide theme={{size: 30}} />
      <InputText
        type="password"
        value={pass}
        onChange={(e) => setPass(e.currentTarget.value)}
      />
      <GlobalStyleComponent.divide theme={{size: 60}} />
      <GlobalStyleComponent.FlexAlignCenterContentCenter>
        <ButtonDefault label="OK" onClick={completeInputDialog} />
      </GlobalStyleComponent.FlexAlignCenterContentCenter>
    </S.InputDialogWrap>
  );
};

const MasterUpload = () => {
  const isAuth = useSelector((state: State) => state.upload.isAuth);
  const pageOption = {headerRightHide: true};
  const dispatch = useDispatch();
  useDidMount(() => {
    if (Config.mode === 'prod') return;
    
    if (!isAuth) {
      dispatch(
        DialogActions.push({
          dialogType: 'default',
          title: '認証',
          bodyEle: <DialogBodyEle />,
        })
      );
    }
  });
  useWillUnMount(() => {
    dispatch(DialogActions.clear());
  });
  return isAuth || Config.mode === 'prod' ? (
    <S.MasterUploadStyle>
      <PageBase
        title="マスタアップロード"
        option={pageOption}
        pageId="Component_MasterUpload"
      >
        <CsvUpload />
      </PageBase>
    </S.MasterUploadStyle>
  ) : (
    <></>
  );
};

export default MasterUpload;
