import { delay, put, takeEvery } from 'redux-saga/effects';
import { Store } from '../store';
import { DialogActions } from './dialog.action';

function* tryLoading(action: ReturnType<typeof DialogActions.isLoading>) {
  const isLoading = action.payload;
  if (!isLoading) {
    yield delay(500);
    yield put(DialogActions.setIsLoading(false));
  } else {
    yield put(DialogActions.setIsLoading(true));
  }
}

function* tryPushMessage(action: ReturnType<typeof DialogActions.pushMessage>) {
  if (!action.payload.noClear) {
    yield put(DialogActions.pop());
  }
  yield put(
    DialogActions.push({
      title: '',
      dialogType: 'message',
      message: action.payload.message,
      bodyEle: action.payload.bodyEle,
      btnProps: [
        {
          label: action.payload.label || 'OK',
          action: () => {
            if (action.payload.onClick) {
              action.payload.onClick();
            }
            Store.dispatch(DialogActions.pop());
          },
        },
      ],
    })
  );
}

export function* DialogSaga() {
  yield takeEvery(DialogActions.isLoading, tryLoading);
  yield takeEvery(DialogActions.pushMessage, tryPushMessage);
}
