import { SaveParam } from '../../components/pages/specification-tool/specification-controller';
import { ApiBase } from '../api-base';
import { HeadersAuthJson } from '../api-headers';
import { ApiSaveSpecificationResponse } from './api-save-specification.response';

type Param = {
  article_code: string;
  room_code: string;
  specification: {
    note: string;
    items: SaveParam;
    selecteria?: string;
  };
};

export class ApiSaveSpecification extends ApiBase<ApiSaveSpecificationResponse> {
  constructor(param: Param) {
    const { article_code, room_code, specification } = param;
    super({
      httpMethod: 'POST',
      contents: 'JSON',
      url: `/api/article/${article_code}/room/${room_code}`,
      param: {},
      header: HeadersAuthJson(),
      body: specification,
      host: 'https://hinokiya-specification-dev.marietta.co.jp'
    });
  }
}
