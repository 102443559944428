import { put, takeEvery } from 'redux-saga/effects';
import { AuthActions } from './auth.action';
import { RoutingPath } from '../../routes/routing-path';
import { push } from 'connected-react-router';
import { DialogActions } from '../../redux/dialog/dialog.action';
import { ApiBase } from '../api-base';
import { ApiLoginResponse } from './api-login.response';
import { ResType } from '../api.type';
import { ApiLogoutResponse } from './api-logout.response';
import { ApiCheckToken } from './api-check-token';
import { ApiCheckTokenResponse } from './api-check-token.response';
import { SystemActions } from '../../redux/system/system.action';
import { ApiLogout } from './api-logout';
import { Store } from '../../redux/store';
import { UploadMasterActions } from '../upload-master/upload-master.action';

function* tryLogin(action: ReturnType<typeof AuthActions.login>) {
  yield put(DialogActions.isLoading(true));
  try {
    const result: ResType<ApiLoginResponse> = yield action.payload.api.run();
    if (ApiBase.isSuccess(result)) {
      yield put(AuthActions.setToken(action.payload.api.token));
      yield put(AuthActions.setLoginUserData(result.data));
      yield put(AuthActions.setRole(result.data.user.role));
      yield put(push(RoutingPath.houseList));
    } else {
      action.payload.onError(result.errors);
    }
  } catch (e) {
    yield put(SystemActions.networkError());
  } finally {
    yield put(DialogActions.isLoading(false));
  }
}

function* tryLogout() {
  const apiLogout = new ApiLogout();

  try {
    const result: ResType<ApiLogoutResponse> = yield apiLogout.run();
    if (ApiBase.isSuccess(result)) {
      yield put(
        DialogActions.pushMessage({
          message: ['ログアウトしました'],
          // label:'ログイン画面に戻る',
          onClick: () => {
            Store.dispatch(push(RoutingPath.login));
          },
        })
      );
    } else if (ApiBase.isTokenError(result)) {
      yield put(SystemActions.tokenError(result.errors));
    } else {
      yield put(DialogActions.pushMessage({ message: [result.errors] }));
    }
  } catch (e) {
    yield put(SystemActions.networkError());
  }
}

function* tryCheckToken(action: ReturnType<typeof AuthActions.checkToken>) {
  const apiCheckToken = new ApiCheckToken();
  const { onSuccess, onError } = action.payload;
  try {
    const result: ResType<ApiCheckTokenResponse> = yield apiCheckToken.run();
      localStorage.removeItem('token');
    yield put(AuthActions.setToken(apiCheckToken.token));
    if (ApiBase.isSuccess(result)) {
      onSuccess();
    } else {
      onError(result.errors);
    }
  } catch (e) {
    yield put(SystemActions.networkError());
  }
}

function* tryChangePassword(
  action: ReturnType<typeof AuthActions.changePassword>
) {
  try {
    const result: ResType<ApiCheckTokenResponse> = yield action.payload.run();
    if (ApiBase.isSuccess(result)) {
      yield put(
        DialogActions.push({
          title: '',
          dialogType: 'message',
          message: ['パスワード再設定完了'],
          btnProps: [
            {
              label: 'OK',
              action: () => {
                Store.dispatch(DialogActions.pop());
                Store.dispatch(push(RoutingPath.login));
              },
            },
          ],
        })
      );
    } else {
      yield put(DialogActions.pushMessage({ message: [result.errors] }));
    }
  } catch (e) {
    yield put(SystemActions.networkError());
  }
}

function* tryCsvDownloadAuth(
  action: ReturnType<typeof AuthActions.csvDownloadAuth>
) {
  try {
    const result: ResType<{}> = yield action.payload.run();
    if (ApiBase.isSuccess(result)) {
      yield put(DialogActions.pop());
      yield put(UploadMasterActions.getZip());
    } else {
      yield put(DialogActions.pushMessage({ message: [result.errors] }));
    }
  } catch (e) {
    yield put(SystemActions.networkError());
  }
}

export function* AuthSaga() {
  yield takeEvery(AuthActions.login, tryLogin);
  yield takeEvery(AuthActions.logout, tryLogout);
  yield takeEvery(AuthActions.checkToken, tryCheckToken);
  yield takeEvery(AuthActions.changePassword, tryChangePassword);
  yield takeEvery(AuthActions.csvDownloadAuth, tryCsvDownloadAuth);
}
