import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';
import { AuthState } from './auth.type';
import { AuthActions } from './auth.action';

const initialState: AuthState = {
  loginUser: null,
  jwtToken: '',
  role: 0,
};

export const AuthReducer = reducerWithInitialState<AuthState>(initialState)
  .case(AuthActions.setLoginUserData, (state, payload) => ({
    ...state,
    loginUser: lodash.cloneDeep(payload),
  }))
  .case(AuthActions.setToken, (state, payload) => {
    const token = payload || state.jwtToken;
    return {
      ...state,
      jwtToken: token,
    };
  })
  .case(AuthActions.setRole, (state, payload) => (
    {
      ...state,
      role: payload,
    }
  ))
  .case(AuthActions.resetState, () => initialState)
  .default((state) => state);
