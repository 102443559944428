import styled from 'styled-components';
import { CommonDefine } from '../../define';

export const HouseListStyle = {
  HouseListBody: styled.div`
    height: 100%;
  `,
  HouseListRefineWrap: styled.div``,
  HouseListTableWrap: styled.div`
    position: relative;
    
    table {
      td,th {
        font-size: 16px;
      }
    }
  `,
  HouseListType3BtnWrap: styled.div``,
  InputStatusBtn: styled.div`
    text-align: center;
    ${CommonDefine.flex_box};
    ${CommonDefine.flex_align_center};
    cursor: pointer;
        div {
          margin: auto;
          ${CommonDefine.flex_box};
          ${CommonDefine.flex_align_center};
        }
        input {
          // display: none;だとfocusが死ぬ

          filter: alpha(opacity=0);
          -moz-opacity:0;
          opacity:0;
          -webkit-appearance: none;
          appearance: none;
          position: absolute;
          top: -2000px;
          left: -2000px;
        }
        .img_wrap {
          border: 0;
          img {
            &:not(.isChecked) {
              width: 25px;
              height: 25px;
            }
            &.isChecked {
              width: 25px;
              height: 25px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        
        &.onFocus {
          img {
            ${CommonDefine.focusLayout}
          }
        }
  `
};
HouseListStyle.HouseListBody.defaultProps = {};