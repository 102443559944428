import { ApiBase } from '../api-base';
import { HeadersAuthJson } from '../api-headers';
import { ApiGetRoomCodeListResponse } from './api-get-room-code-list.response';

export type Param = {
  article_code: string;
  room_code: string;
};

export class ApiGetRoomCodeList extends ApiBase<ApiGetRoomCodeListResponse> {
  constructor(param: Param) {
    super({
      httpMethod: 'GET',
      contents: 'JSON',
      url: `/api/article/${param.article_code}/room/${param.room_code}/selection`,
      param: {},
      header: HeadersAuthJson(),
      host: 'https://hinokiya-specification-dev.marietta.co.jp'
    });
  }
}
