import {put, takeEvery} from 'redux-saga/effects';
import {TestActions} from './test.actions';
import * as Type from './test.type';
import { ResType } from '../api.type';
import { RequestBase } from '../../models/request-base';

function* trySetTest(action: ReturnType<typeof TestActions.setTest>) {
  const testData: Type.TestData = {message: action.payload};
  yield put(TestActions.successTest(testData));
}

function* tryTestApi(action: ReturnType<typeof TestActions.testApi>) {
  const result: ResType<{name: string; age: number}> = yield action.payload.api.run();
  const request: RequestBase = new RequestBase({
    result,
    onSuccess: () => {
    },
    onError: () => {
    },
  });
  request.run();
}

export function* TestSaga() {
  yield takeEvery(TestActions.setTest, trySetTest);
  yield takeEvery(TestActions.testApi, tryTestApi);
}

