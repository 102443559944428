import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Validation } from "../../../models/validation";
import { SelectStyle as S } from "../../../styles/ui/select/select.style";

export type SelectOptionType = {
  value: string,
  label: string,
};

export type SelectProps = {
  touch?: boolean,
  /** selectボタンの選択肢のリスト */
  optionList: SelectOptionType[],
  /** 選択値 */
  value: string,
  /** change ハンドラ */
  onChange?: (v: string) => void,
  /** ディセーブル状態判定フラグ */
  disabled?: boolean,
  /** 値が必須かどうか */
  allowUnselected?: boolean,
  /** バリデーション */
  validationList?: Validation[],
  option?: {
    /** エラーメッセージの表示位置を下に表示する場合 */
    errorPosBottom?: boolean,
    /** selectの左右中央揃えの指定 */
    selectAlign?: string,
  },
  title?: string;
};

export const Select = (props: SelectProps) => {
  const {
    optionList,
    value,
    onChange,
    disabled,
    validationList,
    touch,
    allowUnselected,
    option,
    title,
  } = props;
  const [changed, setChanged] = React.useState<boolean>(false);
  const [errorMessageList, setErrorMessageList] = useState<string[]>([]);
  const [inputFocusState, setInputFocusState] = useState<boolean>(false);
  const [inputHoverState, setInputHoverState] = useState<boolean>(false);
  const error = Boolean((touch || changed) && errorMessageList.length);
  useEffect(
    () => {
      setTimeout(() => {
        if (validationList?.length) {
          setErrorMessageList(
            validationList
              .filter((v) => !v.test(value ? String(value) : ''))
              .map((v) => v.errorMessage),
          );
        }
      });
    },
    [value, validationList],
  );
  return (
    <>
      <S.SelectWrap>
        <div
          title={title}
          className={
            `selectBtnInner_wrap position_r display_inb
            ${disabled ? ' disabled' : ('')}
            ${error ? ' InputTextError' : ('')}
            ${option?.selectAlign ? ` selectAlign_${option?.selectAlign}` : ('')}
            `
          }
        >
          <div
            className={`error_wrap${option?.errorPosBottom ? (' posBottom') : ('')}`}
          >
            {error ? (
              <>
                {inputFocusState || inputHoverState ? (
                  errorMessageList.map((v, i) => (
                    <S.ErrorWrap
                      key={i}
                    >
                      {v}
                    </S.ErrorWrap>
                  ))
                ) : (<></>)}
              </>
            ) : (<></>)}
          </div>

          <S.SelectBtn
            value={value}
            onChange={(v) => {
              setChanged(true);
              if (onChange) {
                onChange((v.target.value));
              }
            }}
            onFocus={() => setInputFocusState(true)}
            onBlur={() => setInputFocusState(false)}
            onMouseEnter={() => setInputHoverState(true)}
            onMouseLeave={() => setInputHoverState(false)}
          >
            {(allowUnselected ? [...optionList] : [{ value: '', label: '' }, ...optionList]).map((data, i) => (
              <option
                key={`selct-option_${data.label}-${data.value}_${i}`}
                value={data.value}
              >
                {data.label}
              </option>
            ))}
          </S.SelectBtn>
          <S.Arrow>
            &#9660;
          </S.Arrow>
        </div>
      </S.SelectWrap>
    </>
  );
};

Select.defaultProps = {
  onChange: () => {
  },
};