import actionCreatorFactory from 'typescript-fsa';
import {ApiHttpBinSample} from './api-httpbin.sample';
import * as Type from './test.type';

const ActionCreator = actionCreatorFactory('test');

export const TestActions = {
  setTest: ActionCreator<string>('setTest'),
  successTest: ActionCreator<Type.TestData>('successTest'),
  testApi: ActionCreator<{api: ApiHttpBinSample}>('testApi'),
};
