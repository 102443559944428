import {ApiBase} from '../api-base';
import {HeadersAuthJson} from '../api-headers';
import {ApiGetSpecificationItemResponse} from './api-get-specification-item.response';

type Param = {
  query?: {
    selecteria?: string;
    floor?: string;
    floor_thema?: string;
  };
  article_code: string;
  room_code: string;
};

export class ApiGetSpecificationItem extends ApiBase<
  ApiGetSpecificationItemResponse
> {
  constructor(param: Param) {
    super({
      httpMethod: 'GET',
      contents: 'JSON',
      url: `/api/article/${param.article_code}/room/${param.room_code}`,
      param: param.query,
      header: HeadersAuthJson(),
      host: 'https://hinokiya-specification-dev.marietta.co.jp',
    });
  }
}
