import styled from 'styled-components';

export const RegistrationItemStyle = {
  RegistrationItemWrap: styled.div`
    height: 100%;
  `,
  RoomSpecificationsBody: styled.div`
    height: 100%;
    
    .tableArea {
      overflow-y: auto;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
    }
  `,
};

