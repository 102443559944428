import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';
import { HouseState } from './house.type';
import { HouseActions } from './house.action';

const initialState: HouseState = {
  houseList: [],
  houseDetail: null,
  sort: null
};

export const HouseReducer = reducerWithInitialState<HouseState>(initialState)
  .case(HouseActions.setHouseList, (state, payload) => ({
    ...state,
    houseList: lodash.cloneDeep(payload),
  }))
  .case(HouseActions.setHouseDetail, (state, payload) => ({
    ...state,
    houseDetail: lodash.cloneDeep(payload),
  }))
  .case(HouseActions.setSort, (state, payload) => ({
    ...state,
    sort: lodash.cloneDeep(payload),
  }))
  .case(HouseActions.resetState, () => initialState)
  .default((state) => state);