import React, {useEffect, useState} from 'react';
import { TableStyle as S } from "../../../styles/ui/table/table.style";
import {TableTh} from "./table-th";
import {TableTd} from "./table-td";
import {DisplayElements} from "../../../models/display-elements";
import { useCallback } from "react";

type TableProps = {
  /** ヘッダー部分の表示要素 */
  header: DisplayElements[],
  /** 各項目部分の表示要素 */
  lists: DisplayElements[][],
  /** 物件idリスト */
  idList?: string[],
  /** セルをclick可能かどうか */
  clickable?: boolean,
  /** click時のハンドラ */
  onClickRow?: (v: any) => void,
  option?: {
    /** tableの横幅指定 */
    tableWidth?: string,
    /** ellipsis */
    ellipsisLine?: number[],
    /** 列の横幅指定 */
    stringWidth?: {
      index: number,
      width: number,
    }[],
    /** tdの文字列の表示位置 */
    tdAlign?: {
      index: number,
      align: string,
    }[],
    /** セルの色の統一 */
    isSingleColor?: boolean,
  },
  /** rowSpan */
  rowSpanOptionArr?: {
    line: number,
    item: number,
    length: number,
  }[],
  /** 選択したセルのindex */
  selectedTr?: number,
  /** ソート */
  sort?: {
    index: number[],
    onClick: (order:'asc' | 'desc') => void,
  },
};

export const Table = (props: TableProps) => {
  const {
    header, lists, clickable, option, onClickRow, idList, rowSpanOptionArr, selectedTr, sort,
  } = props;
  const handlerClickTr = useCallback((i) => {
    if (onClickRow) {
      onClickRow(idList?.[i]);
    }
  }, [idList, onClickRow]);
  // option
  const thOptions = {
    stringWidth: option?.stringWidth,
    ellipsisLine: option?.ellipsisLine,
  };
  const tdOptions = {
    tdAlign: option?.tdAlign,
    ellipsisLine: option?.ellipsisLine,
  };
  // state

  /**
   * sort
   * */
  const [tableLists, setTableLists] = useState<DisplayElements[][]>(lists);
  const [sortOrder, setSortOrder] = useState<'Descending' | 'Ascending'>('Descending');// Descending:降順, Ascending:昇順
  useEffect(() => {
    setTableLists(lists);
  }, [lists]);
  const sortClickAction = useCallback(() => {
    sortOrder === 'Descending' ? setSortOrder('Ascending') : setSortOrder('Descending');
    if(sort?.onClick)sort.onClick(sortOrder === 'Descending' ?'desc':'asc')
  }, [sort, sortOrder]);
  const sortOption = {
    index: sort?.index,
    onClickAction: sortClickAction,
    sortOrder: sortOrder,
  };
  return (
    <>
      <S.TableWrap theme={option?.tableWidth}>
        <S.Table>

          <S.TheadWrap>
            <S.TrWrap>
              <TableTh
                thList={header}
                thOption={thOptions}
                sort={sortOption}
              />
            </S.TrWrap>
          </S.TheadWrap>

          <S.TbodyWrap>
            {
              tableLists?.map((list, i) => (
                <S.TrWrap
                  key={i}
                  className={`
                    ${clickable ? 'isSelectable' : ''}
                    ${selectedTr === i ? 'isSelected' : ''}
                    ${option?.isSingleColor ? ' isSingleColor' : ''}
                  `}
                  onClick={() => handlerClickTr(i)}
                >
                  <TableTd
                    tdList={list}
                    selectable={!!clickable}
                    tdOption={tdOptions}
                    rowSpanOption={rowSpanOptionArr?.find(obj => obj.line === i)}
                  />
                </S.TrWrap>
              ))
            }
          </S.TbodyWrap>

        </S.Table>
      </S.TableWrap>
    </>
  );
};