import { ApiBase } from '../api-base';
import { HeadersAuthJson } from '../api-headers';
import { ApiGetFinishTableResponse } from './api-get-finish-table-csv.response';

export type Param = {
  id: string,
};

export class ApiGetFinishTableCsv extends ApiBase<ApiGetFinishTableResponse> {
  id: string;
  constructor(param: Param) {
    super({
      httpMethod: 'GET',
      contents: 'BLOB',
      url: `/api/file/finish-table/download/${param.id}`,
      param: {},
      header: HeadersAuthJson(),
      host: 'https://hinokiya-specification-dev.marietta.co.jp'
    });
    this.id = param.id;
  }
}
