import styled from 'styled-components';
import {CommonDefine} from '../../define';

const itemHeight = 40;

export const SpecificationToolStyle = {
  SpecificationToolBody: styled.div`
    //max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    
    .error_wrap {
      color: ${CommonDefine.errorColor};
    }
  `,

  SpecificationToolBodyTop: styled.div`
    .specificationToolSelectInner {
      flex: 1;
      ${CommonDefine.flex_box};
      
      >.colInnerChild_wrap {
        &:first-of-type {
          flex-basis: 390px;
          width: 390px;
        }
        &:last-of-type {
          flex: 1;
          max-width: 600px;
        }
      }
    }
    .colInnerChild_wrap {
      &:not(:first-of-type) {
        margin-left: 30px;
      }
      &.child_1 {
        .item_wrap {
          .itemBtn {
            width: 75px;
            flex-basis: 75px;
            
            button {
              width: 100%;
            }
          }
          .itemInput {
            width: calc(100% - 175px);

            > div {
              width: 100%;
            }
          }
        }
      }
      &.child_2 {
        >div >div {
          &:nth-of-type(3) {
            flex: 1;
          }
          .itemInput {
            &:not(.labelInput) {
              flex: 1;
              
              >div {
                width: 100%;
                
                select {
                  width: 100%;
                }
              }
            }
          }
        }
      }
      &.right_wrap {
        //flex: 1;
        flex-basis: 215px;
        width: 215px;
        ${CommonDefine.flex_box};
        ${CommonDefine.flex_content_end};
        
        >div {
          width: 100%;

          >div {
            width: 100%;

            button {
              width: 100%;
            }
            >div {
              width: 100%;

              button {
                width: 100%;
              }
            }
          }
        }
      }
      .item_wrap {
        ${CommonDefine.flex_box};

        &:not(:first-of-type) {
          margin-top: 10px;
        }
        .itemLabel {
          font-size: 14px;
          line-height: ${itemHeight - 2}px;
          text-align: center;
          background-color: #a48b78;
          color: #ffffff;

          &.minSize {
            font-size: 12px;
          }
        }
        .itemInput {
          //&:not(.labelInput) {
          //  width: calc(100% - 175px);
          //
          //  >div {
          //    width: 100%;
          //  }
          //}
          input,select {
            height: ${itemHeight}px;
            border: 1px solid ${CommonDefine.inputBorderColor2};
            font-size: 14px;
          }
          &.labelInput {
            input {
              color: ${CommonDefine.mainFontColor};
            }
          }
        }
        .itemBtn {
          margin-left: 10px;
          
          button {
            height: ${itemHeight}px;
            line-height: ${itemHeight - 2}px;
            padding-top: 0;
            padding-bottom: 0;
          }
        }
        
        &.disabled {
          opacity: 0.1;
          pointer-events: none;
        }
      }
      .floorThemeLabel {
        font-size: 14px;
        line-height: ${itemHeight * 3 + 20 - 2}px;
        text-align: center;
        background-color: #a48b78;
        color: #ffffff;
      }
      .outputLabel {
        font-size: 12px;
        line-height: 1;
        
        // 非表示対応
        opacity: 0;
        visibility: hidden;
        cursor: default;
        pointer-events: none;
        
        >div {
          height: ${itemHeight}px;
        }
        .innerNum {
          font-size: 20px;
          margin-left: 10px;
        }
      }
    }
    .separateEle {
      border-bottom: 1px dotted ${CommonDefine.baseBgColor};
    }
  `,

  SpecificationToolBodyBottom: styled.div`
    //max-width: 1200px;
    margin: 0 auto;
    
    table {
      td {
        &:last-of-type {
          text-align: center;
        }
        .cellBtn_wrap {
          &:not(.isAddCell) {
            >div:not(:first-of-type) {
              //opacity: 0;
              display: none;
            }
          }
          &.isOver {
            >div:first-of-type {
              opacity: 0;
              pointer-events: none;
              cursor: default;
              
              button {
                pointer-events: none;
                cursor: default;
              }
            }
          }
        }
        font-size: 15px;
        select,
        input,
        .css-1uccc91-singleValue {
          font-size: 14px;
        }
      }
    }
  `,
  SpecificationToolNote: styled.div`
    //>div {
    //  max-width: 1200px;
    //  margin: 0 auto;
    //}
    .col_wrap {
      &.col_left {
        flex-basis: 150px;
        width: 150px;
      }
      &.col_right {
        flex: 1;
        
        textarea {
          width: 100%;
          min-height: 100px;
          resize: none;
          padding: 10px;
          border-radius: 0;
          border: 1px solid ${CommonDefine.tableBorderColor};
          
          &:focus {
            ${CommonDefine.focusLayout}
          }
        }
      }
    }
  `,
};
SpecificationToolStyle.SpecificationToolBody.defaultProps = {};