import { ApiBase } from '../api-base';
import { HeadersDefaultJson } from '../api-headers';
import { ApiUploadMasterResponse } from './api-upload-master.response';

type Param = {
  user: string; password: string;
};

export class ApiUploadAuth extends ApiBase<ApiUploadMasterResponse> {
  constructor(param: Param) {
    super({
      httpMethod: 'POST',
      contents: 'JSON',
      url: '/api/file/auth',
      header: HeadersDefaultJson(),
      body: param,
      host: 'https://hinokiya-specification-dev.marietta.co.jp',
    });
  }
}
