import React from 'react';
import { PageBase } from '../../ui/page-base/page-base';
import { HouseListBodyComponent } from './house-list-body';

const HouseList = () => {
  return (
    <PageBase title="物件一覧" pageId="HouseList">
      <HouseListBodyComponent />
    </PageBase>
  );
};

export default HouseList;
