import styled from 'styled-components';
import {CommonDefine} from "../../define";

export const AssignCoordinatorStyle = {
  AssignCoordinatorBody: styled.div`
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    
    .cont_bottom {
      .define_wrap {
        ${CommonDefine.flex_box};
        
        >div {
          ${CommonDefine.flex_box};
          ${CommonDefine.flex_align_center};
          ${CommonDefine.flex_content_center};
          flex: 1;
          margin: 0 15px;
          
          &:first-of-type {
            // ${CommonDefine.flex_content_end}
          }
          >div {
            &:nth-of-type(2) {
              margin-left: 20px;
            }
            &:nth-of-type(3) {
              margin-left: 10px;
            }
          }
        }
      }
    }
  `,

  SelectItemTableBody: styled.div`
    .tableWidthAdjust {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      height: 100%;
    }
  `,
  InputStatusBtn: styled.div`
  text-align: center;
  ${CommonDefine.flex_box};
  ${CommonDefine.flex_align_center};
      div {
        margin: auto;
        ${CommonDefine.flex_box};
        ${CommonDefine.flex_align_center};
      }
      input {
        // display: none;だとfocusが死ぬ

        filter: alpha(opacity=0);
        -moz-opacity:0;
        opacity:0;
        -webkit-appearance: none;
        appearance: none;
        position: absolute;
        top: -2000px;
        left: -2000px;
      }
      .img_wrap {
        border: 0;
        img {
          &:not(.isChecked) {
            width: 25px;
            height: 25px;
          }
          &.isChecked {
            width: 25px;
            height: 25px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      
      &.onFocus {
        img {
          ${CommonDefine.focusLayout}
        }
      }
`
};
AssignCoordinatorStyle.AssignCoordinatorBody.defaultProps = {};