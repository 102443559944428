import React, {useCallback, useEffect, useRef, useState} from 'react';
import {RoutingPath} from '../../../routes/routing-path';
import {HouseListStyle as S} from '../../../styles/pages/house-list/houst-list.style';
import {GlobalStyleComponent} from '../../../styles/component-global-style';
import {ButtonChange, ButtonDefault} from '../../ui/button';
import {InputText} from '../../ui/input/input-text';
import {Table} from '../../ui/table/table';
import {useDispatch, useSelector} from 'react-redux';
import {State} from '../../../redux/root.reducer';
import * as lodash from 'lodash';
import {HouseActions} from '../../../api/house/house.action';
import {push} from 'connected-react-router';
import {ApiGetHouseList} from '../../../api/house/api-get-house-list';
import {useDidMount} from '../../../hooks/life-cycle';
import img_checkBoxNoCheck from '../../../assets/images/checkbox_noCheck.svg';
import img_checkBoxChecked from '../../../assets/images/checkbox_checked.svg';
import {HouseListModel} from './housel-list.model';
import {ApiPostHouseStatus} from '../../../api/house/api-post-house-status';

export const HouseListBodyComponent = () => {
  const dispatch = useDispatch();
  const {houseList, roleType, sort} = useSelector(
    (state: State) => ({
      houseList: state.house.houseList,
      roleType: state.auth.role,
      sort: state.house.sort,
    }),
    lodash.isEqual
  );
  /**
   * state
   * */
  const [houseListModel, setHouseListModel] = useState<HouseListModel | null>(
    null
  );
  const [defineHeight, setDefineHeight] = useState<number>(0);
  const [searchHouseName, setSearchHouseName] = useState<string | null>(
    sort?.name || ''
  );
  const [btnAreaHeight, setBtnAreaHeight] = useState<number>(0);
  /**
   * 表示内容の分岐
   * type1: 全表示, type2: コーディネーター向け, type3: ベトナム向け
   * */
  // const [type, setType] = useState<'type1' | 'type2' | 'type3'>('type1');
  const [type, setType] = useState<number>(roleType);
  /**
   * 絞り込みエリアの高さ算出
   * */
  const defineEle = useRef<any>();
  const btnEle = useRef<any>();
  useEffect(() => {
    setDefineHeight(defineEle.current?.getBoundingClientRect().height || 0);
    setBtnAreaHeight(btnEle.current?.getBoundingClientRect().height || 0);
  }, []);
  useDidMount(() => {
    setType(roleType);
    dispatch(
      HouseActions.getHouseList(
        new ApiGetHouseList({name: searchHouseName || ''})
      )
    );
  });

  // handler
  const handleClickSearchName = useCallback(() => {
    dispatch(
      HouseActions.getHouseList(
        new ApiGetHouseList({name: searchHouseName || ''})
      )
    );
    dispatch(HouseActions.setSort({name: searchHouseName}));
  }, [dispatch, searchHouseName]);
  const handleClickAssignUser = useCallback(
    (houseId: string) => {
      dispatch(push(`${RoutingPath.assignCoordinator}/${houseId}`));
    },
    [dispatch]
  );
  const handleClickList = useCallback(
    (houseId: number) => {
      dispatch(push(`${RoutingPath.specificationTool}/${houseId}`));

      // dispatch(

      //   HouseActions.getHouseSpecification({
      //     api: new APIGetSpecificationDataDummy({house_id: houseId}),
      //   })
      // );
    },
    [dispatch]
  );

  const handleCsv = useCallback(() => {
    dispatch(push(RoutingPath.registrationItem));
    // dispatch(

    //   HouseActions.getHouseSpecification({
    //     api: new APIGetSpecificationDataDummy({house_id: houseId}),
    //   })
    // );
  }, [dispatch]);

  useEffect(() => {
    setHouseListModel(new HouseListModel(lodash.cloneDeep(houseList)));
  }, [houseList]);

  // option(props)
  const defineBtnOption = {fontSize: 14};
  const uploadBtnOption = {
    fontSize: 27,
    paddingSize: {
      paddingTop: 30,
      paddingRight: 40,
      paddingBottom: 30,
      paddingLeft: 40,
    },
  };
  const tableOption =
    type === 1
      ? {
          stringWidth: [
            {index: 0, width: 160},
            {index: 1, width: 160},
            {index: 3, width: 160},
            {index: 4, width: 105},
            {index: 5, width: 170},
            {index: 6, width: 155},
            {index: 7, width: 155},
          ],
        }
      : type === 2
      ? {
          stringWidth: [
            {index: 0, width: 160},
            {index: 1, width: 160},
            {index: 3, width: 160},
            {index: 4, width: 105},
            {index: 5, width: 170},
            {index: 6, width: 170},
          ],
        }
      : {
          stringWidth: [{index: 0, width: 300}],
        };

  /**
   * tableデータ
   * */
  // header
  const headerData =
    type === 1
      ? // ? ['物件名', '支社', '展示場', '営業担当', '入力状況', '保留の項目', '担当者', '操作']
        [
          '物件名',
          '支社',
          '展示場',
          '営業担当',
          '入力状況',
          '入力情報の完了',
          '担当者',
          '操作',
        ]
      : type === 2
      ? // ? ['物件名', '支社', '展示場', '営業担当', '入力状況', '保留の項目', '担当者']
        [
          '物件名',
          '支社',
          '展示場',
          '営業担当',
          '入力状況',
          '入力情報の完了',
          '担当者',
        ]
      : ['物件名', '住所'];
  // lists
  const listsData =
    type === 1
      ? houseListModel?.houseList.map((v) => {
          return [
            v.name,
            v.office,
            v.exhibition_hall,
            v.sales_staff,
            <div style={{textAlign: 'center'}}>
              {v.input_status ? '済' : '未'}
            </div>,
            <S.InputStatusBtn
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(
                  HouseActions.postHouseStatus({
                    api: new ApiPostHouseStatus({
                      article_code: v.code,
                      status: houseListModel.getStatus(v.code) ? 0 : 1,
                    }),
                    callback: () => {
                      houseListModel.changeStatus(v.code);
                      setHouseListModel(
                        new HouseListModel(houseListModel.houseList)
                      );
                    },
                  })
                );
              }}
            >
              <div className="img_wrap position_r">
                {v.status ? (
                  <>
                    <img
                      src={img_checkBoxChecked}
                      alt="チェック済"
                      className="isChecked"
                    />
                  </>
                ) : (
                  <></>
                )}
                <img src={img_checkBoxNoCheck} alt="未チェック" />
              </div>
              <input
                type="checkbox"
                // onChange={() => setKeepData(!keepData)}
                // onFocus={() => setCheckBoxFocus(true)}
                // onBlur={() => setCheckBoxFocus(false)}
              />
            </S.InputStatusBtn>,
            v.coordinator_name,
            v.coordinator_flg ? (
              <ButtonDefault
                label="担当者変更"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickAssignUser(v.code);
                }}
              />
            ) : (
              <ButtonChange
                label="担当者選択"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickAssignUser(v.code);
                }}
              />
            ),
          ];
        })
      : type === 2
      ? houseListModel?.houseList.map((v) => {
          return [
            v.name,
            v.office,
            v.exhibition_hall,
            v.sales_staff,
            <div style={{textAlign: 'center'}}>
              {v.input_status ? '済' : '未'}
            </div>,
            <S.InputStatusBtn
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(
                  HouseActions.postHouseStatus({
                    api: new ApiPostHouseStatus({
                      article_code: v.code,
                      status: houseListModel.getStatus(v.code) ? 0 : 1,
                    }),
                    callback: () => {
                      houseListModel.changeStatus(v.code);
                      setHouseListModel(
                        new HouseListModel(houseListModel.houseList)
                      );
                    },
                  })
                );
              }}
            >
              <div className="img_wrap position_r">
                {v.status ? (
                  <>
                    <img
                      src={img_checkBoxChecked}
                      alt="チェック済"
                      className="isChecked"
                    />
                  </>
                ) : (
                  <></>
                )}
                <img src={img_checkBoxNoCheck} alt="未チェック" />
              </div>
              <input
                type="checkbox"
                // onChange={() => setKeepData(!keepData)}
                // onFocus={() => setCheckBoxFocus(true)}
                // onBlur={() => setCheckBoxFocus(false)}
              />
            </S.InputStatusBtn>,
            v.coordinator_name,
          ];
        })
      : houseList.map((v) => {
          return [v.name, v.location];
        });

  return (
    <S.HouseListBody id="Component_HouseListBody">
      {type !== 0 ? (
        <>
          <S.HouseListRefineWrap ref={defineEle}>
            {/*検索条件*/}
            {/*<GlobalStyleComponent.divide theme={{ size: 10 }} />*/}
            <GlobalStyleComponent.FlexAlignCenter>
              <GlobalStyleComponent.parentWrap theme={{size: 'auto'}}>
                物件名
              </GlobalStyleComponent.parentWrap>

              <GlobalStyleComponent.spacer theme={{size: 20}} />

              <GlobalStyleComponent.parentWrap theme={{size: '300px'}}>
                <InputText
                  value={searchHouseName || ''}
                  onChange={(e) => setSearchHouseName(e.target.value)}
                />
              </GlobalStyleComponent.parentWrap>

              <GlobalStyleComponent.spacer theme={{size: 10}} />

              <GlobalStyleComponent.parentWrap theme={{size: 'auto'}}>
                <ButtonDefault
                  label="絞り込み"
                  option={defineBtnOption}
                  onClick={handleClickSearchName}
                />
              </GlobalStyleComponent.parentWrap>
            </GlobalStyleComponent.FlexAlignCenter>
            <GlobalStyleComponent.divide theme={{size: 20}} />
          </S.HouseListRefineWrap>
        </>
      ) : (
        <></>
      )}

      <S.HouseListTableWrap
        // onClick={() => handleClickList(1)}
        style={
          type === 0
            ? {height: 'calc(100% - ' + btnAreaHeight + 'px)'}
            : {height: 'calc(100% - ' + defineHeight + 'px)'}
        }
      >
        {type !== 0 ? (
          <>
            <GlobalStyleComponent.ListLengthWrap>
              該当件数&nbsp;&nbsp;:&nbsp;&nbsp;
              {listsData?.length}
              &nbsp;件
            </GlobalStyleComponent.ListLengthWrap>
          </>
        ) : (
          <></>
        )}
        <Table
          option={tableOption}
          header={headerData}
          lists={listsData || []}
          clickable
          onClickRow={(selectedHouseId) => handleClickList(selectedHouseId)}
          idList={[...houseList].map((v) => String(v.code))}
        />
      </S.HouseListTableWrap>

      {type === 0 ? (
        <>
          <S.HouseListType3BtnWrap ref={btnEle}>
            <GlobalStyleComponent.divide theme={{size: 40}} />
            <GlobalStyleComponent.FlexAlignCenterContentCenter>
              <ButtonDefault
                label="CSVアップロード"
                option={uploadBtnOption}
                onClick={handleCsv}
              />
            </GlobalStyleComponent.FlexAlignCenterContentCenter>
            <GlobalStyleComponent.divide theme={{size: 21}} />
          </S.HouseListType3BtnWrap>
        </>
      ) : (
        <></>
      )}
    </S.HouseListBody>
  );
};
