import {takeEvery, put, all} from 'redux-saga/effects';
import {DialogActions} from '../../redux/dialog/dialog.action';
import {SystemActions} from '../../redux/system/system.action';
import {ApiBase} from '../api-base';
import {ResFileType, ResType} from '../api.type';
import {UploadMasterActions} from './upload-master.action';
import {UploadApi, UploadResult} from './upload-master.type';
import {ApiUploadHouse} from './api-upload-house';
import {ApiUploadItem} from './api-upload-item';
import {ApiUploadRoom} from './api-upload-room';
import {ApiUploadSelecteria} from './api-upload-selecteria';
import {ApiUploadSeries} from './api-upload-series';
import {ApiUploadUser} from './api-upload-user';
import {ApiUploadMasterResponse} from './api-upload-master.response';
import {RoutingPath} from '../../routes/routing-path';
import {push} from 'connected-react-router';
import {ApiUploadElementGroup} from './api-upload-element-group';
import {ApiUploadElement} from './api-upload-element';
import {ApiPostZip} from './api-post-zip';
import { ApiUploadFinishTable } from './api-upload-finish-table';

function* tryUploadMaster(
  action: ReturnType<typeof UploadMasterActions.upload>
) {
  const {type, csvs} = action.payload;
  // if (!csvs.length) {
  //   yield put(DialogActions.pushMessage({message: ['ファイルがありません。']}));
  //   return;
  // }
  const api: (UploadApi | null)[] = csvs.map((csv) => {
    switch (type) {
      case 'user':
        return new ApiUploadUser({csv});
      case 'house':
        return new ApiUploadHouse({csv});
      case 'finish_table':
        return new ApiUploadFinishTable({csv});
      case 'room':
        return new ApiUploadRoom({csv});
      case 'series':
        return new ApiUploadSeries({csv});
      case 'selecteria':
        return new ApiUploadSelecteria({csv});
      case 'element_group':
        return new ApiUploadElementGroup({csv});
      case 'element':
        return new ApiUploadElement({csv});
      case 'item':
        return new ApiUploadItem({csv});
      default:
        return null;
    }
  });

  const uploadResult: UploadResult[] = [];
  yield put(DialogActions.isLoading(true));
  yield all(
    api.map(function* (v) {
      try {
        if (v === null) {
          uploadResult.push({
            filename: 'アップロードタイプが正しくありません。',
            res: '',
          });
          return;
        }
        const result: ResType<ApiUploadMasterResponse> = yield v?.run();
        const {csv}: {csv: File} = v.body;
        if (ApiBase.isSuccess(result)) {
          uploadResult.push({filename: csv.name, res: '成功しました。'});
        } else {
          uploadResult.push({filename: csv.name, res: result.errors});
        }
      } catch (e) {
        yield put(DialogActions.isLoading(false));
        yield put(SystemActions.networkError());
      }
    })
  );
  yield put(DialogActions.isLoading(false));

  yield put(
    push({
      pathname: RoutingPath.masterUploadResult,
      state: {results: uploadResult},
    })
  );
}

function* tryAuth(action: ReturnType<typeof UploadMasterActions.auth>) {
  try {
    const result: ResType<ApiUploadMasterResponse> = yield action.payload.run();
    if (ApiBase.isSuccess(result)) {
      yield put(DialogActions.pop());
      yield put(UploadMasterActions.setIsAuth(true));
    } else {
      alert('IDまたはパスワードが違います');
    }
  } catch (e) {
    yield put(SystemActions.networkError());
  }
}

function* tryGetZip() {
  try {
    const result: ResFileType = yield new ApiPostZip().run();
    if (ApiBase.isSuccess(result)) {
      const date = new Date();
      const y = date.getFullYear() + '';
      const m = ('0' + String(date.getMonth() + 1)).slice(-2);
      const d = ('0' + String(date.getDate())).slice(-2);

      const url = URL.createObjectURL(result.file);
      const a: HTMLAnchorElement = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.setAttribute('download', `マスタ_${y}_${m}_${d}`);
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      yield put(DialogActions.pushMessage({message: [result.errors]}));
    }
  } catch (e) {
    yield put(SystemActions.networkError());
  }
}

export function* UploadMasterSaga() {
  yield takeEvery(UploadMasterActions.upload, tryUploadMaster);
  yield takeEvery(UploadMasterActions.auth, tryAuth);
  yield takeEvery(UploadMasterActions.getZip, tryGetZip);
}
