// import {ApiLogin} from '../../api/auth/api-login';
import actionCreatorFactory from 'typescript-fsa';
import { ApiChangePassword } from './api-change-password';
import { ApiCsvDownloadAuth } from './api-csv-download-auth';
import { ApiLogin } from './api-login';
import * as Type from './auth.type';

const ActionCreator = actionCreatorFactory('auth');

export const AuthActions = {
  login: ActionCreator<{ api: ApiLogin, onError: (data: string) => void; }>('login'),
  logout: ActionCreator('logout'),
  resetState: ActionCreator('reset/state'),
  checkToken: ActionCreator<{onSuccess: () => void; onError: (e: string) => void; }>('check/token'),
  setToken: ActionCreator<string | null>('set/token'),
  setRole: ActionCreator<number>('set/role'),
  changePassword: ActionCreator<ApiChangePassword>('change/password'),
  getLoginUserData: ActionCreator('get/login/user/data'),
  setLoginUserData: ActionCreator<Type.LoginUser | null>('set/login/user/data'),
  csvDownloadAuth: ActionCreator<ApiCsvDownloadAuth>('csv/download/auth'),
};
