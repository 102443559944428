import styled from 'styled-components';
import {CommonDefine} from '../../define';

export const ButtonStyle = {
  ButtonWrap: styled.div`
    display: block;
    
    // 背景色分岐
    &.btnBg_def button {
      background-color: ${CommonDefine.baseBgColor};
      border: 1px solid ${CommonDefine.baseBgColor};

      &.isSelected,
      &:hover {color: ${CommonDefine.baseBgColor};}
    }
    &.btnBg_delete button {
      background-color: ${CommonDefine.btnBgColorDelete};
      border: 1px solid ${CommonDefine.btnBgColorDelete};
      
      &.isSelected,
      &:hover {color: ${CommonDefine.btnBgColorDelete};}
    }
    &.btnBg_change button {
      background-color: ${CommonDefine.btnBgColorChange};
      border: 1px solid ${CommonDefine.btnBgColorChange};
      
      &.isSelected,
      &:hover {color: ${CommonDefine.btnBgColorChange};}
    }
    &.btnBg_back button {
      background-color: ${CommonDefine.btnBgColorBack};
      border: 1px solid ${CommonDefine.btnBgColorBack};
      
      &.isSelected,
      &:hover {color: ${CommonDefine.btnBgColorBack};}
    }
    &.btnBg_close button {
      background-color: ${CommonDefine.btnBgColorClose};
      border: 1px solid ${CommonDefine.btnBgColorClose};
      
      &.isSelected,
      &:hover {color: ${CommonDefine.btnBgColorClose};}
    }
  `,

  Button: styled.button`
    display: block;
    cursor: pointer;
    color: #ffffff;
    border-radius: 10px;
    margin: 0;
    padding-top: ${({theme}) => theme.paddingTop}px;
    padding-Right: ${({theme}) => theme.paddingRight}px;
    padding-Bottom: ${({theme}) => theme.paddingBottom}px;
    padding-Left: ${({theme}) => theme.paddingLeft}px;
    font-size: ${({theme}) => theme.fontSize}px;
    font-weight: bold;
    
    &:hover {
      ${CommonDefine.animationSlow};
      background-color: #ffffff!important;
    }
    
    &.isReset {
      background: none!important;
      border-radius: 0!important;
      border: 0!important;
      color: ${CommonDefine.mainFontColor};
    }
    &.isSelected {
      background-color: #ffffff!important;
      pointer-events: none;
      cursor:default;
      position: relative;
      
      //&:before {
      //  display: block;
      //  content: '';
      //  position: absolute;
      //  top: 50%;
      //  left: 50%;
      //  transform: translate(-50%, -50%);
      //  width: calc(100% + 4px);
      //  height: calc(100% + 4px);
      //  border-radius: 10px;
      //}
    }
    &:focus {
      ${CommonDefine.focusLayout}
    }
  `,
};
ButtonStyle.ButtonWrap.defaultProps = {};
ButtonStyle.Button.defaultProps = {
  theme: {
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    fontSize: 14,
  },
};