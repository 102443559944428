import styled from 'styled-components';
import { CommonDefine } from '../../define';

export const TableStyle = {
  TableWrap: styled.div`
    width: ${({theme}) => theme.width};
    overflow: auto;
    height: 100%;
  `,

  Table: styled.table`
    table-layout:fixed;
    min-width: 100%;
    border-collapse: collapse;
    border: 1px solid ${CommonDefine.tableBorderColor};
  `,

  TheadWrap: styled.thead``,

  TbodyWrap: styled.tbody``,

  TrWrap: styled.tr`
    &.isSingleColor {
      background-color: #ffffff;
    }
    &:not(.isSingleColor) {
      &:nth-of-type(odd) {
        background-color: #ffffff;
      }
      &:nth-of-type(even) {
        background-color: rgba(201, 188, 156, 0.2);
      }
    }
    
    &.isSelectable {
      position: relative;
      
      &:hover {
        //box-shadow: 0 0 5px 2px rgba(0,0,0,0.3) inset;
        //opacity: 0.5;
        //background-color: #dddddd;
        background-color: rgba(222,93,16,0.2)!important;
        ${CommonDefine.animationSlow}
      }
    }
    &.isSelected {
      background-color: rgba(222, 93, 16, 0.2)!important;
    } 
  `,

  ThWrap: styled.th`
    border-collapse: collapse;
    font-size: 14px;
    font-weight: normal;
    padding: 10px 20px;
    background-color: #a48b78;
    color: #ffffff;
    
    // テーブルヘッダ固定
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    
    // stickyの弊害対策
    .sideBorder {
      position:absolute;
      background-color: #ffffff;
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
    }
    .topBorder {
      position:absolute;
      background-color: ${CommonDefine.tableBorderColor};
      top: -1px;
      left: -1px;
      right: -1px;
      height: 1px;
    }
    
    //&.isEllipsis {
    //  width: auto!important;
    //}
    &.isSortable {
      //position:relative;
      cursor: pointer;
      
      &:after {
        display: block;
        position:absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
      }
      &.isDescending {
        &:after {content: '\\025bc';}
      }
      &.isAscending {
        &:after {content: '\\025b2';}
      }
    }
  `,

  TdWrap: styled.td`
    border-collapse: collapse;
    border: 1px solid ${CommonDefine.tableBorderColor};
    font-size: 20px;
    text-align: left;
    padding: 10px 20px;
    //vertical-align: top;
    vertical-align: middle;
    
    img,
    button {
      display: inline-block;
    }
    input[type="text"],
    input[type="number"] {
      width: 100%;
    }
    
    &.selectable {
      cursor: pointer;
    }
    &.isEllipsis {
      ${CommonDefine.longTextEllipsis};
      max-width: 0;
    }
  `,
};
TableStyle.TableWrap.defaultProps = {
  theme: {width: '100%'}
};
TableStyle.Table.defaultProps = {};