import React, {useCallback, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {RoutingPath} from '../../../routes/routing-path';
import {LoginStyle as S} from '../../../styles/pages/login/login.style';
import img_logo from '../../../assets/images/logo.jpg';
import img_titleSpecificationDecision from '../../../assets/images/title_specificationDecision.svg';
import {InputText} from '../../ui/input/input-text';
import {GlobalStyleComponent} from '../../../styles/component-global-style';
import {Button} from '../../ui/button';
import {OnlyHalfPointedStrings} from '../../../utilities/only-half-pointed-strings';
import {useDispatch} from 'react-redux';
import {AuthActions} from '../../../api/auth/auth.action';
import {ApiChangePassword} from '../../../api/auth/api-change-password';

export const ForgetPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>('');
  const [addressError, setAddressError] = useState<
    'true' | 'false' | 'initial'
  >('initial');
  const [password, setPassword] = useState<string>('');
  const [password_confirmation, setPassword_confirmation] = useState<string>('');
  const [btnDisabled, setBtnDisabled] = useState<'true' | 'false' | 'initial'>(
    'initial'
  );
  const handleClickSubmit = useCallback(() => {
    dispatch(
      AuthActions.changePassword(
        new ApiChangePassword({
          email,
          password,
          password_confirmation,
        })
      )
    );
  }, [email, dispatch, password, password_confirmation]);
  return (
    <S.ForgetPasswordBody id="Component_ForgetPassword">
      <S.LoginBodyInner>
        <div className="img_wrap">
          <img src={img_logo} alt="HINOKIYA" />
          <GlobalStyleComponent.divide theme={{size: 30}} />
          <img src={img_titleSpecificationDecision} alt="ＩＣマッチングシステム" />
          <GlobalStyleComponent.divide theme={{size: 20}} />
        </div>

        <S.LoginFormInner>
          <GlobalStyleComponent.divide theme={{size: 50}} />

          <div className="input_wrap largeInput align_c font_b">
            パスワード再設定画面
          </div>
          <GlobalStyleComponent.divide theme={{size: 30}} />
          <div className="input_wrap align_c">
            新しいパスワードを入力してください。
          </div>

          <GlobalStyleComponent.divide theme={{size: 30}} />

          <div className="input_wrap position_r">
            <div className="align_r inputLabel">
              メールアドレス入力&nbsp;:&nbsp;
            </div>
            <div>
              <InputText
                value={email}
                onChange={(e) => {
                  setEmail(OnlyHalfPointedStrings(e.currentTarget.value));
                  e.currentTarget.value.match(
                    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
                  )
                    ? setAddressError('false')
                    : setAddressError('true');
                }}
                placeholder="hinokiya@hinokiya.com"
              />
              {addressError === 'true' ? (
                <>
                  <div className="passError">
                    正しい形式で入力してください。
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          <GlobalStyleComponent.divide theme={{size: 40}} />

          <div className="input_wrap position_r">
            <div className="align_r inputLabel">
              パスワード入力&nbsp;:&nbsp;
            </div>
            <div className="min_input">
              <InputText
                value={password}
                onChange={(e) => {
                  setPassword(OnlyHalfPointedStrings(e.currentTarget.value));
                  setBtnDisabled(
                    e.currentTarget.value !== password_confirmation ? 'true' : 'false'
                  );
                }}
                type="password"
              />
            </div>
          </div>

          <GlobalStyleComponent.divide theme={{size: 40}} />

          <div className="input_wrap position_r">
            <div className="align_r inputLabel">
              パスワード入力（確認用）&nbsp;:&nbsp;
            </div>
            <div className="min_input position_r">
              <InputText
                value={password_confirmation}
                onChange={(e) => {
                  setPassword_confirmation(OnlyHalfPointedStrings(e.currentTarget.value));
                  setBtnDisabled(
                    e.currentTarget.value !== password ? 'true' : 'false'
                  );
                }}
                type="password"
              />
              {btnDisabled === 'true' ? (
                <>
                  <div className="passError">パスワードが一致しません。</div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          <GlobalStyleComponent.divide theme={{size: 60}} />

          <GlobalStyleComponent.FlexAlignCenterContentCenter>
            <Button
              label="送信"
              option={{
                fontSize: 30,
                paddingSize: {
                  paddingTop: 30,
                  paddingRight: 90,
                  paddingBottom: 30,
                  paddingLeft: 90,
                },
              }}
              onClick={handleClickSubmit}
              disabled={
                email === '' ||
                addressError === 'true' ||
                btnDisabled === 'true' ||
                btnDisabled === 'initial'
                  ? true
                  : false
              }
            />
          </GlobalStyleComponent.FlexAlignCenterContentCenter>

          <GlobalStyleComponent.divide theme={{size: 40}} />
        </S.LoginFormInner>
      </S.LoginBodyInner>

    </S.ForgetPasswordBody>
  );
};
