import { ApiBase } from '../api-base';
import { ApiUploadMasterResponse } from './api-upload-master.response';

type Param = { csv: File; };

export class ApiUploadUser extends ApiBase<ApiUploadMasterResponse> {

  constructor(param: Param) {
    const sendData = { csv: param.csv, file_name: param.csv.name}
    super({
      httpMethod: 'POST',
      contents: 'JSON',
      url: '/api/file/user',
      header: {},
      body: sendData,
      host: 'https://hinokiya-specification-dev.marietta.co.jp',
      isBinary: true
    });
  }
}
