
import { ApiBase } from '../api-base';
import { HeadersAuthJson } from '../api-headers';
import { ApiLoginResponse } from './api-login.response';

export class ApiCheckToken extends ApiBase<ApiLoginResponse> {
  constructor() {
    super({
      httpMethod: 'GET',
      header: HeadersAuthJson(),
      contents: 'JSON',
      url: '/api/auth/token',
      host: 'https://hinokiya-specification-dev.marietta.co.jp'
    });
  }
}
