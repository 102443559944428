import { ApiBase } from '../api-base';
import { HeadersDefaultJson } from '../api-headers';
import { ApiLoginResponse } from './api-login.response';

type Param = {
  email: string;
  password: string;
  remember: string;
};

export class ApiLogin extends ApiBase<ApiLoginResponse> {
  constructor(param: Param) {
    super({
      httpMethod: 'POST',
      header: HeadersDefaultJson(),
      contents: 'JSON',
      url: '/api/auth/login',
      body: param,
      host: 'https://hinokiya-specification-dev.marietta.co.jp'
    });
  }
}
