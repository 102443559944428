export const CommonDefine = {
  // fontFamily: '"HiraMaruPro-W4", "ヒラギノ丸ゴ Pro W4", "小塚ゴシック Pr6N", "メイリオ", "Meiryo", "Hiragino Sans", "ヒラギノ角ゴ Pro W4", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", sans-serif !important',
  // fontFamilyIE: '"HiraMaruPro-W4", "ヒラギノ丸ゴ Pro W4", "メイリオ", "meiryo", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", ​sans-serif !important;',
  fontFamily: ' "メイリオ", "Meiryo", "Hiragino Sans", "ヒラギノ角ゴ Pro W4", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "HiraMaruPro-W4", "ヒラギノ丸ゴ Pro W4", "小塚ゴシック Pr6N", sans-serif !important',
  fontFamilyIE: '"メイリオ", "meiryo", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "HiraMaruPro-W4", "ヒラギノ丸ゴ Pro W4",  ​sans-serif !important;',
  animationSlow: '-webkit-transition: 0.2s ease-in-out;-moz-transition: 0.2s ease-in-out;-o-transition: 0.2s ease-in-out;transition: 0.2s ease-in-out;',
  animationSlow1s: '-webkit-transition: 0.6s ease-in-out;-moz-transition: 0.6s ease-in-out;-o-transition: 0.6s ease-in-out;transition: 0.6s ease-in-out;',
  noDrag: '-webkit-user-drag: none;',
  longTextEllipsis: 'overflow: hidden;text-overflow: ellipsis;white-space: nowrap;',
  selectDisabled: '-ms-user-select: none;-moz-user-select: -moz-none;-khtml-user-select: none;-webkit-user-select: none;user-select: none;',
  mainFontColor: '#231815',
  mainFontColor2: '#231815',
  linkFontColor: '#2ea7e0',
  baseBgColor: '#de5d10',
  btnBgColorDelete: '#c30d23',
  btnBgColorChange: '#036eb8',
  btnBgColorBack: '#595757',
  btnBgColorClose: '#898989',
  borderColor: '#231815',
  inputBorderColor: '#231815',
  inputBorderColor2: '#a48b78',
  tableBorderColor: '#9fa0a0',
  errorColor: '#e60012',
  mainFontSize: 18,
  btnFontSize: 24,
  errorFontSize: 12,
  headerHeight: 70,
  footerHeight: 0,
  minWidth: 1200,
  lineHeight: 1.4,
  selectArrow: 30,
  focusLayout: 'box-shadow:0 0 2px 2px #bbbbbb;',
  flex_box: 'display: -webkit-flex;display: -ms-flexbox;display: flex;',
  flex_align_start: '-webkit-align-items: flex-start;-ms-flex-align: start;align-items: flex-start;',
  flex_align_stretch: '-webkit-align-items: stretch;-ms-flex-align: stretch;align-items: stretch;',
  flex_align_center: '-webkit-align-items: center;-ms-flex-align: center;align-items: center;',
  flex_align_end: '-webkit-align-items: flex-end;-ms-flex-align: end;align-items: flex-end;',
  flex_space_between: '-webkit-justify-content: space-between;justify-content: space-between;-ms-flex-pack: justify;',
  flex_content_center: '-webkit-justify-content: center;justify-content: center;-ms-flex-pack: center;',
  flex_content_end: '-webkit-justify-content: flex-end;justify-content: flex-end;-ms-flex-pack: end;',
  flex_content_around: '-webkit-justify-content: space-around;justify-content: space-around;-ms-flex-pack: distribute;',
  flex_container_wrap: '-webkit-flex-wrap: wrap;flex-wrap: wrap;-ms-flex-wrap: wrap;',
  flex_direction_column: '-webkit-flex-direction: column;flex-direction: column;-ms-flex-direction: column;',
};
