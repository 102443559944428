import styled, { AnyStyledComponent } from 'styled-components';
import { CommonDefine } from './define';

export const GlobalStyleComponent: {[key: string]: AnyStyledComponent} = {};

/**
 * Globalで使用するstyledコンポネント
 */

/**
 * divide
 */
GlobalStyleComponent.divide = styled.div`
  display: block;
  width: 100%;
  height:  ${({theme}) => theme.size}px!important;
`;
GlobalStyleComponent.divide.defaultProps = {
  theme: {size: 10}
};

/**
 * spacer
 */
GlobalStyleComponent.spacer = styled.div`
  display: block;
  width: ${({theme}) => theme.size}px!important;
  flex-basis: ${({theme}) => theme.size}px!important;
`;
GlobalStyleComponent.spacer.defaultProps = {
  theme: {size: 10}
};

/**
 * flexBox系
 */
GlobalStyleComponent.FlexBox = styled.div`
  ${CommonDefine.flex_box}
`;
GlobalStyleComponent.FlexAlignCenter = styled.div`
  ${CommonDefine.flex_box}
  ${CommonDefine.flex_align_center}
`;
GlobalStyleComponent.FlexBetween = styled.div`
  ${CommonDefine.flex_box}
  ${CommonDefine.flex_space_between}
`;
GlobalStyleComponent.FlexAlignCenterBetween = styled.div`
  ${CommonDefine.flex_box}
  ${CommonDefine.flex_align_center}
  ${CommonDefine.flex_space_between}
`;
GlobalStyleComponent.FlexAlignEndBetween = styled.div`
  ${CommonDefine.flex_box}
  ${CommonDefine.flex_align_end}
  ${CommonDefine.flex_space_between}
`;
GlobalStyleComponent.FlexAlignCenterContentCenter = styled.div`
  ${CommonDefine.flex_box}
  ${CommonDefine.flex_align_center}
  ${CommonDefine.flex_content_center}
`;
GlobalStyleComponent.FlexAlignEndContentCenter = styled.div`
  ${CommonDefine.flex_box}
  ${CommonDefine.flex_align_end}
  ${CommonDefine.flex_content_center}
`;
GlobalStyleComponent.FlexAlignCenterContentEnd = styled.div`
  ${CommonDefine.flex_box}
  ${CommonDefine.flex_align_center}
  ${CommonDefine.flex_content_end}
`;
GlobalStyleComponent.FlexWrap = styled.div`
  ${CommonDefine.flex_box}
  ${CommonDefine.flex_container_wrap}
`;

/**
 * label, input等のラッパー、サイズ指定用
 */
GlobalStyleComponent.parentWrap = styled.div`
  flex-basis: ${({theme}) => theme.size};
  width: ${({theme}) => theme.size};
`;
GlobalStyleComponent.parentWrap.defaultProps = {
  theme: {size: '5em'}
};

/**
 * 件数表示用エレメント
 */
GlobalStyleComponent.ListLengthWrap = styled.div`
  position: absolute;
  right: 0;
  top: -1.5em;
`;