import React, {useCallback, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {RoutingPath} from '../../../routes/routing-path';
import {LoginStyle as S} from '../../../styles/pages/login/login.style';
import img_logo from '../../../assets/images/logo.jpg';
import img_titleSpecificationDecision from '../../../assets/images/title_specificationDecision.svg';
import img_checkBoxNoCheck from '../../../assets/images/checkbox_noCheck.svg';
import img_checkBoxChecked from '../../../assets/images/checkbox_checked.svg';
import {InputText} from '../../ui/input/input-text';
import {GlobalStyleComponent} from '../../../styles/component-global-style';
import {Button} from '../../ui/button';
import {useDispatch, useSelector} from 'react-redux';
import {ErrorMessage} from '../../../models/message/error-message';
import {AuthActions} from '../../../api/auth/auth.action';
import {LineFeedConversion} from '../../../utilities/line-feed-conversion';
import {OnlyHalfPointedStrings} from '../../../utilities/only-half-pointed-strings';
import {ApiLogin} from '../../../api/auth/api-login';
import {useDidMount} from '../../../hooks/life-cycle';
import {HouseActions} from '../../../api/house/house.action';
import {CoordinatorActions} from '../../../api/coordinator/coordinator.action';
import {State} from '../../../redux/root.reducer';
import {DialogActions} from '../../../redux/dialog/dialog.action';
import {DialogBodyEle} from '../master/master-upload';
import {Config} from '../../../config/config';

const Login = () => {
  const isAuth = useSelector((state: State) => state.upload.isAuth);

  const dispatch = useDispatch();
  const [id, setId] = useState<string>('');
  const [pass, setPass] = useState<string>('');
  const [keepData, setKeepData] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [checkBoxFocus, setCheckBoxFocus] = useState<boolean>(false);
  const handleClickLogin = useCallback(() => {
    const apiData = new ApiLogin({
      email: id,
      password: pass,
      remember: keepData ? '1' : '0',
    });
    const onError = () => {
      setErrorMessage(ErrorMessage.loginError);
    };
    dispatch(
      AuthActions.login({
        api: apiData,
        onError,
      })
    );
  }, [dispatch, id, keepData, pass]);

  useDidMount(() => {
    dispatch(AuthActions.resetState());
    dispatch(CoordinatorActions.resetState());
    dispatch(HouseActions.resetState());
  });

  useDidMount(() => {
    if ((Config.mode !== 'dev' && Config.mode !== 'stg') || isAuth) return;
    dispatch(
      DialogActions.push({
        dialogType: 'default',
        title: '認証',
        bodyEle: <DialogBodyEle />,
      })
    );
  });

  return (
    <S.LoginBody id="Component_Login">
      <S.LoginBodyInner>
        <div className="img_wrap">
          <img src={img_logo} alt="HINOKIYA" />
          <GlobalStyleComponent.divide theme={{size: 30}} />
          <img
            src={img_titleSpecificationDecision}
            alt="ＩＣマッチングシステム"
          />
          <GlobalStyleComponent.divide theme={{size: 20}} />
        </div>

        <S.LoginFormInner>
          <GlobalStyleComponent.divide theme={{size: 50}} />

          {errorMessage ? (
            <>
              <div className="error_wrap">
                {LineFeedConversion(errorMessage)}
              </div>
              <GlobalStyleComponent.divide theme={{size: 20}} />
            </>
          ) : (
            <></>
          )}

          <div className="input_wrap position_r">
            <div className="align_r inputLabel">ID&nbsp;:&nbsp;</div>
            <div>
              <InputText
                value={id}
                onChange={(e) => {
                  setId(OnlyHalfPointedStrings(e.currentTarget.value));
                }}
                list={'debugList'}
              />
            </div>
          </div>

          <GlobalStyleComponent.divide theme={{size: 40}} />

          <div className="input_wrap position_r">
            <div className="align_r inputLabel">パスワード&nbsp;:&nbsp;</div>
            <div>
              <InputText
                value={pass}
                onChange={(e) => {
                  setPass(OnlyHalfPointedStrings(e.currentTarget.value));
                }}
                type="password"
                onKeyPress={(e) => e.key === 'Enter' && handleClickLogin()}
              />
            </div>
          </div>

          <GlobalStyleComponent.divide theme={{size: 30}} />

          <label
            className={`
              checkBoxLabel
              ${checkBoxFocus ? ' onFocus' : ''}
              `}
          >
            <div className="img_wrap position_r">
              {keepData ? (
                <>
                  <img
                    src={img_checkBoxChecked}
                    alt="チェック済"
                    className="isChecked"
                  />
                </>
              ) : (
                <></>
              )}
              <img src={img_checkBoxNoCheck} alt="未チェック" />
            </div>
            <input
              type="checkbox"
              onChange={() => setKeepData(!keepData)}
              onFocus={() => setCheckBoxFocus(true)}
              onBlur={() => setCheckBoxFocus(false)}
            />
            <GlobalStyleComponent.spacer theme={{size: 10}} />
            ログイン情報を保持
          </label>

          <GlobalStyleComponent.divide theme={{size: 60}} />

          <GlobalStyleComponent.FlexAlignCenterContentCenter>
            <Button
              label="ログイン"
              option={{
                fontSize: 30,
                paddingSize: {
                  paddingTop: 30,
                  paddingRight: 90,
                  paddingBottom: 30,
                  paddingLeft: 90,
                },
              }}
              onClick={handleClickLogin}
              disabled={!id || !pass}
            />
          </GlobalStyleComponent.FlexAlignCenterContentCenter>

          <GlobalStyleComponent.divide theme={{size: 40}} />

          <div className="align_c">
            <NavLink to={RoutingPath.forgetPassword}>パスワード再設定</NavLink>
          </div>

          <GlobalStyleComponent.divide theme={{size: 40}} />
        </S.LoginFormInner>
      </S.LoginBodyInner>
    </S.LoginBody>
  );
};

export default Login;
